export enum ContactUsRequestFormField {
  contactUsRequestId = 'contactUsRequestId',
  contactUsRequestState = 'contactUsRequestState',
  internalNotes = 'internalNotes',
}

export enum ContactUsRequestState {
  Received = 'Received',
  Reviewed = 'Reviewed',
  Responded = 'Responded',
}

export const toContactUsRequestState = (str: string): ContactUsRequestState => {
  switch(str) {
    case ContactUsRequestState.Received:
      return ContactUsRequestState.Received;
    case ContactUsRequestState.Reviewed:
      return ContactUsRequestState.Reviewed;
    case ContactUsRequestState.Responded:
      return ContactUsRequestState.Responded;
    default:
      return ContactUsRequestState.Received;
  }
};

export interface ContactUsRequest {
  contactUsRequestId: string;
  email: string;
  fullName: string;
  message: string;
  contactUsRequestState: ContactUsRequestState;
  internalNotes: string;
  updated: string;
}


export default ContactUsRequest;
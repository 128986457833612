import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HashMap from '../../models/HashMap';
import ExerciseProgram from '../../models/ExerciseProgram';
import ExerciseProgramFormField from '../../models/ExerciseProgramFormField.enum';
import validateTruthyValue from '../../helpers/validate-truthy-value';

type ExerciseProgramId = string;

interface ExerciseProgramState {
  exercisePrograms: ExerciseProgram[];
  selectedExerciseProgram: ExerciseProgram;
  exerciseProgramModalIsVisible: boolean;
  exerciseProgramForModal?: ExerciseProgram; 
  exerciseProgramForm: HashMap<ExerciseProgramFormField, string>;
  formIsValid: boolean;
  validationErrors: string[];
  isProcessing: boolean;
  isModalSubmitting: boolean;
  newExerciseProgramError: string;
  hasSubscribed: boolean;
  subscriptionId?: string;
  subscriptionError?: string;
  updatingExerciseProgramIds: ExerciseProgramId[];
  updatingErrors: HashMap<ExerciseProgramId, string>;
}

const exerciseProgramSlice = createSlice({
  name: 'exerciseProgram',
  initialState: {
    exercisePrograms: new Array<ExerciseProgram>(),
    exerciseProgramForm: new HashMap<ExerciseProgramFormField, string>(),
    updatingExerciseProgramIds: new Array<ExerciseProgramId>(), 
    updatingErrors: new HashMap<ExerciseProgramId, string>(),
  } as ExerciseProgramState,
  reducers: {
    submittingExerciseProgramStarted(state) {
      state.isModalSubmitting = true;
    },
    submittingExerciseProgramCompleted(state) {
      state.isModalSubmitting = false;
      state.newExerciseProgramError = '';
    },
    submittingExerciseProgramFailed(state, action: PayloadAction<string>) {
      state.isModalSubmitting = false;
      state.newExerciseProgramError = action.payload;
    },
    selectExerciseProgram(state, action: PayloadAction<ExerciseProgram>) {
      state.selectedExerciseProgram = action.payload;
    },
    showExerciseProgramModal(state, action: PayloadAction<ExerciseProgramId|undefined>) {
      const exerciseProgramId = action.payload;
      const exerciseProgram = exerciseProgramId && state.exercisePrograms.find(exerciseProgram => exerciseProgram.exerciseProgramId === exerciseProgramId);

      if (exerciseProgram) {
        state.exerciseProgramForModal = exerciseProgram;
      } else {
        state.exerciseProgramForModal = {} as ExerciseProgram;
      }

      state.exerciseProgramModalIsVisible = true;
    },
    hideExerciseProgramModal(state) {
      state.exerciseProgramModalIsVisible = false;
      delete state.exerciseProgramForModal;
    },
    setFormDefaults(state, action: PayloadAction<HashMap<ExerciseProgramFormField, string>>) {
      const fields = action.payload;
      
      fields.forEach(([field, value]) => {
        state.exerciseProgramForm.set(field, value);
      });
    },
    updateForm(state, action: PayloadAction<{field: ExerciseProgramFormField, value: string}>) {
      const { field, value} = action.payload;
      
      state.exerciseProgramForm.set(field, value);
      
      const validationErrors = validateTruthyValue(state.exerciseProgramForm.ref());

      state.validationErrors = validationErrors;
      state.formIsValid = !validationErrors.length;
    },
    clearForm(state) {
      state.exerciseProgramForm.clear();
      state.formIsValid = false;
      state.validationErrors = new Array<ExerciseProgramFormField>();
    },
    unsubscribedFromExercisePrograms(state) {
      state.hasSubscribed = false;
      delete state.subscriptionId;
    },
    subscribedToExercisePrograms(state, action: PayloadAction<string>) {
      state.hasSubscribed = true;
      state.subscriptionId = action.payload;
    },
    exerciseProgramsReceived(state, action: PayloadAction<ExerciseProgram[]>) {
      state.exercisePrograms= action.payload;
      if (state.selectedExerciseProgram) {
        state.selectedExerciseProgram = state.exercisePrograms.find(
          program => program.exerciseProgramId === state.selectedExerciseProgram.exerciseProgramId
        ) || state.selectedExerciseProgram;
      }
    },
    exerciseProgramsSubscriptionFailed(state, action: PayloadAction<string>) {
      state.hasSubscribed = true;
      state.subscriptionError = action.payload;
    },
    setExerciseProgramForModal(state, action: PayloadAction<ExerciseProgramId>) {
      const exerciseProgramId = action.payload;
      const exerciseProgram = exerciseProgramId && state.exercisePrograms.find(exerciseProgram => exerciseProgram.exerciseProgramId === exerciseProgramId);

      if (exerciseProgram) {
        state.exerciseProgramForModal = exerciseProgram;
      } else {
        state.exerciseProgramForModal = {} as ExerciseProgram;
      }
    },
    unsetExerciseProgramForModal(state) {
      delete state.exerciseProgramForModal;
    },
    exerciseProgramUpdateRequested(state, action:PayloadAction<ExerciseProgramId>) {
      state.updatingExerciseProgramIds.push(action.payload);
      state.isProcessing = true;
    },
    exerciseProgramUpdateCompleted(state, action:PayloadAction<ExerciseProgramId>) {
      const itemId = action.payload;
      state.updatingExerciseProgramIds = state.updatingExerciseProgramIds.filter(id => id !== itemId);
      state.updatingErrors.delete(itemId);
      state.isProcessing = false;
      delete state.exerciseProgramForModal;
    },
    exerciseProgramUpdateFailed(state, action:PayloadAction<[ExerciseProgramId,string]>) {
      const [itemId, errorMessage] = action.payload;

      state.updatingExerciseProgramIds = state.updatingExerciseProgramIds.filter(id => id !== itemId);
      state.updatingErrors.set(itemId, errorMessage);
      state.isProcessing = false;
    },
  }
});

export default exerciseProgramSlice;

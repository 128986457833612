import firebase from 'firebase';
import { v4 as createUUID } from 'uuid';
import HashMap from '../models/HashMap';
import UserAppFormField from '../models/UserAppFormField.enum';
import UserApp from '../models/UserApp';

type userAppsUpdatedHandler = (userApps: UserApp[]) => void;

const SUBSCRIPTION_CACHE: Map<string, () => void> = new Map();

export const USER_APPS_PATH= 'userApps';

const userAppsAPI = {
  postNewUserApp: async (userId: string, form: HashMap<UserAppFormField, string>) => {
    const userCollection = firebase.firestore().collection('users');
    const currentUserDoc = userCollection.doc(userId);
    const userAppsCollection = currentUserDoc.collection(USER_APPS_PATH);

    return userAppsCollection.add({
      [UserAppFormField.userAppName]: form.getWithDefault(UserAppFormField.userAppName, ''),
      [UserAppFormField.userAppDescription]: form.getWithDefault(UserAppFormField.userAppDescription, ''),
      created: (new Date()).toString(),
      active: true,
    }).then();
  },

  updateUserApp: async (userId: string, form: HashMap<UserAppFormField, string>) => {
    const userAppId = form.get(UserAppFormField.userAppId) as string;

    const userCollection = firebase.firestore().collection('users');
    const currentUserDoc = userCollection.doc(userId);
    const userAppsCollection = currentUserDoc.collection(USER_APPS_PATH);
    const userAppDoc = userAppsCollection.doc(userAppId);
    
    return userAppDoc.update({
      [UserAppFormField.userAppName]: form.getWithDefault(UserAppFormField.userAppName, ''),
      [UserAppFormField.userAppDescription]: form.getWithDefault(UserAppFormField.userAppDescription, ''),
      updated: (new Date()).toString(),
    }).then();
  },

  subscribeToUserApps: async (userId: string, userAppUpdated: userAppsUpdatedHandler) => {
    const userCollection = firebase.firestore().collection('users');
    const currentUserDoc = userCollection.doc(userId);
    const userAppCollection = currentUserDoc.collection(USER_APPS_PATH);

    const unsubscribe = userAppCollection.onSnapshot((snapshot) => {
      let storeUserApps: UserApp[] = [];

      snapshot.forEach((doc) => {
        const data = doc.data();
        const userApp: UserApp = {
          userAppId: doc.id as string,
          userAppName: data.userAppName,
          userAppDescription: data.userAppDescription,
        };

        storeUserApps = [...storeUserApps, userApp];
      });

      userAppUpdated(storeUserApps);
    });
    
    const subscriptionUUID = createUUID();

    SUBSCRIPTION_CACHE.set(subscriptionUUID, unsubscribe);

    return subscriptionUUID;
  },

  unsubscribeFromUserApps: (subscriptionId: string) => {
    if (SUBSCRIPTION_CACHE.has(subscriptionId)) {
      const unsubscribe = SUBSCRIPTION_CACHE.get(subscriptionId);
      unsubscribe && unsubscribe();
      SUBSCRIPTION_CACHE.delete(subscriptionId);
    }
  },
};

export default userAppsAPI;

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../root-reducer';

export const clientsStateSelector = (state: RootState) => state.clients;

export const selectedClientSelector = createSelector(clientsStateSelector, (state) => state.selectedClient);

export const clientModalIsVisibleSelector = createSelector(clientsStateSelector, (state) => state.clientModalIsVisible);

export const clientFormSelector = createSelector(clientsStateSelector,
  (state) => ({
    form: state.clientForm,
    formIsValid: state.formIsValid,
    validationErrors: state.validationErrors,
    selectedClient: state.selectedClient,
    isModalSubmitting: state.isModalSubmitting,
  }));

export const clientDetailsSelector = createSelector(clientsStateSelector,
  (state) => ({
    clientDetails: state.clientDetails,
    requestError: state.clientDetailsRequestError,
    isLoading: state.isLoadingClientDetails,
    hasLoadedClientDetails: state.hasLoadedClientDetails,
  }))
import { createSlice , PayloadAction } from '@reduxjs/toolkit';
import Message from '../../models/Message';

interface MessageState {
    isLoading: boolean;
    hasSubscribed: boolean;
    messages: Message[];
    subscriptionError: string;
    subscriptionId?: string;
    updatingError: string;
}

const messageSlice = createSlice({
  name: 'message',
  initialState: {
    isLoading: false,
    hasSubscribed: false,
  } as MessageState,
  reducers: {
    unsubscribedFromMessages(state) {
      state.hasSubscribed = false;
      delete state.subscriptionId;
      state.messages = [];
    },
    subscribedToMessages(state, action: PayloadAction<string>) {
      state.hasSubscribed = true;
      state.subscriptionId = action.payload;
    },
    messagesReceived(state, action: PayloadAction<Message[]>) {
      state.messages= action.payload;
    },
    messagesSubscriptionFailed(state, action: PayloadAction<string>) {
      state.hasSubscribed = false;
      state.subscriptionError = action.payload;
    },
    messageUpdateRequested(state) {
      state.isLoading = true;
    },
    messageUpdateCompleted(state) {
      state.isLoading = false;
    },
    messageUpdateFailed(state, action:PayloadAction<string>) {
      state.updatingError = action.payload;
      state.isLoading = false;
    },
  }
});

export default messageSlice;

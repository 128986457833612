import React from 'react';
import { Redirect } from 'react-router-dom';
import Route from './RouteWrapper';
import { AnimatedSwitch } from 'react-router-transition';
import styles from './routes.module.scss';

//Pages
import ExercisePrograms from '../pages/ExercisePrograms/ExercisePrograms';
import ExerciseProgramDetails from '../pages/ExerciseProgramDetails/ExerciseProgramDetails';
import ExerciseProgramClients from '../pages/ExerciseProgramClients/ExerciseProgramClients';
import ExerciseProgramExercises from '../pages/ExerciseProgramExercises/ExerciseProgramExercises';
import Exercises from '../pages/Exercises/Exercises';
import Home from '../pages/Home/Home';
import SignIn from '../pages/SignIn/SignIn';
import Clients from '../pages/Clients/Clients';
import ClientDetails from '../pages/ClientDetails/ClientDetails';
import SignUp from '../pages/SignUp/SignUp';

const Routes = () => (
  <AnimatedSwitch 
    atEnter={{ opacity: 0 }}
    atLeave={{ opacity: 0 }}
    atActive={{ opacity: 1 }}
    className={styles.switchWrapper}
  >
    <Route path="/summary" exact component={Home} isPrivate />
    <Route path="/exercises" exact component={Exercises} isPrivate />
    <Route path="/clients" exact component={Clients} isPrivate />
    <Route path="/client-details/:clientId" exact component={ClientDetails} isPrivate />
    <Route path="/exercise-programs" exact component={ExercisePrograms} isPrivate />
    <Route path="/exercise-program-details" exact component={ExerciseProgramDetails} isPrivate />
    <Route path="/exercise-program-clients" exact component={ExerciseProgramClients} isPrivate />
    <Route path="/exercise-program-exercises" exact component={ExerciseProgramExercises} isPrivate />
    <Route path="/sign-in" component={SignIn} />
    <Route path="/sign-up" component={SignUp} />
    <Redirect to="/summary" />
  </AnimatedSwitch>
);

export default Routes;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './exercise-program-details.module.scss';
import { userIdSelector } from '../../store/auth/auth-selectors';
import { selectedExerciseProgramSelector, exerciseProgramDetailsSelector } from '../../store/exercisePrograms/exercise-programs-selectors';
import { updateExerciseProgram } from '../../store/exercisePrograms/exercise-programs-thunks';
import exerciseProgramSlice from '../../store/exercisePrograms/exercise-programs-slice';
import { default as FormField } from '../../models/ExerciseProgramFormField.enum';
import ExerciseProgramModalBody from '../../features/ExerciseProgramModal/components/ExerciseProgramModalBody';
import useSession from '../../custom_hooks/useSession';
import LoadingWithOverlay from '../../components/LoadingWithOverlay/LoadingWithOverlay';

const ExerciseProgramDetails = () => {
  const dispatch = useDispatch();
  const session = useSession();
  
  const exerciseProgram = useSelector(selectedExerciseProgramSelector);

  const {
    exerciseProgramForModal,
    form,
    isProcessing,
  } = useSelector(exerciseProgramDetailsSelector);
  
  const { setExerciseProgramForModal } = exerciseProgramSlice.actions;
  
  useEffect(() => {
    if (exerciseProgram && !exerciseProgramForModal) {
      dispatch(setExerciseProgramForModal(exerciseProgram.exerciseProgramId));
    }
  }, [exerciseProgramForModal])

  useEffect(() => {
    dispatch(setExerciseProgramForModal(exerciseProgram.exerciseProgramId));
  }, [exerciseProgram])

  const handleSubmit = () => {
    dispatch(updateExerciseProgram(session, form));
  };

  return (
    <div className={classNames("pure-g", styles.inventory)}>
    	<div className={classNames("pure-u-1 pure-u-lg-5-6", styles.cardContainer)}>
        <h1>Exercise Program Details</h1>
        { isProcessing && <LoadingWithOverlay contained />}

        <div className={styles.formContainer}>
          { exerciseProgramForModal && <ExerciseProgramModalBody /> }
        </div>

        <div className={styles.actionsContainer}>
          <button type="button"
            className={classNames("pure-button", styles.submitButton)} 
            onClick={handleSubmit}>
            Update
          </button>
        </div>
    	</div>
    </div>
  );
};

export default ExerciseProgramDetails;

import firebase, { firestore } from 'firebase';
import { v4 as createUUID } from 'uuid';
import SessionData from '../models/SessionData';
import { getAppDoc, getExerciseProgramPath, getUserRootDoc } from './Helpers';
import Client from '../models/Client';
import ClientFormField from '../models/ClientFormField.enum';
import { EXERCISE_PROGRAM_PATH } from './ExercisePrograms';

export const CLIENTS_PATH = 'clients';

type SubscriptionId = string;

type clientsUpdatedHandler = (clients: Client[]) => void;

const SUBSCRIPTION_CACHE: Map<string, () => void> = new Map();

const clientsAPI = {
  subscribeToClients: async (session: SessionData, clientsUpdated: clientsUpdatedHandler) => {
    const appDoc = getAppDoc(session);
    const clientCollection = appDoc.collection(CLIENTS_PATH);

    const unsubscribe = clientCollection.onSnapshot((snapshot) => {
      let storeClients: Client[] = [];
      snapshot.forEach((doc) => {
        const client: Client = {
          clientId: doc.id as string,
          ...doc.data() as any,
        };
        storeClients = [...storeClients, client];
      });
      clientsUpdated(storeClients);
    });

    const subscriptionUUID = createUUID();

    SUBSCRIPTION_CACHE.set(subscriptionUUID, unsubscribe);

    return subscriptionUUID;
  },

  unsubscribeFromClient: (subscriptionId: string) => {
    if (SUBSCRIPTION_CACHE.has(subscriptionId)) {
      const unsubscribe = SUBSCRIPTION_CACHE.get(subscriptionId);
      unsubscribe && unsubscribe();
      SUBSCRIPTION_CACHE.delete(subscriptionId);
    }
  },

  addClient: async (session: SessionData, client: Client) => {
    const appDoc = getAppDoc(session);
    const clientCollection = appDoc.collection(CLIENTS_PATH);
    
    return clientCollection.doc(client.clientId).set(client);
  },

  removeClient: async (session: SessionData, client: Client) => {
    const appDoc = getAppDoc(session);
    const clientCollection = appDoc.collection(CLIENTS_PATH);
    const clientClientDoc = clientCollection.doc(client.clientId);

    return clientClientDoc.delete();
  },

  updateClientForAllExercisePrograms: async (session: SessionData, client: Client) => {
    const rootDoc = getUserRootDoc(session);
    const exerciseProgramsCollection = await rootDoc.collection(EXERCISE_PROGRAM_PATH).get();
    
    exerciseProgramsCollection.forEach(async (doc) => {
        const clientQ = rootDoc.collection(EXERCISE_PROGRAM_PATH).doc(doc.id).collection(CLIENTS_PATH).doc(client.clientId);
        const _client = await clientQ.get();
        
        if (_client.exists) {
            clientQ.update({
                [ClientFormField.email]: client.email || '',
                [ClientFormField.clientName]: client.clientName || '',
                [ClientFormField.clientState]: client.clientState || '',
            });
        }
    });
  },

};

export default clientsAPI;

import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import styles from './nav.module.scss';
import { signOut } from '../../store/auth/user-actions';
import AddOnsNav from './components/AddOnsNav/AddOnsNav';
import ExerciseProgramsNav from './components/ExerciseProgramsNav/ExerciseProgramsNav';
import classNames from 'classnames';

interface NavProps {
  children?: React.ReactNode;
  segment: string; // route?
  isOpen: boolean;
  onNavigate?: () => void;
}

const HIDDEN_NAV_PATHNAMES = ['/sign-in', '/sign-up'];

const PATHS = {
  summary: ['/summary'],
  clients: ['/clients'],
  exercises: ['/exercises'],
  exercisePrograms: ['/exercise-programs', '/exercise-program-details', '/exercise-program-clients', '/exercise-program-exercises'],
}

const Nav = ({
  children,
  isOpen,
  onNavigate,
}: NavProps) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const pathname = location.pathname;
  const showLinks = !(HIDDEN_NAV_PATHNAMES.indexOf(pathname) > -1);

  const navigateTo = (path: string) => (ev: React.FormEvent) => {
    history.push(path);
    onNavigate && onNavigate();
  };
  const pushToHistory = (path: string) => {
    history.push(path);
    onNavigate && onNavigate();
  };

  const handleSignOutClick = () => {
    dispatch(signOut());
  };
  

  return (
    <section className={styles.navSectionContainer}>
      {children}
      { showLinks && isOpen && (
        <nav className={styles.navSection}>
          <ul className={styles.navList}>
            <li onClick={navigateTo('/')} className={classNames({ [styles.selected]: (PATHS.summary.indexOf(pathname) > -1)})}>
              Summary 
            </li>
            <ExerciseProgramsNav onNavClick={pushToHistory} />
            <li onClick={navigateTo('/clients')} className={classNames({ [styles.selected]: (PATHS.clients.indexOf(pathname) > -1)})} >
              Clients
            </li>
            <li onClick={navigateTo('/exercises')} className={classNames({ [styles.selected]: (PATHS.exercises.indexOf(pathname) > -1)})} >
              Exercises
            </li>
            <li onClick={handleSignOutClick}>
              Sign Out
            </li>
          </ul>
        </nav>
      )}
    </section>
  );
};

export default Nav;


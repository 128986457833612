import * as firebase from 'firebase';
import configAPI from './Config';
import inventoryAPI from './Inventory';
import authAPI from './Auth';
import storageAPI from './Storage';
import settingsAPI from './Settings';
import ordersAPI from './Orders';
import contactUsAPI from './ContactUs';
import preferencesAPI from './Preferences';
import blogPagesAPI from './BlogPages';
import userAppsAPI from './UserApps';
import exercisesAPI from './Exercises';
import exerciseProgramsAPI from './ExercisePrograms';
import exerciseProgramClientsAPI from './ExerciseProgramClients';
import exerciseProgramExercisesAPI from './ExerciseProgramExercises';
import clientsAPI from './Clients';
import publicClientAPI from './PublicClient';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_apiKey,
  authDomain: process.env.REACT_APP_FIREBASE_authDomain,
  databaseURL: process.env.REACT_APP_FIREBASE_databaseURL,
  projectId: process.env.REACT_APP_FIREBASE_projectId,
  storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
  appId: process.env.REACT_APP_FIREBASE_appId,
  measurementId: process.env.REACT_APP_FIREBASE_measurementId
} as const;

const app = firebase.initializeApp(firebaseConfig);

const api = {
  auth: authAPI,
  configs: configAPI,
  inventory: inventoryAPI,
  storage: storageAPI,
  settings: settingsAPI,
  orders: ordersAPI,
  contactUs: contactUsAPI,
  preferences: preferencesAPI,
  blogPages: blogPagesAPI,
  userApps: userAppsAPI,
  exercises: exercisesAPI,
  exercisePrograms: exerciseProgramsAPI,
  clients: clientsAPI,
  exerciseProgramClients: exerciseProgramClientsAPI,
  exerciseProgramExercises: exerciseProgramExercisesAPI,
  publicClient: publicClientAPI,
};

export default api;


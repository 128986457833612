import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import Client from '../../models/Client';
import clientsSlice from '../../store/clients/clients-slice';
import styles from './clients-container.module.scss';
import { Redirect } from 'react-router-dom';

interface ClientsTableRowProps {
  client: Client;
  index: number;
}

const ClientsTableRow = ({
  client,
  index,
}: ClientsTableRowProps) => {
  const [redirectId, setRedirectId] = useState('');
  const dispatch = useDispatch();
  const {
    showClientModal,
  } = clientsSlice.actions;

  const handleClick = () => {
    dispatch(showClientModal(client.clientId));
  };
  
  const handleDetailsClick = () => {
    setRedirectId(client.clientId);
  }
  
  if (redirectId) {
    return (<Redirect to={`/client-details/${client.clientId}`} />);
  }

  return (
    <tr className={index % 2 === 0 ? '' : 'pure-table-odd'}>
      <td className={styles.tdClientName}>{client.clientName}</td>
      <td className={styles.tdEmail}>{client.email}</td>
      <td className={styles.tdContent}>
        {client.clientState}
      </td>
      <td className={styles.actionButtonCell}>
        <button
          type="button"
          className={classNames('pure-button pure-button-primary', styles.actionButton)}
          onClick={handleClick}
        >
          <FontAwesomeIcon icon={['fas', 'edit']} />
        </button>
      </td>
      <td className={styles.actionButtonCell}>
        <button
          type="button"
          className={classNames('pure-button pure-button-primary', styles.actionButton)}
          onClick={handleDetailsClick}
        >
          <FontAwesomeIcon icon={['fas', 'binoculars']} />
        </button>
      </td>
    </tr>
  );
};

export default ClientsTableRow;

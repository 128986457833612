import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/Modal/Modal';
import styles from './user-app-modal.module.scss';
import UserAppModalBody from './components/UserAppModalBody';
import { userIdSelector } from '../../store/auth/auth-selectors';
import userAppsSlice from '../../store/userApps/user-apps-slice';
import { selectedUserAppForModal, userAppFormSelector } from '../../store/userApps/user-apps-selectors';
import UserApp from '../../models/UserApp';
import { postNewUserApp } from '../../store/userApps/user-apps-thunks';

const UserAppModal = () => {
  const dispatch = useDispatch();
  const { hideUserAppModal } = userAppsSlice.actions;
  const userId = useSelector(userIdSelector);
  const userApp = useSelector(selectedUserAppForModal);
  const { form } = useSelector(userAppFormSelector);
  
  const handleClose = () => {
    dispatch(hideUserAppModal());
  };
  
  const handleSubmit = () => {
    dispatch(postNewUserApp(userId, form));
  };

  const renderHeader = () => <h2>{ userApp?.userAppId ? "Edit User App" : "New User App"}</h2>;

  const renderBody = () => (<UserAppModalBody onClose={handleClose} />);

  const renderActions = () => (
    <div className={styles.actionsContainer}>
      <button type="button"
        className={classNames("pure-button", styles.cancelButton)} 
        onClick={handleClose}>
        Close
      </button>
      
      <button type="button"
        className={classNames("pure-button", styles.submitButton)} 
        onClick={handleSubmit}>
        Save 
      </button>
    </div>
  );
  
  return (
    <Modal
      onClose={handleClose}
      renderHeader={renderHeader}
      renderBody={renderBody}
      renderActions={renderActions}
    />
  );
};

export default UserAppModal;

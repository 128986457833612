import api from '../../api';
import { AppThunk } from '../index';
import inventorySlice from './inventory-slice';
import FormFields from '../../models/NewInventoryFormField.enum';
import InventoryItemField from '../../models/InventoryItemField.enum';
import HashMap from '../../models/HashMap';
import SessionData from '../../models/SessionData';

const inventoryImagePath = 'inventoryImage';

export const postNewItem = (session: SessionData, form: HashMap<FormFields, string>, imageFile: File): AppThunk => async(dispatch) => {
  const {
    submittingInventoryStarted,
    submittingInventoryCompleted,
    submittingInventoryFailed,
    clearForm,
    hideNewInventoryModal,
  } = inventorySlice.actions;

  dispatch(submittingInventoryStarted());
  
  try {
    //const imageUrl = await api.storage.createStorageItem(session, inventoryImagePath, imageFile);

    // await api.inventory.postNewInventory(session, form, imageUrl);

    dispatch(submittingInventoryCompleted());
    dispatch(clearForm());
    dispatch(hideNewInventoryModal());
  } catch(e) {
    dispatch(submittingInventoryFailed(e.message));
  }
};

export const subscribeToInventory = (session: SessionData): AppThunk => async(dispatch) => {
  const {
    subscribedToInventoryItems,
    inventoryItemsReceived,
    inventoryItemsSubscriptionFailed,
  } = inventorySlice.actions;

  try {
    const subscriptionId = await api.inventory.subscribeToInventory(session, (items) => {
      dispatch(inventoryItemsReceived(items));
    });
    dispatch(subscribedToInventoryItems(subscriptionId));
  } catch(e) {
    dispatch(inventoryItemsSubscriptionFailed(e.message));
  }
}
      
export const unsubscribeToInventory = (subscriptionId: string): AppThunk => async(dispatch) => {
  const {
    unsubscribedToInventoryItems,
  } = inventorySlice.actions;

  await api.inventory.unsubscribeFromInventory(subscriptionId);
  
  dispatch(unsubscribedToInventoryItems());
}

export const updateInventoryItem = (session: SessionData, form: HashMap<InventoryItemField, string>): AppThunk => async(dispatch) => {
  const {
    itemUpdateRequested,
    itemUpdateCompleted,
    itemUpdateFailed,
  } = inventorySlice.actions;
  
  const itemId = form.getWithDefault(InventoryItemField.itemId, '');
  
  dispatch(itemUpdateRequested(itemId));
  
  try {
    await api.inventory.updateInventoryItem(session, form);

    dispatch(itemUpdateCompleted(itemId));
  } catch(e) {
    dispatch(itemUpdateFailed([itemId, e.message]));
  }
};

export const deleteInventoryItem = (session: SessionData, itemId: string): AppThunk => async(dispatch) => {
  const {
    itemUpdateRequested,
    itemUpdateCompleted,
    itemUpdateFailed,
  } = inventorySlice.actions;
  
  dispatch(itemUpdateRequested(itemId));
  
  try {
    await api.inventory.deleteInventoryItem(session, itemId);

    dispatch(itemUpdateCompleted(itemId));
  } catch(e) {
    dispatch(itemUpdateFailed([itemId, e.message]));
  }
};
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../root-reducer';

export const inventoryStateSelector = (state: RootState) => state.inventory;

export const newInventoryModalIsVisibleSelector = createSelector(
  inventoryStateSelector,
  (inventoryState) => inventoryState.newInventoryModalIsVisible,
);

export const newInventoryFormSelector = createSelector(
  inventoryStateSelector,
  (inventoryState) => ({
    isValid: inventoryState.newInventoryFormIsValid,
    fields: inventoryState.newInventoryForm,
    validationErrors: inventoryState.validationErrors,
    isSubmitting: inventoryState.isSubmittingNewInventory,
  }),
);

export const inventoryItemsStateSelector = createSelector(inventoryStateSelector,
(inventoryState) => ({
  items: inventoryState.items,
  hasSubscribed: inventoryState.hasSubscribedToInventoryItems,
  subscriptionId: inventoryState.subscriptionId,
}));

export const updatingItemsSelector = createSelector(inventoryStateSelector, (inventoryState) => ({
  updatingItemIds: inventoryState.updatingItemIds,
  updatingErrors: inventoryState.updatingErrors,
}));
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './preferences-modal-body.module.scss';
import { PreferenceIdKey } from '../../../models/Preference';
import preferenceSlice from '../../../store/preferences/preferences-slice';
import { availableSummaryCardsSelector } from '../../../store/preferences/preferences-selector';
import Preference, { PreferenceType } from '../../../models/Preference';
import SummaryCardMap from '../../../structs/SummaryCards/SummaryCardsMap';

interface PreferenceModalBodyProps {
  modalFormPreferences: Preference[];
  onClose: () => void;
}

const PreferencesModalBody = ({ modalFormPreferences, onClose }: PreferenceModalBodyProps) => {
  const dispatch = useDispatch();
  const availableSummaryCards = useSelector(availableSummaryCardsSelector);
  const { updateModalForm } = preferenceSlice.actions;
  
  const isActive = (summaryCardKey: PreferenceIdKey): boolean =>
    modalFormPreferences.some(preference => preference.key === summaryCardKey && preference.active);
    
  const onCheckChange = (summaryCardKey: PreferenceIdKey) => (ev: React.FormEvent<HTMLInputElement>) => {
    const active = ev.currentTarget.checked;
    const summaryCardPreference = {
      active,
      id: summaryCardKey,
      key: summaryCardKey,
      preferenceType: PreferenceType.SUMMARY_CARD,
    } as Preference; 
    
    const otherPreferences = modalFormPreferences.filter(pref => pref.key !== summaryCardKey);
    
    dispatch(updateModalForm([...otherPreferences, summaryCardPreference]))
  };

  const closeModalForNavigation = () => {
    onClose();
  }
  
  return (
    <div className={classNames("pure-g", styles.modalBodyContainer)}>
      <div className="pure-u-1">
        { availableSummaryCards && availableSummaryCards.length
          ? (<p>Which <em>Summary Cards</em> would you like to view on your home screen?</p>)
          : (<p>You must use select <Link to="/add-ons" onClick={closeModalForNavigation}>Add Ons</Link> in order to use <em>Summary Cards</em></p>)
        }
      </div>
      { availableSummaryCards.map(summaryCard => (
        <div className={classNames("pure-u-1 pure-u-md-1-2", styles.checkboxContainer)}
             key={summaryCard.key}
             title={summaryCard.description}
        >
          <label className={classNames("pure-checkbox", styles.checkboxLabel)}>
            <input type="checkbox" value={summaryCard.key}
                   checked={isActive(summaryCard.key)}
                   onChange={onCheckChange(summaryCard.key)}
            />
            <span className={styles.labelHeaderText}>{summaryCard.headerText}:</span>
            <span className={styles.labelDescriptionText}>{summaryCard.description}</span>
          </label>
        </div>
      ))}
    </div>
  );
};

export default PreferencesModalBody;
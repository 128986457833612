export enum MediaSourceType {
    File,
    Youtube,
}

interface MediaSource {
    mediaSourceName: string;
    mediaSourceId: string;
    mediaSourceUrl: string;
    mediaSourceType: MediaSourceType;
    mediaSourceRemotePath?: string;
    mediaSourceFileName?: string;
    isThumbnail: boolean;
}

export default MediaSource;
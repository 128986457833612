import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../root-reducer';

export const contactUsStateSelector = (state: RootState) => state.contactUs;

export const selectedContactUsRequestSelector = createSelector(contactUsStateSelector, (state) => state.selectedContactUsRequest);

export const detailsModalIsVisibleSelector = createSelector(contactUsStateSelector,
  (state) => state.detailsModalIsVisible);

export const contactUsFormStateSelector = createSelector(contactUsStateSelector,
  (state) => ({
    form: state.selectContactRequestForm,
    formIsValid: state.formIsValid,
    validationErrors: state.validationErrors,
    selectedContactUsRequest: state.selectedContactUsRequest,
    isProcessing: state.isProcessing,
  }));
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ExpandableNav from '../ExpandableNav/ExpandableNav';
import { NavItem } from '../../../../store/navigation/navigation-slice';
import useSession from '../../../../custom_hooks/useSession';
import { selectedExerciseProgramSelector } from '../../../../store/exercisePrograms/exercise-programs-selectors';
import NavLink from '../../../../models/NavLink';
import FolderIcon from '../../../Icons/Folder';
import OpenFolderIcon from '../../../Icons/OpenFolder';
import styles  from '../../nav.module.scss';

interface ExerciseProgramsNavProps {
    onNavClick: (path: string) => void;
}

const ExerciseProgramsNav = ({ onNavClick }: ExerciseProgramsNavProps) => {
  const session = useSession();
  const selectedExerciseProgram = useSelector(selectedExerciseProgramSelector);

  const NavFolderIcon = () => (<span className={styles.iconContainer}><FolderIcon/></span>);

  let childLinks: NavLink[] = [
      { PreIcon: NavFolderIcon, text: 'Programs', path: '/exercise-programs' },
  ];
  
  if (!!session.selectedExerciseProgramId) {
    const NavOpenFolderIcon = () => <span className={styles.iconContainer}><OpenFolderIcon/></span>;
    
    childLinks = [
      { PreIcon: NavOpenFolderIcon, text: selectedExerciseProgram.exerciseProgramName, path: '/exercise-programs' },
      { text: '- Details', path: '/exercise-program-details' },
      { text: '- Exercises', path: '/exercise-program-exercises' },
      { text: '- Clients', path: '/exercise-program-clients' },
    ];
  }

  return (
      <ExpandableNav
        navItem={NavItem.EXERCISE_PROGRAMS}
        isLoading={false}
        headerNavText="Exercise Programs"
        onNavClick={onNavClick}
        childLinks={childLinks}
      />
  );
}

export default ExerciseProgramsNav;
export enum PreferenceType {
  ADD_ON = "ADD_ON",
  SUMMARY_CARD = "SUMMARY_CARD",
}

export enum PreferenceIdKey {
  /** Add Ons **/
  INVENTORY = "INVENTORY",
  CONTACT_US = "CONTACT_US",
  ORDER_TRACKING = "ORDER_TRACKING",
  BLOG_PAGE = "BLOG_PAGE",

  /** Summary Cards **/
  INVENTORY_OUT_OF_STOCK = "INVENTORY_OUT_OF_STOCK",
  UNHANDLED_CONTACT_REQUESTS = "UNHANDLED_CONTACT_REQUESTS",
  UNHANDLED_ORDERS = "UNHANDLED_ORDERS",
}

interface Preference {
  id: PreferenceIdKey;
  preferenceType: PreferenceType;
  key: PreferenceIdKey;
  active: boolean;
}

export const toPreferenceType = (preferenceTypeString: string) => {
  switch(preferenceTypeString) {
    case PreferenceType.ADD_ON:
      return PreferenceType.ADD_ON;
    case PreferenceType.SUMMARY_CARD:
      return PreferenceType.SUMMARY_CARD;
    default:
      throw new Error(`Unrecognized PreferenceType ${preferenceTypeString}`);
  }
};

export const toPreferenceIdKey = (preferenceIdKeyString: string) => {
  switch(preferenceIdKeyString) {
    case PreferenceIdKey.CONTACT_US:
      return PreferenceIdKey.CONTACT_US;
    case PreferenceIdKey.INVENTORY:
      return PreferenceIdKey.INVENTORY;
    case PreferenceIdKey.ORDER_TRACKING:
      return PreferenceIdKey.ORDER_TRACKING;
    case PreferenceIdKey.BLOG_PAGE:
      return PreferenceIdKey.BLOG_PAGE;
    case PreferenceIdKey.INVENTORY_OUT_OF_STOCK:
      return PreferenceIdKey.INVENTORY_OUT_OF_STOCK;
    case PreferenceIdKey.UNHANDLED_CONTACT_REQUESTS:
      return PreferenceIdKey.UNHANDLED_CONTACT_REQUESTS;
    case PreferenceIdKey.UNHANDLED_ORDERS:
      return PreferenceIdKey.UNHANDLED_ORDERS;
    default:
      throw new Error(`Unrecognized Preference Type ${preferenceIdKeyString}`);
  }
};

export const toPreference = (id: string, data: any) => {
  const preferenceType = toPreferenceType(data.preferenceType);
  const key = toPreferenceIdKey(id);
  return {
    id:key,
    key,
    active: data.active,
    preferenceType,
  } as Preference;
};


export default Preference;
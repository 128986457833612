import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import LoadingWithOverlay from '../../../components/LoadingWithOverlay/LoadingWithOverlay';
import styles from './blog-page-modal-body.module.scss';
import { default as FormField } from '../../../models/BlogPageFormField.enum';
import blogPagesSlice from '../../../store/blogPages/blog-pages-slice';
import { blogPageFormSelector } from '../../../store/blogPages/blog-pages-selector';
import { BlogPageState } from '../../../models/BlogPage';
import HashMap from '../../../models/HashMap';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const OrderDetailsModalBody = () => {
  const dispatch = useDispatch();
  const {
    form,
    validationErrors,
    selectedBlogPage,
    isModalSubmitting,
  } = useSelector(blogPageFormSelector);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  
  const { updateForm, setFormDefaults } = blogPagesSlice.actions;

  const fromFormHTML = (html: string) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState);
  }
  
  useEffect(() => {
    const formDefaults = new HashMap<FormField, string>();
    formDefaults.set(FormField.header, selectedBlogPage?.header || '');
    formDefaults.set(FormField.subheader, selectedBlogPage?.subheader || '');
    formDefaults.set(FormField.content, selectedBlogPage?.content || '');
    formDefaults.set(FormField.blogPageState, selectedBlogPage?.blogPageState || '');
    formDefaults.set(FormField.blogPageId, selectedBlogPage?.blogPageId || '');
    dispatch(setFormDefaults(formDefaults));
  }, [])

  useEffect(() => {
    setEditorState(fromFormHTML(selectedBlogPage?.content || ''));
  }, [selectedBlogPage?.content])
  
  const handleOnChange =  (field: FormField) => (ev: React.FormEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    ev.preventDefault();
    const value = ev.currentTarget.value;
    
    dispatch(updateForm({ field, value}));
  };

  const handleBlogPageStateChange = (ev: React.FormEvent<HTMLSelectElement>) => {
    ev.preventDefault();
    dispatch(updateForm({ field: FormField.blogPageState, value: ev.currentTarget.value }));
  };

  const handleContentChange = (updatedState: EditorState) => {
    const value = draftToHtml(convertToRaw(updatedState.getCurrentContent()));
    setEditorState(updatedState);
    dispatch(updateForm({ field: FormField.content, value, }));
  };
  
  return (
    <div id="blogPage-form" className={classNames("pure-g pure-form", styles.formContainer)}>
      { isModalSubmitting && <LoadingWithOverlay contained={false}/>}
        <div className="pure-u-1 pure-u-md-1-3">
          <span className={styles.label}>Header</span>
          <input
            type="text"
            className={classnames(styles.input, 'pure-input-1')}
            onChange={handleOnChange(FormField.header)}
            value={form.getWithDefault(FormField.header, '')}
          />
        </div>
        <div className="pure-u-1 pure-u-md-2-3">
          <br />
        </div>
        <div className="pure-u-1 pure-u-md-1-3">
          <span className={styles.label}>Subheader</span>
          <input
            type="text"
            className={classnames(styles.input, 'pure-input-1')}
            onChange={handleOnChange(FormField.subheader)}
            value={form.getWithDefault(FormField.subheader, '')}
          />
        </div>
        <div className="pure-u-1">
          <hr />
        </div>
        <div className="pure-u-1">
          <span className={classNames(styles.label, styles.fullWidth)}>
            Content
          </span>
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName={styles.editor}
            onEditorStateChange={handleContentChange}
          />
        </div>
        <div className="pure-u-1">
          <div className={styles.spacer} />
        </div>
        <div className="pure-u-1 pure-u-md-1-3">
          <span className={classNames(styles.label, styles.fullWidth)}>
            Blog Page State
          </span>
          <select id="blogPage-state"
            value={form.getWithDefault(FormField.blogPageState, BlogPageState.draft)}
            onChange={handleBlogPageStateChange}
            className={"pure-u-1"}
          >
            <option value={BlogPageState.draft}>Draft</option>
            <option value={BlogPageState.active}>Active</option>
            <option value={BlogPageState.inactive}>Inactive</option>
          </select>
        </div>
    </div>
  );
};

export default OrderDetailsModalBody;
import firebase from 'firebase';
import { v4 as createUUID } from 'uuid';
import Order, { OrderState, toOrderBillingAndShippingDetails, toOrderState } from '../models/Order';
import HashMap from '../models/HashMap';
import OrderField from '../models/OrderField.enum';
import SessionData from '../models/SessionData';
import { getAppDoc } from './Helpers';


type ordersUpdatedHandler = (orders: Order[]) => void;

const SUBSCRIPTION_CACHE: Map<string, () => void> = new Map();

export const ORDERS_PATH = 'orders';

const orderssAPI = {
  subscribeToOrders: async (session: SessionData, ordersUpdated: ordersUpdatedHandler) => {
    const appDoc = getAppDoc(session);
    const ordersCollection = appDoc.collection(ORDERS_PATH);

    const unsubscribe = ordersCollection.onSnapshot((snapshot) => {
      let storeOrders: Order[] = [];

      snapshot.forEach((doc) => {
        const data = doc.data();
        const order: Order = {
          orderId: doc.id as string,
          amount: parseFloat(data.amount) / 100.0,
          created: data.created,
          email: data.email,
          name: data.name,
          orderState: data.orderState ? toOrderState(data.orderState) : OrderState.OrderReceived,
          billingAndShipping: toOrderBillingAndShippingDetails(data.billingAndShipping),
          publicNotes: data.publicNotes,
          internalNotes: data.internalNotes,
          updated: data.updated,
        };

        storeOrders = [...storeOrders, order];
      });

      ordersUpdated(storeOrders);
    });
    
    const subscriptionUUID = createUUID();

    SUBSCRIPTION_CACHE.set(subscriptionUUID, unsubscribe);

    return subscriptionUUID;
  },

  unsubscribeFromOrders: (subscriptionId: string) => {
    if (SUBSCRIPTION_CACHE.has(subscriptionId)) {
      const unsubscribe = SUBSCRIPTION_CACHE.get(subscriptionId);
      unsubscribe && unsubscribe();
      SUBSCRIPTION_CACHE.delete(subscriptionId);
    }
  },

  updateOrder: async (session: SessionData, form: HashMap<OrderField, string>) => {
    const orderId = form.get(OrderField.orderId) as string;

    const appDoc = getAppDoc(session);
    const ordersCollection = appDoc.collection(ORDERS_PATH);
    const orderDoc = ordersCollection.doc(orderId);
    
    return orderDoc.update({
      [OrderField.internalNotes]: form.getWithDefault(OrderField.internalNotes, ''),
      [OrderField.publicNotes]: form.getWithDefault(OrderField.publicNotes, ''),
      [OrderField.orderState]: form.getWithDefault(OrderField.orderState, ''),
      updated: (new Date()).getTime(),
    }).then();
  },

  getIncompleteOrderCount: async (session: SessionData) => {
    const appDoc = getAppDoc(session);
    const ordersCollection = appDoc.collection(ORDERS_PATH);
    
    const snapshot = await ordersCollection.get();
    
    let count = 0;
    
    snapshot.forEach((doc) => {
      const data = doc.data();

      if(data.orderState !== OrderState.OrderShipmentComplete) {
        count++;
      }
    });

    return count;
  }
};

export default orderssAPI;

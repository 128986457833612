import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import Modal from '../../components/LargeModal/LargeModal';
import exerciseSlice from '../../store/exercises/exercises-slice';
import {  editExerciseFormSelector } from '../../store/exercises/exercises-selector';
import { deleteExercise, updateExercise } from '../../store/exercises/exercises-thunks';
import styles from './edit-exercise-modal.module.scss';
import useSession from '../../custom_hooks/useSession';
import ExerciseDetailsModalBody from './components/EditDetailsModalBody';
import EditMediaModalBody from './components/EditMediaModalBody';

const NewExerciseModal = () => {
  const dispatch = useDispatch();
  const editExerciseFormState = useSelector(editExerciseFormSelector);
  const session = useSession();
  
  
  const { hideEditExerciseModal, clearEditForm } = exerciseSlice.actions;
  
  const renderHeader = () => <h2>Edit Exercise</h2>;

  const renderBody = () => (
    <div className={styles.modalBodyContainer}>
      <div className={styles.modalBodyLeft}>
        <ExerciseDetailsModalBody isLoading={editExerciseFormState.isSubmitting} />
      </div>
      <div className={styles.modalBodyRight}>
        <EditMediaModalBody isLoading={editExerciseFormState.isSubmitting} />
      </div>
    </div>
  )
  
  const handleClose = () => {
    dispatch(hideEditExerciseModal());
    dispatch(clearEditForm());
  };
  
  const handleSubmit = () => {
    const {
      isValid,
      fields,
      mediaSources,
      selectedExercise
    }  = editExerciseFormState;
    
    if (!isValid) return;
    
    if (session && selectedExercise) {
      dispatch(updateExercise(session, selectedExercise, fields, mediaSources))
    }
  };

  const handleDeleteClick = () => {
    if(editExerciseFormState.selectedExercise) {
      dispatch(deleteExercise(session, editExerciseFormState.selectedExercise));
    }
  };

  const renderActions = () => (<div className={styles.actionsContainer}>
    <button type="button"
      className={classNames("pure-button", styles.cancelButton)} 
      onClick={handleClose}>
      Close
    </button>

    <button type="button" className={classNames('pure-button', styles.deleteButton)} onClick={handleDeleteClick}>Delete</button>

    <button type="button"
      className={classNames("pure-button", styles.submitButton)} 
      onClick={handleSubmit}>
      Submit
    </button>
  </div>);
  
  return (
    <Modal
      onClose={handleClose}
      renderHeader={renderHeader}
      renderBody={renderBody}
      renderActions={renderActions}
    />
  );
};

export default NewExerciseModal;

import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './exercise-program-modal-body.module.scss';
import ExerciseProgram from '../../../models/ExerciseProgram';
import LoadingWithOverlay from '../../../components/LoadingWithOverlay/LoadingWithOverlay';
import { default as FormField } from '../../../models/ExerciseProgramFormField.enum';
import HashMap from '../../../models/HashMap';
import exerciseProgramSlice from '../../../store/exercisePrograms/exercise-programs-slice';
import { exerciseProgramFormSelector } from '../../../store/exercisePrograms/exercise-programs-selectors';

const ExerciseProgramModalBody = () => {
  const dispatch = useDispatch();
  const { updateForm, setFormDefaults } = exerciseProgramSlice.actions;
  const {
    form,
    validationErrors,
    exerciseProgramForModal,
    isModalSubmitting,
  } = useSelector(exerciseProgramFormSelector);

  useEffect(() => {
    const formDefaults = new HashMap<FormField, string>();

    if (exerciseProgramForModal) {
      formDefaults.set(FormField.exerciseProgramId, exerciseProgramForModal.exerciseProgramId)
      formDefaults.set(FormField.exerciseProgramName, exerciseProgramForModal.exerciseProgramName)
      formDefaults.set(FormField.exerciseProgramDescription, exerciseProgramForModal.exerciseProgramDescription)
    }

    dispatch(setFormDefaults(formDefaults));
  }, [exerciseProgramForModal?.exerciseProgramId])

  
  const handleOnChange =  (field: FormField) => (ev: React.FormEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    ev.preventDefault();
    const value = ev.currentTarget.value;
    
    dispatch(updateForm({ field, value}));
  };
  
  return (
    <div id="exerciseProgram-form" className={classNames("pure-g pure-form", styles.formContainer)}>
      { isModalSubmitting && <LoadingWithOverlay contained={false}/>}
        <div className="pure-u-1">
          <span className={styles.label}>Name</span>
          <input
            type="text"
            className={classnames(styles.input, 'pure-input-1')}
            onChange={handleOnChange(FormField.exerciseProgramName)}
            value={form.getWithDefault(FormField.exerciseProgramName, '')}
          />
        </div>
        <div className="pure-u-1">
          <br />
        </div>
        <div className="pure-u-1">
          <span className={styles.label}>Description</span>
          <textarea 
            className={classnames(styles.textarea, 'pure-input-1')}
            onChange={handleOnChange(FormField.exerciseProgramDescription)}
            value={form.getWithDefault(FormField.exerciseProgramDescription, '')}
          />
        </div>
    </div>
  );
};

export default ExerciseProgramModalBody;
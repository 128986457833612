import AddOn from '../../models/AddOn';
import { PreferenceIdKey } from '../../models/Preference';

const ContactUsAddOn = {
    key: PreferenceIdKey.CONTACT_US,
    description: 'Manage Customer Contact Requests',
    headerText: 'Contact Us',
    link: '/contact-us-requests',
    summaryCardIds: [PreferenceIdKey.UNHANDLED_CONTACT_REQUESTS],
} as AddOn;

export default ContactUsAddOn;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import NewExerciseFormField from '../../models/NewExerciseFormField.enum';
import EditExerciseFormField from '../../models/EditExerciseFormField.enum';
import HashMap from '../../models/HashMap';
import validateTruthyValue from '../../helpers/validate-truthy-value';
import Exercise from '../../models/Exercise';
import ExerciseFormField from '../../models/ExerciseField.enum';
import MediaSource from '../../models/MediaSource';

export const tempFileStore =  new HashMap<string, File>();

export const addToTempFileStore = (mediaSourceId: string, file: File) => {
  tempFileStore.set(mediaSourceId, file);
};

export const removeFromTempFileStore = (mediaSource: MediaSource) => {
  tempFileStore.delete(mediaSource.mediaSourceId);
};

type ExerciseId = string;

interface ExercisesState {
  newExercisesModalIsVisible: boolean;
  newExerciseForm: HashMap<NewExerciseFormField, string>;
  newExerciseFormMedia: MediaSource[];
  newExerciseFormIsValid: boolean;
  editExercisesModalIsVisible: boolean;
  editExerciseForm: HashMap<NewExerciseFormField, string>;
  editExerciseFormMedia: MediaSource[];
  editExerciseFormIsValid: boolean;
  editExerciseError: string;
  validationErrorsForEdit: NewExerciseFormField[];
  validationErrors: NewExerciseFormField[];
  isSubmittingNewExercise: boolean;
  isSubmittingExercise: boolean;
  newExerciseError: string;
  exercises: Exercise[];
  hasSubscribedToExercises: boolean;
  subscriptionId?: string;
  subscriptionError: string;
  updatingExerciseIds: ExerciseId[];
  updatingErrors: HashMap<ExerciseId, string>;
  selectedExercise?: Exercise;
}

const exercisesSlice = createSlice({
  name: 'exercises',
  initialState: {
    newExercisesModalIsVisible: false,
    newExerciseForm: new HashMap<NewExerciseFormField, string>(),
    newExerciseFormMedia: [] as MediaSource[],
    validationErrors: new Array<NewExerciseFormField>(),
    editExercisesModalIsVisible: false,
    editExerciseForm: new HashMap<EditExerciseFormField, string>(),
    editExerciseFormMedia: [] as  MediaSource[],
    editExerciseFormIsValid: true,
    validationErrorsForEdit: [] as EditExerciseFormField[],
    isSubmittingNewExercise: false,
    isSubmittingExercise: false,
    hasSubscribedToExercises: false,
    exercises:[] as Exercise[],
    updatingExerciseIds: [] as ExerciseId[],
    updatingErrors: new HashMap<ExerciseId, string>(),
  } as ExercisesState,
  reducers: {
    showNewExerciseModal(state) {
      state.newExercisesModalIsVisible = true;
    },
    hideNewExerciseModal(state) {
      state.newExercisesModalIsVisible = false;
    },
    updateForm(state, action: PayloadAction<{field: NewExerciseFormField, value: string}>) {
      const { field, value} = action.payload;
      
      state.newExerciseForm.set(field, value);
      
      // const validationErrors = validateTruthyValue(state.newExerciseForm.ref());

      // state.validationErrors = validationErrors;
      // state.newExerciseFormIsValid = !validationErrors.length;
      state.newExerciseFormIsValid = true;
    },
    clearForm(state) {
      state.newExerciseForm.clear();
      state.newExerciseFormMedia = [] as MediaSource[];
      state.newExerciseFormIsValid = false;
      state.validationErrors = new Array<NewExerciseFormField>();
      tempFileStore.clear();
    },
    submittingExerciseStarted(state) {
      state.isSubmittingNewExercise = true;
    },
    submittingExerciseCompleted(state) {
      state.isSubmittingNewExercise = false;
    },
    submittingExerciseFailed(state, action: PayloadAction<string>) {
      state.isSubmittingNewExercise = false;
      state.newExerciseError = action.payload;
    },
    unsubscribedToExercises(state) {
      state.hasSubscribedToExercises = false;
      delete state.subscriptionId;
    },
    subscribedToExercises(state, action: PayloadAction<string>) {
      state.hasSubscribedToExercises = true;
      state.subscriptionId = action.payload;
    },
    exercisesReceived(state, action: PayloadAction<Exercise[]>) {
      state.exercises = action.payload;
    },
    exercisesSubscriptionFailed(state, action: PayloadAction<string>) {
      state.hasSubscribedToExercises = true;
      state.subscriptionError = action.payload;
    },
    exerciseUpdateRequested(state, action:PayloadAction<ExerciseId>) {
      state.updatingExerciseIds.push(action.payload);
    },
    exerciseUpdateCompleted(state, action:PayloadAction<ExerciseId>) {
      const execiseId = action.payload;
      state.updatingExerciseIds = state.updatingExerciseIds.filter(id => id !== execiseId);
      state.updatingErrors.delete(execiseId);
    },
    exerciseUpdateFailed(state, action:PayloadAction<[ExerciseId,string]>) {
      const [exerciseId, errorMessage] = action.payload;

      state.updatingExerciseIds = state.updatingExerciseIds.filter(id => id !== exerciseId);
      state.updatingErrors.set(exerciseId, errorMessage);
    },
    selectExerciseForEdit(state, action: PayloadAction<ExerciseId>) {
      state.selectedExercise = state.exercises.find(exercise => exercise.exerciseId === action.payload);
      state.newExercisesModalIsVisible = true;
    },
    setFormDefaults(state, action: PayloadAction<HashMap<ExerciseFormField, string>>) {
      const fields = action.payload;
      
      fields.forEach(([field, value]) => {
        state.newExerciseForm.set(field, value);
      });
    },
    addMediaSource(state, action: PayloadAction<MediaSource>) {
      const mediaSource = action.payload;
      state.newExerciseFormMedia = [...state.newExerciseFormMedia, mediaSource];
    },
    removeMediaSource(state, action: PayloadAction<MediaSource>) {
      const mediaSource = action.payload;
      state.newExerciseFormMedia = state.newExerciseFormMedia.filter(med => med.mediaSourceId !== mediaSource.mediaSourceId);
    },
    updateSelectedThumbnail(state, action: PayloadAction<MediaSource>) {
      const selected = action.payload;
      state.newExerciseFormMedia.forEach((media) => {
        if (media.mediaSourceId === selected.mediaSourceId) {
          media.isThumbnail = true;
        } else {
          media.isThumbnail = false;
        }
      });
    },
    addMediaSourceForEdit(state, action: PayloadAction<MediaSource>) {
      const mediaSource = action.payload;
      state.editExerciseFormMedia = [...state.editExerciseFormMedia, mediaSource];
      state.editExerciseFormIsValid = true;
    },
    removeMediaSourceForEdit(state, action: PayloadAction<MediaSource>) {
      const mediaSource = action.payload;
      state.editExerciseFormMedia = state.editExerciseFormMedia.filter(med => med.mediaSourceId !== mediaSource.mediaSourceId);
      state.editExerciseFormIsValid = true;
    },
    updateSelectedThumbnailForEdit(state, action: PayloadAction<MediaSource>) {
      const selected = action.payload;
      state.editExerciseFormMedia.forEach((media) => {
        if (media.mediaSourceId === selected.mediaSourceId) {
          media.isThumbnail = true;
        } else {
          media.isThumbnail = false;
        }
      });
      state.editExerciseFormIsValid = true;
    },
    showEditExerciseModal(state, action: PayloadAction<ExerciseId>) {
      state.selectedExercise = state.exercises.find(exercise => exercise.exerciseId === action.payload);
      state.editExerciseFormMedia = state.selectedExercise?.mediaSources || [];
      state.editExercisesModalIsVisible = true;
    },
    hideEditExerciseModal(state) {
      delete state.selectedExercise;
      state.editExercisesModalIsVisible = false;
    },
    updateEditForm(state, action: PayloadAction<{field: EditExerciseFormField, value: string}>) {
      const { field, value} = action.payload;
      
      state.editExerciseForm.set(field, value);
      
      state.editExerciseFormIsValid = true;
    },
    clearEditForm(state) {
      state.editExerciseForm.clear();
      state.editExerciseFormMedia = [] as MediaSource[];
      state.editExerciseFormIsValid = false;
      state.validationErrors = new Array<NewExerciseFormField>();
      tempFileStore.clear();
    },
    setEditFormDefaults(state, action: PayloadAction<HashMap<EditExerciseFormField, string>>) {
      const fields = action.payload;
      
      fields.forEach(([field, value]) => {
        state.editExerciseForm.set(field, value);
      });
    },
  }
});

export default exercisesSlice;

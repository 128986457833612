import api from '../../api';
import { AppThunk } from '../index';
import blogPagesSlice from './blog-pages-slice';
import BlogPageFormField from '../../models/BlogPageFormField.enum';
import HashMap from '../../models/HashMap';
import SessionData from '../../models/SessionData';


export const postNewBlogItem = (
    session: SessionData,
    form: HashMap<BlogPageFormField, string>,
): AppThunk => async(dispatch) => {
  const {
    submittingBlogPageStarted,
    submittingBlogPageCompleted,
    submittingBlogPageFailed,
    hideBlogPageModal,
    clearForm,
  } = blogPagesSlice.actions;

  dispatch(submittingBlogPageStarted());
  
  try {

    await api.blogPages.postNewBlogPage(session, form);

    dispatch(submittingBlogPageCompleted());
    dispatch(clearForm());
    dispatch(hideBlogPageModal());
  } catch(e) {
    dispatch(submittingBlogPageFailed(e.message));
  }
};

export const subscribeToBlogPages = (session: SessionData): AppThunk => async(dispatch) => {
  const {
    subscribedToBlogPages,
    blogPagesReceived,
    blogPagesSubscriptionFailed,
  } = blogPagesSlice.actions;

  try {
    const subscriptionId = await api.blogPages.subscribeToBlogPages(session, (blogPages) => {
      dispatch(blogPagesReceived(blogPages));
    });
    dispatch(subscribedToBlogPages(subscriptionId));
  } catch(e) {
    dispatch(blogPagesSubscriptionFailed(e.message));
  }
}
      
export const unsubscribeToBlogPages = (subscriptionId: string): AppThunk => async(dispatch) => {
  const {
    unsubscribedFromBlogPages,
  } = blogPagesSlice.actions;

  api.blogPages.unsubscribeFromBlogPages(subscriptionId);
  
  dispatch(unsubscribedFromBlogPages());
}

export const updateBlogPage = (session: SessionData, form: HashMap<BlogPageFormField, string>): AppThunk => async(dispatch) => {
  const {
    blogPageUpdateRequested,
    blogPageUpdateCompleted,
    blogPageUpdateFailed,
  } = blogPagesSlice.actions;
  
  const orderId = form.getWithDefault(BlogPageFormField.blogPageId, '');

  dispatch(blogPageUpdateRequested(orderId));
  
  try {
    await api.blogPages.updateBlogPage(session, form);

    dispatch(blogPageUpdateCompleted(orderId));
  } catch(e) {
    dispatch(blogPageUpdateFailed([orderId, e.message]));
  }
};
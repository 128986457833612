import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import Modal from '../../components/LargeModal/LargeModal';
import contactUsSlice from '../../store/contactUsRequests/contact-us-requests-slice';
import { selectedContactUsRequestSelector, contactUsFormStateSelector } from '../../store/contactUsRequests/contact-us-requests-selector';
import { updateContactUsRequest } from '../../store/contactUsRequests/contact-us-requests-thunks';
import styles from './contact-us-details-modal.module.scss';
import ContactUsDetailsModalBody from './components/ContactUsDetailsModalBody';
import useSession from '../../custom_hooks/useSession';

const OrderDetailsModal = () => {
  const dispatch = useDispatch();
  const contactUsRequest = useSelector(selectedContactUsRequestSelector);
  const { form }= useSelector(contactUsFormStateSelector);
  const session = useSession();
  
  const { hideDetailsModal } = contactUsSlice.actions;
  
  const renderHeader = () => <h2>Contact Us Details</h2>;
  
  const renderBody = () => <ContactUsDetailsModalBody />;
  
  const handleClose = () => {
    dispatch(hideDetailsModal());
  };
  
  const handleSubmit = () => {
    if (session) {
      dispatch(updateContactUsRequest(session, form));
    }
  };

  const renderActions = () => (
    <div className={styles.actionsContainer}>
      <button type="button"
        className={classNames("pure-button", styles.cancelButton)} 
        onClick={handleClose}>
        Close
      </button>
      
      <button type="button"
        className={classNames("pure-button", styles.submitButton)} 
        onClick={handleSubmit}>
        Save 
      </button>
    </div>
  );
  
  return (
    <Modal
      onClose={handleClose}
      renderHeader={renderHeader}
      renderBody={renderBody}
      renderActions={renderActions}
    />
  );
};

export default OrderDetailsModal;

import api from '../../api';
import { AppThunk } from '../index';
import HashMap from '../../models/HashMap';
import ExerciseProgramFormField from '../../models/ExerciseProgramFormField.enum';
import exerciseProgramSlice from './exercise-programs-slice';
import SessionData from '../../models/SessionData';


export const postNewExerciseProgram = (
    userId: string,
    form: HashMap<ExerciseProgramFormField, string>,
): AppThunk => async(dispatch) => {
  const {
    submittingExerciseProgramStarted,
    submittingExerciseProgramCompleted,
    submittingExerciseProgramFailed,
    hideExerciseProgramModal,
    clearForm,
  } = exerciseProgramSlice.actions;

  dispatch(submittingExerciseProgramStarted());
  
  try {

    await api.exercisePrograms.postNewExerciseProgram(userId, form);

    dispatch(submittingExerciseProgramCompleted());
    dispatch(clearForm());
    dispatch(hideExerciseProgramModal())
  } catch(e) {
    dispatch(submittingExerciseProgramFailed(e.message));
  }
};


export const subscribeToExercisePrograms = (userId: string): AppThunk => async(dispatch) => {
  const {
    subscribedToExercisePrograms,
    exerciseProgramsReceived,
    exerciseProgramsSubscriptionFailed,
  } = exerciseProgramSlice.actions;

  try {
    const subscriptionId = await api.exercisePrograms.subscribeToExercisePrograms(userId, (exercisePrograms) => {
      dispatch(exerciseProgramsReceived(exercisePrograms));
    });
    dispatch(subscribedToExercisePrograms(subscriptionId));
  } catch(e) {
    dispatch(exerciseProgramsSubscriptionFailed(e.message));
  }
}
      
export const unsubscribeToExercisePrograms = (subscriptionId: string): AppThunk => async(dispatch) => {
  const {
    unsubscribedFromExercisePrograms,
  } = exerciseProgramSlice.actions;

  api.exercisePrograms.unsubscribeFromExercisePrograms(subscriptionId);
  
  dispatch(unsubscribedFromExercisePrograms());
}

export const updateExerciseProgram = (session: SessionData, form: HashMap<ExerciseProgramFormField, string>): AppThunk => async(dispatch) => {
  const {
    exerciseProgramUpdateRequested,
    exerciseProgramUpdateCompleted,
    exerciseProgramUpdateFailed,
  } = exerciseProgramSlice.actions;
  
  const exerciseProgramId = form.getWithDefault(ExerciseProgramFormField.exerciseProgramId, '');

  dispatch(exerciseProgramUpdateRequested(exerciseProgramId));
  
  try {
    await api.exercisePrograms.updateExerciseProgram(session.userId, form);

    dispatch(exerciseProgramUpdateCompleted(exerciseProgramId));
  } catch(e) {
    dispatch(exerciseProgramUpdateFailed([exerciseProgramId, e.message]));
  }
};
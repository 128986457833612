import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import Modal from '../../components/Modal/Modal';
import styles from './manage-exercise-modal.module.scss';
import ModalBody from './components/ManageExerciseModalBody';
import useSession from '../../custom_hooks/useSession';
import epeSlice from '../../store/exerciseProgramExercises/exercise-program-exercises-slice';
import { manageExerciseSelector } from '../../store/exerciseProgramExercises/exercise-program-exercises-selector';
import { updateExerciseProgramExercise  } from '../../store/exerciseProgramExercises/exercise-program-exercises-thunks';
import { toExerciseProgramExercise } from '../../models/ExerciseProgramExercise';

const ClientModal = () => {
  const dispatch = useDispatch();
  const { form } = useSelector(manageExerciseSelector);
  const session = useSession();
  
  const renderHeader = () => <h2>Client</h2>;
  
  const renderBody = () => <ModalBody />;
  
  const handleClose = () => {
    dispatch(epeSlice.actions.closeManageExerciseModal());
  };
  
  const handleSubmit = () => {
    if (session) {
      const epe = toExerciseProgramExercise(form);
      dispatch(updateExerciseProgramExercise(session, epe));
    }
  };

  const renderActions = () => (
    <div className={styles.actionsContainer}>
      <button type="button"
        className={classNames("pure-button", styles.cancelButton)} 
        onClick={handleClose}>
        Close
      </button>
      
      <button type="button"
        className={classNames("pure-button", styles.submitButton)} 
        onClick={handleSubmit}>
        Save 
      </button>
    </div>
  );
  
  return (
    <Modal
      onClose={handleClose}
      renderHeader={renderHeader}
      renderBody={renderBody}
      renderActions={renderActions}
    />
  );
};

export default ClientModal;

import MediaSource from './MediaSource';

export enum ExerciseUnit {
  lbs = 'lbs',
  seconds = 'seconds',
  minutes = 'minutes',
  none = 'none',
}


interface Exercise {
  exerciseId: string;
  exerciseName: string;
  unit: ExerciseUnit;
  category: string
  created: Date;
  imageSrc: string;
  description: string;
  mediaSources: MediaSource[];
}

export default Exercise;

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../root-reducer';

export const exercisesStateSelector = (state: RootState) => state.exercises;

export const newExerciseModalIsVisibleSelector = createSelector(
  exercisesStateSelector,
  (exercisesState) => exercisesState.newExercisesModalIsVisible
);

export const editExerciseModalIsVisibleSelector = createSelector(
  exercisesStateSelector,
  (exercisesState) => exercisesState.editExercisesModalIsVisible
);

export const newExerciseFormSelector = createSelector(
  exercisesStateSelector,
  (exercisesState) => ({
    isValid: exercisesState.newExerciseFormIsValid,
    fields: exercisesState.newExerciseForm,
    validationErrors: exercisesState.validationErrors,
    isSubmitting: exercisesState.isSubmittingNewExercise,
    selectedExercise: exercisesState.selectedExercise,
    mediaSources: exercisesState.newExerciseFormMedia,
  }),
);

export const editExerciseFormSelector = createSelector(
  exercisesStateSelector,
  (exercisesState) => ({
    isValid: exercisesState.editExerciseFormIsValid,
    fields: exercisesState.editExerciseForm,
    validationErrors: exercisesState.validationErrorsForEdit,
    isSubmitting: exercisesState.isSubmittingExercise,
    selectedExercise: exercisesState.selectedExercise,
    mediaSources: exercisesState.editExerciseFormMedia,
  }),
);

export const exercisesSelector = createSelector(exercisesStateSelector,
(exercisesState) => ({
  exercises: exercisesState.exercises,
  hasSubscribed: exercisesState.hasSubscribedToExercises,
  subscriptionId: exercisesState.subscriptionId,
}));

export const updatingExerciseSelector = createSelector(exercisesStateSelector, (exercisesState) => ({
  updatingExerciseIds: exercisesState.updatingExerciseIds,
  updatingErrors: exercisesState.updatingErrors,
}));

import * as firebase from 'firebase';
import { v4 as createUUID } from 'uuid';
import Preference, { PreferenceIdKey, toPreference } from '../models/Preference';
import HashMap from '../models/HashMap';
import SummaryCardQueriesMap from './SummaryCardQueries';
import SessionData from '../models/SessionData';
import { getAppDoc } from './Helpers';

export const PREFERENCES_PATH = 'preferences';
export const SUMMARY_CARDS_PATH = 'activeSummaryCards';
export const ACTIVE_SUMMARY_CARDS_KEY_PATH = 'activeSummaryCardsKeys';

type PreferencesUpdatedHandler = (preferences: Preference[]) => void;
const SUBSCRIPTION_CACHE: Map<string, () => void> = new Map();

const PreferencesAPI = {
  subscribeToPreferences: async (session: SessionData, onPreferencesUpdated: PreferencesUpdatedHandler) => {
    const appDoc = getAppDoc(session);
    const preferencesCollection = appDoc.collection(PREFERENCES_PATH);
    
    const unsubscribe = preferencesCollection.onSnapshot((snapshot) => {
      const preferences: Preference[] = [];

      snapshot.forEach((doc) => {
        const data = doc.data();
        preferences.push(toPreference(doc.id, data));
      });

      onPreferencesUpdated(preferences);
    });
    
    const subscriptionUUID = createUUID();

    SUBSCRIPTION_CACHE.set(subscriptionUUID, unsubscribe);

    return subscriptionUUID;
  },

  unsubscribeFromPreferences: (subscriptionId: string) => {
    if (SUBSCRIPTION_CACHE.has(subscriptionId)) {
      const unsubscribe = SUBSCRIPTION_CACHE.get(subscriptionId);
      unsubscribe && unsubscribe();
      SUBSCRIPTION_CACHE.delete(subscriptionId);
    }
  },

  updatePreference: async (session: SessionData, preference: Preference) => {
    const appDoc = getAppDoc(session);
    const preferencesCollection = appDoc.collection(PREFERENCES_PATH);
    const preferenceDoc = preferencesCollection.doc(preference.id);

    return preferenceDoc.set({
      active: preference.active,
      preferenceType: preference.preferenceType,
      updated: (new Date()).getTime(),
    });
  },

  getSummaryCardSummaryValues: async (
    session: SessionData,
    summaryCardPreferenceIdKeys: PreferenceIdKey[]
  ) => {
    const queries = summaryCardPreferenceIdKeys.map<Promise<[PreferenceIdKey, number]>>(async (prefIdKey) => {
      const query = SummaryCardQueriesMap.get(prefIdKey);
      if (query) {
        const value = await query(session);
        return [prefIdKey, value];
      } else {
        return [prefIdKey, 0];
      }
    });

    const queryResults = await Promise.all(queries);
    
    const summaryCardValues = new HashMap<PreferenceIdKey, number>();
    
    queryResults.forEach(result => {
      const [preferenceIdKey, value] = result;
      summaryCardValues.set(preferenceIdKey, value);
    });
    
    return summaryCardValues;
  }
};

export default PreferencesAPI;

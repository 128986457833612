export enum BlogPageState {
    active = 'active',
    inactive = 'inactive',
    draft = 'draft',
}

export interface BlogPage {
    blogPageId: string;
    header: string;
    subheader: string;
    content: string;
    created: string;
    updated: string;
    blogPageState: BlogPageState;
}

export default BlogPage;
import firebase from 'firebase';
import HashMap from '../models/HashMap';
import SignUpField from '../models/SignUpField.enum';
import User from '../models/User';

type AuthStateChangeHandler = (user: User|null) => void;

const authAPI = {
  signUp: async (signUpForm: HashMap<SignUpField, string>) => {
    const email = signUpForm.getWithDefault(SignUpField.email, "");
    const password = signUpForm.getWithDefault(SignUpField.password, "");
    const displayName = signUpForm.getWithDefault(SignUpField.displayName, "");

    return firebase.auth().createUserWithEmailAndPassword(email, password)
    .then(() => {
        const user = firebase.auth().currentUser;
        
        if (user) {
            return user.updateProfile({
                displayName,
            });
        }
    })
  },
  signIn: async (email: string, password: string) => {
    firebase.auth().signInWithEmailAndPassword(email, password);
  },
  subscribeToAuthStateChange: (onAuthStateChangeHandler: AuthStateChangeHandler) => {
    firebase.auth().onAuthStateChanged((payload) => {
      if (!payload) {
        onAuthStateChangeHandler(null);
        return;
      }

      const user = {
        uid: payload.uid,
        email: payload.email,
        displayName: payload.displayName,
      } as User;

      onAuthStateChangeHandler(user);
    });
  },
  signOut: () => {
   return firebase.auth().signOut(); 
  },
};

export default authAPI;

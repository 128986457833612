import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../root-reducer';

export const exerciseProgramsStateSelector = (state: RootState) => state.exercisePrograms;


export const selectedExerciseProgramSelector = createSelector(exerciseProgramsStateSelector, (state) => state.selectedExerciseProgram);

export const exerciseProgramModalIsVisibleSelector = createSelector(exerciseProgramsStateSelector, (state) => state.exerciseProgramModalIsVisible);

export const selectedExerciseProgramForModal = createSelector(exerciseProgramsStateSelector, (state) => state.exerciseProgramForModal);

export const exerciseProgramFormSelector = createSelector(exerciseProgramsStateSelector,
  (state) => ({
    form: state.exerciseProgramForm,
    formIsValid: state.formIsValid,
    validationErrors: state.validationErrors,
    exerciseProgramForModal: state.exerciseProgramForModal,
    isModalSubmitting: state.isModalSubmitting,
  }));

export const exerciseProgramDetailsSelector = createSelector(exerciseProgramsStateSelector,
  (state) => ({
    exerciseProgramForModal: state.exerciseProgramForModal,
    form: state.exerciseProgramForm,
    formIsValid: state.formIsValid,
    validationErrors: state.validationErrors,
    isProcessing: state.isProcessing,
  }));
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../root-reducer';

export const epcStateSelector = (state: RootState) => state.exerciseProgramClients;


export const clientsSelector = createSelector(epcStateSelector,
(epcState) => ({
  exerciseProgramClients: epcState.exerciseProgramClients,
  hasSubscribed: epcState.hasSubscribedToExerciseProgramClients,
  subscriptionId: epcState.subscriptionId,
}));

export const updatingClientSelector = createSelector(epcStateSelector, (epcState) => ({
  updatingClientIds: epcState.updatingExerciseProgramClientsIds,
  updatingErrors: epcState.updatingErrors,
  isProcessing: epcState.isProcessing,
}));
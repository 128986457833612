import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import styles from './exercises-container.module.scss';
import Loading from '../Loading/Loading';
import ExercisesTableRow from './ExercisesTableRow';
import Exercise from '../../models/Exercise';
import PlusIcon from '../Icons/PlusIcon';
import exercisesSlice from '../../store/exercises/exercises-slice';

interface ExercisesContainerProps {
  exercises: Exercise[];
  isLoading: boolean;
}

const ExercisesContainer = ({ exercises, isLoading }: ExercisesContainerProps) => {
  const dispatch = useDispatch();
  const [filterVal, setFilterVal] = useState('');
  const [filteredExercises, setFilteredExercises] = useState([] as Exercise[]);
  const {
    showNewExerciseModal,
  } = exercisesSlice.actions;
  
  useEffect(() => {
    setFilteredExercises(exercises);
  }, [exercises]);

  const handleFilterChange = (ev: React.FormEvent<HTMLInputElement>) => {
    const val = ev.currentTarget.value;
    const valUpperCase = val.toUpperCase();

    if (!val) {
      setFilteredExercises(exercises);
    } else {
      setFilteredExercises(exercises.filter(exercise =>
        exercise.exerciseName.toUpperCase().indexOf(valUpperCase) > -1
        || exercise.category.toLocaleUpperCase().indexOf(valUpperCase) > -1
        || exercise.description.toLocaleUpperCase().indexOf(valUpperCase) > -1
      ));
    }
    
    setFilterVal(val);
  };

  const handleNewExerciseClick = () => {
    dispatch(showNewExerciseModal());
  };

  const noExercises = !isLoading && !(filteredExercises && filteredExercises.length);

  return (<>
    <section className={classNames("pure-u-1", styles.headerSection)}>
      <h1>Exercises</h1>
      <button className={classNames("pure-button", styles.newExerciseButton)}
        onClick={handleNewExerciseClick}
      >
        <span className={styles.iconContainer}>
          <PlusIcon />
        </span>
        Add Exercise
      </button>
    </section>
    <section className={classNames("pure-form", styles.exercisesFilterContainer)}>
      <input type="text" placeholder="Filter Exercises..."
             className={classNames("pure-u-1 pure-u-md-1-2 pure-u-lg-1-4 pure-input-rounded", styles.filterPurchases)}
             value={filterVal}
             onChange={handleFilterChange}
      />
    </section>
    
    <section className={styles.tableContainer}>
      <table className={classNames("pure-table", styles.exercisesTable)}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Category</th>
            <th>Description</th>
            <th colSpan={2}>Thumbnail</th>
          </tr>
        </thead>
        <tbody>
          { isLoading && (
            <tr className={styles.infoRow}>
              <td colSpan={6}><Loading></Loading></td>
            </tr>
          )}
          {  noExercises && (
            <tr className={styles.infoRow}>
                <td colSpan={6}><strong>
                  { filterVal
                    ? "No exercises matches your filter"
                    : "No exercises to display"
                  }
                  
                </strong></td>
            </tr>
          )}

          {!noExercises && filteredExercises
            .map((exercise, i) => 
              (<ExercisesTableRow exercise={exercise} index={i} key={exercise.exerciseId} />)
            )
          }
        </tbody>
      </table>
    </section>
  </>);
};

export default ExercisesContainer;

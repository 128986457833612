import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { messagesStateSelector } from '../messages-selectors';
import { subscribeToMessages, unsubscribeFromMessages } from '../messages-actions';
import useSession from '../../../custom_hooks/useSession';

const useMessages = function useMessages(clientEmail: string) {
    const dispatch = useDispatch();
    const {
        isLoading,
        hasSubscribed,
        messages,
        subscriptionError,
        subscriptionId,
    } = useSelector(messagesStateSelector);
    const session = useSession();

    // useEffect(() => {
    //   if (subscriptionId) {
    //     unsubscribeFromMessages(subscriptionId);
    //   }
    // }, []);
    
    useEffect(() => {
      if (!(hasSubscribed || isLoading)) {
        dispatch(subscribeToMessages(session, clientEmail));
      }
      
      if (subscriptionId) {
        return () => { dispatch(unsubscribeFromMessages(subscriptionId)); };
      }
    }, [clientEmail]); 
    
    return {
        isLoading,
        hasSubscribed,
        messages,
        subscriptionError,
    };
}

export default useMessages;
import SummaryCard from '../../models/SummaryCard';
import { PreferenceIdKey } from '../../models/Preference';

const InventoryOutOfStock = {
  description: 'The number of items out of stock',
  headerText: 'Out of Stock Items',
  key: PreferenceIdKey.INVENTORY_OUT_OF_STOCK,
  link: '/inventory'
} as SummaryCard;

export default InventoryOutOfStock;
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import styles from './expandable-nav.module.scss';
import ChevronDownIcon from '../../../Icons/ChevronDownIcon';
import ChevronUpIcon from '../../../Icons/ChevronUpIcon';
import LoadingWithOverlay from '../../../../components/LoadingWithOverlay/LoadingWithOverlay';
import Overlay from '../../../../components/Overlay/Overlay';
import navigationSlice, { NavItem } from '../../../../store/navigation/navigation-slice';
import { expandedNavsSelector } from '../../../../store/navigation/navigation-selectors';
import NavLink from '../../../../models/NavLink';

interface ExpandableNavProps {
    navItem: NavItem;
    headerNavText: string;
    childLinks: NavLink[];
    onNavClick: (path: string) => void;
    isLoading: boolean;
}

const ExpandableNav = ({ headerNavText, childLinks, isLoading, onNavClick, navItem }: ExpandableNavProps) => {
    const dispatch = useDispatch();
    const expandedNavs = useSelector(expandedNavsSelector);
    const { toggleExpandedNav } = navigationSlice.actions;

    const isExpanded = expandedNavs.getWithDefault(navItem, false);

    const toggleExpand = () => {
        dispatch(toggleExpandedNav(navItem));
    };

    const handleChildClick = (path: string) => (ev: React.MouseEvent<HTMLElement>) => {
        onNavClick(path);
        ev.stopPropagation();
    };

    return (
        <li className={classnames(styles.headerNavLi)} onClick={toggleExpand}>
            { isLoading && isExpanded && (<LoadingWithOverlay contained/>)}
            { isLoading && !isExpanded && (<Overlay contained>""</Overlay>)}
            <header className={styles.header}>
                <span className={styles.headerText}>{headerNavText}</span>
                <span className={styles.headerIcon}>
                    { isExpanded
                        ? (<ChevronUpIcon />)
                        : (<ChevronDownIcon />)
                    }
                </span>
            </header>
            <ul className={classnames(styles.childLinksUl, { [styles.hide]: !isExpanded })}>
                <hr />
                { isExpanded && (childLinks.map(childLink => (
                    <li className={styles.childLinkLi} onClick={handleChildClick(childLink.path)} key={childLink.path}>
                        {childLink.PreIcon ? <childLink.PreIcon /> : null}
                        {childLink.text}
                    </li>
                )))}
            </ul>
        </li>
    );
}

export default ExpandableNav;
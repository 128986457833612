import api from '../../api';
import { AppThunk } from '../index';
import clientsSlice from './clients-slice';
import ClientFormField from '../../models/ClientFormField.enum';
import HashMap from '../../models/HashMap';
import SessionData from '../../models/SessionData';
import { ClientState } from '../../models/Client';


export const postNewClient = (
    session: SessionData,
    form: HashMap<ClientFormField, string>,
): AppThunk => async(dispatch) => {
  const {
    submittingClientStarted,
    submittingClientCompleted,
    submittingClientFailed,
    hideClientModal,
    clearForm,
  } = clientsSlice.actions;

  dispatch(submittingClientStarted());
  
  try {

    await api.clients.postNewClient(session, form);
    
    const email = form.getWithDefault(ClientFormField.email, '');
    const clientState = form.getWithDefault(ClientFormField.clientState, '');

    if (email && clientState) {
      const clientStateEnumVal = clientState.toLowerCase() === 'active'
      ? ClientState.active
      : ClientState.inactive;

      await api.publicClient.updateClientStatus(session, email,  clientStateEnumVal);
    }

    dispatch(submittingClientCompleted());
    dispatch(clearForm());
    dispatch(hideClientModal());
  } catch(e) {
    dispatch(submittingClientFailed(e.message));
  }
};

export const subscribeToClients = (session: SessionData): AppThunk => async(dispatch) => {
  const {
    subscribedToClients,
    clientsReceived,
    clientsSubscriptionFailed,
  } = clientsSlice.actions;

  try {
    const subscriptionId = await api.clients.subscribeToClients(session, (clients) => {
      dispatch(clientsReceived(clients));
    });
    dispatch(subscribedToClients(subscriptionId));
  } catch(e) {
    dispatch(clientsSubscriptionFailed(e.message));
  }
}
      
export const unsubscribeToClients = (subscriptionId: string): AppThunk => async(dispatch) => {
  const {
    unsubscribedFromClients,
  } = clientsSlice.actions;

  api.clients.unsubscribeFromClients(subscriptionId);
  
  dispatch(unsubscribedFromClients());
}

export const updateClient = (session: SessionData, form: HashMap<ClientFormField, string>): AppThunk => async(dispatch) => {
  const {
    clientUpdateRequested,
    clientUpdateCompleted,
    clientUpdateFailed,
  } = clientsSlice.actions;
  
  const clientId = form.getWithDefault(ClientFormField.clientId, '');

  dispatch(clientUpdateRequested(clientId));
  
  try {
    const updatedClient  = await api.clients.updateClient(session, form);
    await api.exerciseProgramClients.updateClientForAllExercisePrograms(session, updatedClient);
    
    await api.publicClient.updateClientStatus(session, updatedClient.email, updatedClient.clientState);

    dispatch(clientUpdateCompleted(clientId));
  } catch(e) {
    dispatch(clientUpdateFailed([clientId, e.message]));
  }
};

export const requestClientDetails = (clientsEmails: string[]): AppThunk => async(dispatch) => {
  const {
    clientDetailsRequested,
    clientDetailsRequestSucceeded,
    clientDetailsRequestFailed,
  } = clientsSlice.actions;
  
  dispatch(clientDetailsRequested());
  
  try {
    const clientDetails = await api.publicClient.getClientDetails(clientsEmails);
    dispatch(clientDetailsRequestSucceeded(clientDetails));
  } catch (e) {
    dispatch(clientDetailsRequestFailed(e.message));
  }
};
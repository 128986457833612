import AddOn from '../../models/AddOn';
import { PreferenceIdKey } from '../../models/Preference';

import InventoryAddOn from './InventoryAddOn';
import OrderTrackingAddOn from './OrderTrackingAddOn';
import ContactUsAddOn from './ContactUsAddOn';
import BlogPageAddon from './BlogPageAddOn';

const addOnMap = new Map<PreferenceIdKey, AddOn>();
addOnMap.set(PreferenceIdKey.INVENTORY, InventoryAddOn);
addOnMap.set(PreferenceIdKey.ORDER_TRACKING, OrderTrackingAddOn);
addOnMap.set(PreferenceIdKey.CONTACT_US, ContactUsAddOn);
addOnMap.set(PreferenceIdKey.BLOG_PAGE, BlogPageAddon);

export default addOnMap;
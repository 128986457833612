import api from '../../api';
import { AppThunk } from '../index';
import exercisesSlice, { tempFileStore } from './exercises-slice';
import FormFields from '../../models/NewExerciseFormField.enum';
import Exercise from '../../models/Exercise';
import ExerciseField from '../../models/ExerciseField.enum';
import HashMap from '../../models/HashMap';
import SessionData from '../../models/SessionData';
import MediaSource, { MediaSourceType } from '../../models/MediaSource';
import { showError, showSuccess } from '../../alert/alert';

const EXERCISE_MEDIA_PATH = 'exercisesMedia';

export const postNew = (session: SessionData, form: HashMap<FormFields, string>, mediaSources: MediaSource[]): AppThunk => async(dispatch) => {
  const {
    submittingExerciseStarted,
    submittingExerciseCompleted,
    submittingExerciseFailed,
    clearForm,
    hideNewExerciseModal,
  } = exercisesSlice.actions;

  dispatch(submittingExerciseStarted());
  
  try {
    
    const mediaSaves = mediaSources.map(async (media) => {
      if (media.mediaSourceType === MediaSourceType.File) {
        
        const file = tempFileStore.get(media.mediaSourceId);
        
        if (file) {
          return await api.storage.createStorageItem(session, EXERCISE_MEDIA_PATH, file, media);
        } else {
          return media;
        }
      } else {
        const youtubeMedia = {
          ...media,
          mediaSourceUrl: media.mediaSourceFileName,
        } as MediaSource;
        
        return youtubeMedia;
      }
    })
    
    const savedSources = await Promise.all(mediaSaves) as MediaSource[];

    await api.exercises.postNewExercise(session, form, savedSources);

    dispatch(submittingExerciseCompleted());
    dispatch(clearForm());
    dispatch(hideNewExerciseModal());
    
    showSuccess(`${form.getWithDefault(ExerciseField.exerciseName, 'Exercise')} updated`);
  } catch(e) {
    dispatch(submittingExerciseFailed(e.message));
    showError('Error Saving Exercise');
  }
};

export const subscribeToExercises = (session: SessionData): AppThunk => async(dispatch) => {
  const {
    subscribedToExercises,
    exercisesReceived,
    exercisesSubscriptionFailed,
  } = exercisesSlice.actions;

  try {
    const subscriptionId = await api.exercises.subscribeToExercise(session, (items) => {
      dispatch(exercisesReceived(items));
    });
    dispatch(subscribedToExercises(subscriptionId));
  } catch(e) {
    dispatch(exercisesSubscriptionFailed(e.message));
  }
}
      
export const unsubscribeToExercises = (subscriptionId: string): AppThunk => async(dispatch) => {
  const {
    unsubscribedToExercises,
  } = exercisesSlice.actions;

  await api.exercises.unsubscribeFromExercise(subscriptionId);
  
  dispatch(unsubscribedToExercises());
}

export const updateExercise = (session: SessionData, oldExercise: Exercise, form: HashMap<ExerciseField, string>, mediaSources: MediaSource[]): AppThunk => async(dispatch) => {
  const {
    exerciseUpdateRequested,
    exerciseUpdateCompleted,
    exerciseUpdateFailed,
    clearEditForm,
    hideEditExerciseModal,
  } = exercisesSlice.actions;
  

  const deletedMediaSources = oldExercise.mediaSources
    .filter((oldMedia) => !mediaSources
      .some((media) => oldMedia.mediaSourceId === media.mediaSourceId));
    
  const deletedMedia = deletedMediaSources.map(async (media) => {
    if (media.mediaSourceRemotePath) {
      return api.storage.deleteStorageItem(media.mediaSourceRemotePath);
    }
  })

  const nuMediaSources = mediaSources
    .filter((media) => !oldExercise.mediaSources 
      .some((oldMedia) => oldMedia.mediaSourceId === media.mediaSourceId));
    
  const nuMediaSaves = nuMediaSources.map(async (media) => {
    if (media.mediaSourceType === MediaSourceType.File) {
      
      const file = tempFileStore.get(media.mediaSourceId);
      
      if (file) {
        return await api.storage.createStorageItem(session, EXERCISE_MEDIA_PATH, file, media);
      } else {
        return media;
      }
    } else {
        const youtubeMedia = {
          ...media,
          mediaSourceUrl: media.mediaSourceFileName,
        } as MediaSource;
        
        return youtubeMedia;
    }
  })

  const nuSavedSources = await Promise.all(nuMediaSaves) as MediaSource[];
  
  const exerciseId = form.getWithDefault(ExerciseField.exerciseId, '');
  
  const oldSavedMedia = mediaSources.filter((media) => !nuSavedSources.some((saved) => media.mediaSourceId === saved.mediaSourceId));
  const updatedMediaSources = [...oldSavedMedia, ...nuSavedSources];
  
  dispatch(exerciseUpdateRequested(exerciseId));
  
  try {
    await api.exercises.updateExercise(session, form, updatedMediaSources);

    dispatch(exerciseUpdateCompleted(exerciseId));
    dispatch(clearEditForm());
    dispatch(hideEditExerciseModal());
    
    showSuccess(`${form.getWithDefault(ExerciseField.exerciseName, 'Exercise')} updated`);
  } catch(e) {
    dispatch(exerciseUpdateFailed([exerciseId, e.message]));
    showError('Error saving exercise');
  }
};

export const deleteExercise = (session: SessionData, exercise: Exercise): AppThunk => async(dispatch) => {
  const {
    exerciseUpdateRequested,
    exerciseUpdateCompleted,
    exerciseUpdateFailed,
    clearEditForm,
    hideEditExerciseModal
  } = exercisesSlice.actions;

  dispatch(exerciseUpdateRequested(exercise.exerciseId));
  
  try {
    const deletedMedia = exercise.mediaSources.map(async (media) => {
      if (media.mediaSourceRemotePath) {
        return api.storage.deleteStorageItem(media.mediaSourceRemotePath);
      }
    })
    
    await Promise.all(deletedMedia);
  
    await api.exercises.deleteExercise(session, exercise.exerciseId);

    dispatch(exerciseUpdateCompleted(exercise.exerciseId));
    dispatch(clearEditForm());
    dispatch(hideEditExerciseModal());
    showSuccess('Exercise deleted');
  } catch(e) {
    dispatch(exerciseUpdateFailed([exercise.exerciseId, e.message]));
    showError('Deleting failed');
  }
};
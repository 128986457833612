import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../root-reducer';
import { PreferenceType, PreferenceIdKey } from '../../models/Preference';
import SummaryCard from '../../models/SummaryCard';
import SummaryCardMap from '../../structs/SummaryCards/SummaryCardsMap';
import AddOn from '../../models/AddOn';
import AddOnsMap from '../../structs/AddOns/AddOnsMap';

export const preferencesStateSelector = (state: RootState) => state.preferences;

export const preferencesModalIsVisibleSelector = createSelector(
  preferencesStateSelector,
  (state) => state.preferencesModalIsVisible,
);

export const activeSummaryCardsSelector = createSelector(preferencesStateSelector, (state) => {
  const {
    preferences,
  } = state;
  
  if (!(preferences && preferences.length)) return [] as SummaryCard[];
  
  return preferences
    .filter(preference => preference.preferenceType === PreferenceType.SUMMARY_CARD && preference.active)
    .map(preference => SummaryCardMap.get(preference.key)) as SummaryCard[];
});

export const modalFormPreferencesSelector = createSelector(preferencesStateSelector, (state) => state.modalFormPreferences);

export const summaryCardsStateSelector = createSelector([preferencesStateSelector, activeSummaryCardsSelector],
(state, activeSummaryCards) => {
  const {
    hasRequestedSummaryCardValues,
    summaryCardValueRequestError,
    summaryCardValues,
  } = state;
  
  return {
    hasRequestedSummaryCardValues,
    summaryCardValueRequestError,
    summaryCardValues,
    activeSummaryCards,
  };
});

export const activeAddOnsSelector = createSelector(preferencesStateSelector, (state) => {
  const {
    preferences,
  } = state;
  
  if (!(preferences && preferences.length)) return [] as AddOn[];
  
  return preferences
    .filter(preference => preference.preferenceType === PreferenceType.ADD_ON && preference.active)
    .map(preference => AddOnsMap.get(preference.key)) as AddOn[];
});

export const availableSummaryCardsSelector = createSelector(activeAddOnsSelector, (activeAddOns) => {
  return activeAddOns
    .reduce((availableSummaryCards, addOn) => {
      return [...availableSummaryCards, ...addOn.summaryCardIds]
    }, [] as PreferenceIdKey[])
    .map(summaryCardIdKey => SummaryCardMap.get(summaryCardIdKey)) as SummaryCard[];
})
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../root-reducer';

export const userAppsStateSelector = (state: RootState) => state.userApps;

export const selectedUserAppSelector = createSelector(userAppsStateSelector, (state) => state.selectedUserApp);

export const userAppModalIsVisibleSelector = createSelector(userAppsStateSelector, (state) => state.userAppModalIsVisible);

export const selectedUserAppForModal = createSelector(userAppsStateSelector, (state) => state.userAppForModal);

export const userAppFormSelector = createSelector(userAppsStateSelector,
  (state) => ({
    form: state.userAppForm,
    formIsValid: state.formIsValid,
    validationErrors: state.validationErrors,
    userAppForModal: state.userAppForModal,
    isModalSubmitting: state.isModalSubmitting,
  }));
import React from 'react'
import classNames from 'classnames';
import styles from './form-error.module.scss';

interface FormErrorProps {
    errorMessage?: string;
}

const FormError = ({ errorMessage }: FormErrorProps) => {
    if (!errorMessage) return null;

    return (
        <span className={classNames("pure-form-message form-error", styles.formError)}>
            {errorMessage}
        </span>
    );
};

export default FormError;

import api from '../../api';
import { AppThunk } from '../index';
import preferencesSlice from './preferences-slice';
import Preference, { PreferenceIdKey, PreferenceType } from '../../models/Preference';
import SessionData from '../../models/SessionData';

export const unsubscribeFromPreferences = (subscriptionId: string): AppThunk => async(dispatch) => {
  const {
    unsubscribedToPreferences,
  } = preferencesSlice.actions;

  api.preferences.unsubscribeFromPreferences(subscriptionId);
  
  dispatch(unsubscribedToPreferences());
}

export const updatePreferences = (session: SessionData, preferences: Preference[]): AppThunk => async(dispatch) => {
  const {
    preferenceUpdateRequested,
    preferenceUpdateCompleted,
    preferenceUpdateFailed,
  } = preferencesSlice.actions;
  
  const allUpdates = preferences.map(async(preference) => {
    try {
      dispatch(preferenceUpdateRequested(preference.id));

      await api.preferences.updatePreference(session, preference);
      
      dispatch(preferenceUpdateCompleted(preference.id));
    } catch(e) {
      dispatch(preferenceUpdateFailed([preference.id, e.message]));
    }
  });
  
  await Promise.all(allUpdates);
};

export const getSummaryCardValues = (session: SessionData, preferenceIdKeys: PreferenceIdKey[]): AppThunk => async(dispatch) => {
  const {
    summaryCardValuesRequested,
    summaryCardValuesRequestSucceeded,
    summaryCardValuesRequestFailed,
  } = preferencesSlice.actions;
  
  dispatch(summaryCardValuesRequested());

  try {
    const summaryCardValues = await api.preferences.getSummaryCardSummaryValues(session, preferenceIdKeys);
    dispatch(summaryCardValuesRequestSucceeded(summaryCardValues));
  } catch(e) {
    dispatch(summaryCardValuesRequestFailed(e.message));
  }
};

export const subscribeToPreferences = (session: SessionData): AppThunk => async(dispatch) => {
  const {
    preferencesReceived,
    subscribedToPreferences,
    preferencesSubscriptionFailed
  } = preferencesSlice.actions;

  try {
    const subscriptionId = await api.preferences.subscribeToPreferences(session, (preferences) => {
      dispatch(preferencesReceived(preferences));

      const activeSummaryCardsIdKeys = preferences
        .filter(pref => pref.preferenceType === PreferenceType.SUMMARY_CARD && pref.active)
        .map(summaryCardPreference => summaryCardPreference.id);

      dispatch(getSummaryCardValues(session, activeSummaryCardsIdKeys));
    });
    dispatch(subscribedToPreferences(subscriptionId));
  } catch(e) {
    dispatch(preferencesSubscriptionFailed(e.message));
  }
}
      
import { format } from "date-fns";
import { ExerciseUnit } from "./Exercise";

export enum StatRecordType {
    SETS = 'SETS',
    REPS = 'REPS',
}

interface StatRecord {
    exerciseId: string;
    statRecordId: string;
    statCategory: string;
    unit: ExerciseUnit;
    value: number;
    date: string;
    statRecordType: StatRecordType;
}

export const generateStatRecordCategory = (date: Date) => {
    return format(date, 'yyyyMMdd');
};

export default StatRecord;
import firebase from 'firebase';
import { v4 as createUUID } from 'uuid';
import HashMap from '../models/HashMap';
import ClientFormField from '../models/ClientFormField.enum';
import Client, { ClientState } from '../models/Client';
import SessionData from '../models/SessionData';
import { getAppDoc, getUserRootDoc } from './Helpers';
import StatRecord from '../models/StatRecord';
import ClientEvent from '../models/ClientEvent';
import ClientDetails from '../models/ClientDetails';


type clientsUpdatedHandler = (clients: Client[]) => void;
type clientsDetailsUpdatedHandler = (clients: Client[]) => void;

const SUBSCRIPTION_CACHE: Map<string, () => void> = new Map();

export const CLIENTS_PATH= 'clients';

const clientsAPI = {
  postNewClient: async (session: SessionData, form: HashMap<ClientFormField, string>) => {
    const rootDoc = getUserRootDoc(session);
    const clientsCollection = rootDoc.collection(CLIENTS_PATH);

    return clientsCollection.add({
      email: form.getWithDefault(ClientFormField.email, ''),
      clientName: form.getWithDefault(ClientFormField.clientName, ''),
      clientState: form.getWithDefault(ClientFormField.clientState, ''),
      created: (new Date()).toString()
    }).then();
  },

  subscribeToClients: async (session: SessionData, clientsUpdated: clientsUpdatedHandler) => {
    const rootDoc = getUserRootDoc(session);
    const clientsCollection = rootDoc.collection(CLIENTS_PATH);

    const unsubscribe = clientsCollection.onSnapshot((snapshot) => {
      let storeClients: Client[] = [];

      snapshot.forEach((doc) => {
        const data = doc.data();
        const client: Client = {
          clientId: doc.id as string,
          email: data.email,
          clientName: data.clientName,
          clientState: data.clientState,
        };

        storeClients = [...storeClients, client];
      });

      clientsUpdated(storeClients);
    });
    
    const subscriptionUUID = createUUID();

    SUBSCRIPTION_CACHE.set(subscriptionUUID, unsubscribe);

    return subscriptionUUID;
  },

  unsubscribeFromClients: (subscriptionId: string) => {
    if (SUBSCRIPTION_CACHE.has(subscriptionId)) {
      const unsubscribe = SUBSCRIPTION_CACHE.get(subscriptionId);
      unsubscribe && unsubscribe();
      SUBSCRIPTION_CACHE.delete(subscriptionId);
    }
  },

  updateClient: async (session: SessionData, form: HashMap<ClientFormField, string>) => {
    const clientId = form.get(ClientFormField.clientId) as string;

    const rootDoc = getUserRootDoc(session);
    const clientsCollection = rootDoc.collection(CLIENTS_PATH);
    const clientDoc = clientsCollection.doc(clientId);
    
    await clientDoc.update({
      [ClientFormField.email]: form.getWithDefault(ClientFormField.email, ''),
      [ClientFormField.clientName]: form.getWithDefault(ClientFormField.clientName, ''),
      [ClientFormField.clientState]: form.getWithDefault(ClientFormField.clientState, ''),
      updated: (new Date()).toString(),
    });
    
    const doc = await clientDoc.get();
    const data = doc.data();
    const updatedClient  = {
      clientId: doc.id,
      email: data?.email,
      clientName: data?.clientName,
      clientState: data?.clientState,
    } as Client;
    
    return updatedClient;
  },
  
};


export default clientsAPI;

import firebase from 'firebase';
import { v4 as createUUID } from 'uuid';
import { userInfo } from 'os';
import { ClientState } from '../models/Client';
import Message from '../models/Message';
import SessionData from '../models/SessionData';
import User from '../models/User';
import { getExerciseProgramPath } from './Helpers';
import { stringify } from 'querystring';
import ClientDetails from '../models/ClientDetails';
import ClientEvent from '../models/ClientEvent';
import StatRecord from '../models/StatRecord';

const PUBLIC_PATH = 'public';
const PUBLIC_CLIENT_PATH = 'client';
const PUBLIC_CLIENT_EXERCISE_PROGRAM_PATH = 'exerciseProgram';
const PUBLIC_CLIENT_TRAINER_PATH = 'trainer';
const PUBLIC_CLIENT_STATUS_PATH = 'status';
const PUBLIC_CLIENT_MESSAGES_PATH = 'messages';

enum DataPaths {
  events = 'events',
  stats = 'stats',
  status = 'status',
}

type MessagesUpdatedHandler = (clients: Message[]) => void;

const SUBSCRIPTION_CACHE: Map<string, () => void> = new Map();


const getPublicClientCollection = (clientEmail: string) => {
    return firebase.firestore()
        .collection(PUBLIC_PATH)
        .doc(PUBLIC_CLIENT_PATH)
        .collection(clientEmail)
}

const getPublicClientTrainerDoc = (session: SessionData, clientEmail: string) => {
    return  getPublicClientCollection(clientEmail).doc(PUBLIC_CLIENT_TRAINER_PATH).collection(session.userId);
}

const PublicClientAPI = {
    addExerciseProgram:  async (session: SessionData, clientEmail: string) => {
        const clientProgram = getPublicClientTrainerDoc(session, clientEmail)
            .doc(PUBLIC_CLIENT_EXERCISE_PROGRAM_PATH)

        await clientProgram.set({
            [session.selectedExerciseProgramId]: getExerciseProgramPath(session),
        }, { merge: true });
    },
    removeExerciseProgram:  async (session: SessionData, clientEmail: string) => {
        const clientProgram = getPublicClientTrainerDoc(session, clientEmail)
            .doc(PUBLIC_CLIENT_EXERCISE_PROGRAM_PATH)

        await clientProgram.set({
            [session.selectedExerciseProgramId]: firebase.firestore.FieldValue.delete(),
        }, { merge: true });
    },
    updateClientStatus: async(session: SessionData, clientEmail: string, status: ClientState) => {
        const clientStatus = getPublicClientTrainerDoc(session, clientEmail)
            .doc(PUBLIC_CLIENT_STATUS_PATH)
        await clientStatus.set({
          value: status,
        });
    },
    subscribeToMessages: async (session: SessionData, clientEmail: string, messagesUpdated: MessagesUpdatedHandler) => {
        const clientColl = getPublicClientCollection(clientEmail);
        const messagesDoc = clientColl.doc(PUBLIC_CLIENT_MESSAGES_PATH);

        const unsubscribe = messagesDoc.onSnapshot((snapshot) => {
            let storeMessages: Message[] = [];
            
            const docData = snapshot.data();
            
            if (docData) {
                const keys = Object.keys(docData);
                
                keys.forEach((k) => {
                  const data = docData[k];

                  const message: Message = {
                      messageId: k,
                      message: data.message,
                      messageTo: data.messageTo,
                      messageFrom: data.messageFrom,
                      timestamp: data.timestamp,
                      isUserMessage: data.isUserMessage,
                      trainerId: data.trainerId,
                  };

                  storeMessages = [...storeMessages, message];
                });

            }


          messagesUpdated(storeMessages);
        });
        
        const subscriptionUUID = createUUID();

        SUBSCRIPTION_CACHE.set(subscriptionUUID, unsubscribe);

        return subscriptionUUID;
    },

    unsubscribeFromMessages: (subscriptionId: string) => {
        if (SUBSCRIPTION_CACHE.has(subscriptionId)) {
            const unsubscribe = SUBSCRIPTION_CACHE.get(subscriptionId);
            unsubscribe && unsubscribe();
            SUBSCRIPTION_CACHE.delete(subscriptionId);
        }
    },
    
    addMessage: (session: SessionData, clientEmail: string, message: string) => {
        const clientColl = getPublicClientCollection(clientEmail);
        const messagesDoc = clientColl.doc(PUBLIC_CLIENT_MESSAGES_PATH);
        const messageId = createUUID();

        messagesDoc.set({
            [messageId]: {
                messageId,
                message,
                messageTo: clientEmail,
                messageFrom: session.userId,
                timestamp: (new Date()).toString(),
                trainerId: session.userId,
                isUserMessage: false,
            }
        }, { merge: true })
    },

    getClientDetails: async (clientsEmails: string[]) => {
      const clientDetails = clientsEmails.map(async (email) => {
        const events = await PublicClientAPI.getEvents(email);
        const stats = await PublicClientAPI.getStatRecords(email);
        return { email, events, stats } as ClientDetails;
      });
      
      return await Promise.all(clientDetails);
    },
    
    getEvents: async (email: string):Promise<ClientEvent[]> => {
      const getEvents = getPublicClientCollection(email).doc(DataPaths.events).get();
      const response = await getEvents;
      
      const events: ClientEvent[] = [];
      
      const data = response.data();
      
      if (data && Object.keys(data).length) {
          Object.keys(data).forEach((k) => {
              events.push(...data[k]);
          });
      }
      
      return events;
    },

    getStatRecords: async (email: string):Promise<StatRecord[]> => {
        const getStatRecords = getPublicClientCollection(email).doc(DataPaths.stats).get();
        const response = await getStatRecords;
        
        const statRecords: StatRecord[] = [];
        
        const data = response.data();
        
        if (data && Object.keys(data).length) {
            Object.keys(data).forEach((k) => {
                statRecords.push(...data[k]);
            });
        }
        
        return statRecords;
    },
};

export default PublicClientAPI;
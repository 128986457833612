import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Summary = {
  outOfStockInventoryItems: number;
  contactUsRequestReceived: number;
  incompleteOrders: number;
};

interface HomeState {
  outOfStockInventoryItems: number;
  contactUsRequestReceived: number;
  incompleteOrders: number;
  isLoading: boolean;
  hasLoaded: boolean;
  summaryLoadingError: string;
}

const home = createSlice({
  name: 'home',
  initialState: {
    contactUsRequestReceived: 0,
    incompleteOrders: 0,
    outOfStockInventoryItems: 0,
    isLoading: false,
    hasLoaded: false,
  } as HomeState,
  reducers: {
    summaryRequested(state) {
      state.isLoading = true;
    },
    summaryRequestSucceeded(state, action: PayloadAction<Summary>) {
      state.contactUsRequestReceived = action.payload.contactUsRequestReceived;
      state.incompleteOrders = action.payload.incompleteOrders;
      state.outOfStockInventoryItems = action.payload.outOfStockInventoryItems;
      state.isLoading = false
      state.hasLoaded = true;
    },
    summaryRequestFailed(state, action: PayloadAction<string>) {
      state.isLoading = false
      state.summaryLoadingError = action.payload;
    },
  }
});

export default home;

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../root-reducer';

export const epcStateSelector = (state: RootState) => state.exerciseProgramExercises;


export const exercisesSelector = createSelector(epcStateSelector,
(epcState) => ({
  exerciseProgramExercises: epcState.exerciseProgramExercises,
  hasSubscribed: epcState.hasSubscribedToExerciseProgramExercises,
  subscriptionId: epcState.subscriptionId,
}));

export const updatingExerciseSelector = createSelector(epcStateSelector, (epcState) => ({
  updatingExerciseIds: epcState.updatingExerciseProgramExercisesIds,
  updatingErrors: epcState.updatingErrors,
  isProcessing: epcState.isProcessing,
}));

export const manageExerciseSelector = createSelector(epcStateSelector, (epcState) => ({
  manageExerciseModalIsVisible: epcState.manageModalIsVisible,
  form: epcState.manageExerciseForm,
  isProcessing: epcState.isProcessingManageModal,
}));

export const manageExerciseModalIsVisibleSelector = createSelector(epcStateSelector, (epcState) => epcState.manageModalIsVisible);
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import LoadingWithOverlay from '../../../components/LoadingWithOverlay/LoadingWithOverlay';
import styles from './order-details-modal-body.module.scss';
import { default as FormField } from '../../../models/OrderField.enum';
import ordersSlice from '../../../store/orders/orders-slice';
import { orderFormSelector } from '../../../store/orders/orders-selector';
import { OrderState } from '../../../models/Order';
import HashMap from '../../../models/HashMap';

const OrderDetailsModalBody = () => {
  const dispatch = useDispatch();
  const {
    form,
    validationErrors,
    selectedOrder,
    isProcessing,
  } = useSelector(orderFormSelector);
  
  const { updateForm, setFormDefaults } = ordersSlice.actions;
  
  useEffect(() => {
    const formDefaults = new HashMap<FormField, string>();
    formDefaults.set(FormField.internalNotes, selectedOrder?.internalNotes || '');
    formDefaults.set(FormField.publicNotes, selectedOrder?.publicNotes || '');
    formDefaults.set(FormField.orderState, selectedOrder?.orderState || '');
    formDefaults.set(FormField.orderId, selectedOrder?.orderId || '');
    dispatch(setFormDefaults(formDefaults));
  }, [])
  
  if (!selectedOrder) { return null; } // Null check but should not be null
  
  const billingAndShipping = selectedOrder.billingAndShipping;
  const billingAddress = `${billingAndShipping.billingStreetAddress||''}` +
    `${billingAndShipping.billingCity || ''}, ${billingAndShipping.billingState||''} ${billingAndShipping.billingPostalCode||''}`;
  const shippingAddress = `${billingAndShipping.shippingStreetAddress||''}` +
    `${billingAndShipping.shippingCity||''}, ${billingAndShipping.shippingState||''} ${billingAndShipping.shippingPostalCode||''}`;
  
  const handleOnChange =  (field: FormField) => (ev: React.FormEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    ev.preventDefault();
    const value = ev.currentTarget.value;
    
    dispatch(updateForm({ field, value}));
  };
  
  const handleOrderStateChange = (ev: React.FormEvent<HTMLSelectElement>) => {
    ev.preventDefault();
    dispatch(updateForm({ field: FormField.orderState, value: ev.currentTarget.value }));
  };
  
  return (
    <div id="order-form" className={classNames("pure-g", styles.formContainer)}>
      { isProcessing && <LoadingWithOverlay contained={false}/>}
        <div className="pure-u-1">
          <span className={styles.label}>Purchaser Name</span>
          <span className={styles.value}>{selectedOrder.name}</span>
        </div>
        <div className="pure-u-1">
          <span className={styles.label}>Email</span>
          <span className={styles.value}>{selectedOrder.email}</span>
        </div>
        <div className="pure-u-1">
          <span className={styles.label}>Total</span>
          <span className={styles.value}>{selectedOrder.amount}</span>
        </div>
        <div className="pure-u-1">
          <hr />
        </div>
        <div className="pure-u-1 pure-u-md-1-3">
          <span className={styles.label}>Billing Address</span>
          <span className={styles.value}>{billingAddress}</span>
        </div>
        <div className="pure-u-1 pure-u-md-1-3">
          <span className={styles.label}>Shipping Address</span>
          <span className={styles.value}>{shippingAddress}</span>
        </div>
        <div className="pure-u-1">
          <hr />
        </div>
        <div className="pure-u-1 pure-u-md-1-2">
          <span className={classNames(styles.label, styles.fullWidth)}>
            Internal Notes
          </span>
          <textarea
            value={form.getWithDefault(FormField.internalNotes, "") }
            className={styles.textArea}
            onChange={handleOnChange(FormField.internalNotes)}
          />
        </div>
        <div className="pure-u-1 pure-u-md-1-2">
          <span className={classNames(styles.label, styles.fullWidth)}>
            Public Notes
          </span>
          <textarea
            value={form.getWithDefault(FormField.publicNotes, "") }
            className={styles.textArea}
            onChange={handleOnChange(FormField.publicNotes)}
          />
        </div>
        <div className="pure-u-1">
          <div className={styles.spacer} />
        </div>
        <div className="pure-u-1">
          <span className={classNames(styles.label, styles.fullWidth)}>
            Order State
          </span>
          <select id="order-state" value={form.getWithDefault(FormField.orderState, OrderState.OrderReceived)}
            onChange={handleOrderStateChange}>
            <option value={OrderState.OrderReceived}>Order Received</option>
            <option value={OrderState.OrderProcessing}>Order Processing</option>
            <option value={OrderState.OrderShipmentSent}>Order Shipment Sent</option>
            <option value={OrderState.OrderShipmentComplete}>Order Shipment Complete</option>
          </select>
        </div>
    </div>
  );
};

export default OrderDetailsModalBody;
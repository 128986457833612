import iziToast from 'izitoast';

export const showSuccess = (message: string) => {
    iziToast.show({
      title: 'Success',
      message: message,
      color: 'green',
    });
};

export const showInfo = (message: string) => {
    iziToast.show({
      message: message,
      color: 'blue',
    });
};

export const showError = (message: string) => {
    iziToast.show({
      title: 'Error',
      message: message,
      color: 'red',
    });
};

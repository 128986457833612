import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HashMap from '../../models/HashMap';
import BlogPage from '../../models/BlogPage';
import validateTruthyValue from '../../helpers/validate-truthy-value';
import BlogPageFormField from '../../models/BlogPageFormField.enum';

type BlogPageId = string;
  
interface BlogPagesState {
  blogPageForm: HashMap<BlogPageFormField, string>;
  formIsValid: boolean;
  validationErrors: string[];
  isProcessing: boolean;
  blogPages: BlogPage[];
  hasSubscribed: boolean;
  subscriptionId?: string;
  subscriptionError: string;
  updatingBlogPageIds: BlogPageId[];
  updatingErrors: HashMap<BlogPageId, string>;
  selectedBlogPage?: BlogPage;
  blogPageModalIsVisible: boolean;
  isModalSubmitting: boolean;
  newBlogPageError: string;
}

const blogPagesSlice = createSlice({
  name: 'blogPage',
  initialState: {
    blogPageForm: new HashMap<BlogPageFormField, string>(),
    validationErrors: new Array<string>(),
    isProcessing: false,
    hasSubscribed: false,
    blogPages:[] as BlogPage[],
    updatingBlogPageIds: [] as BlogPageId[],
    updatingErrors: new HashMap<BlogPageId, string>(),
    blogPageModalIsVisible: false,
    isModalSubmitting: false,
  } as BlogPagesState,
  reducers: {
    submittingBlogPageStarted(state) {
      state.isModalSubmitting = true;
    },
    submittingBlogPageCompleted(state) {
      state.isModalSubmitting = false;
      state.newBlogPageError = '';
    },
    submittingBlogPageFailed(state, action: PayloadAction<string>) {
      state.isModalSubmitting = false;
      state.newBlogPageError = action.payload;
    },
    showBlogPageModal(state, action: PayloadAction<BlogPageId|undefined>) {
      const blogPageId = action.payload;
      const selectedBlogPage = blogPageId && state.blogPages.find(blogPage => blogPage.blogPageId === blogPageId);

      if (selectedBlogPage) {
        state.selectedBlogPage = selectedBlogPage;
      } else {
        state.selectedBlogPage = {} as BlogPage;
      }

      state.blogPageModalIsVisible = true;
    },
    hideBlogPageModal(state) {
      state.blogPageModalIsVisible = false;
      delete state.selectedBlogPage;
    },
    setFormDefaults(state, action: PayloadAction<HashMap<BlogPageFormField, string>>) {
      const fields = action.payload;
      
      fields.forEach(([field, value]) => {
        state.blogPageForm.set(field, value);
      });
    },
    updateForm(state, action: PayloadAction<{field: BlogPageFormField, value: string}>) {
      const { field, value} = action.payload;
      
      state.blogPageForm.set(field, value);
      
      const validationErrors = validateTruthyValue(state.blogPageForm.ref());

      state.validationErrors = validationErrors;
      state.formIsValid = !validationErrors.length;
    },
    clearForm(state) {
      state.blogPageForm.clear();
      state.formIsValid = false;
      state.validationErrors = new Array<BlogPageFormField>();
    },
    unsubscribedFromBlogPages(state) {
      state.hasSubscribed = false;
      delete state.subscriptionId;
    },
    subscribedToBlogPages(state, action: PayloadAction<string>) {
      state.hasSubscribed = true;
      state.subscriptionId = action.payload;
    },
    blogPagesReceived(state, action: PayloadAction<BlogPage[]>) {
      state.blogPages= action.payload;
    },
    blogPagesSubscriptionFailed(state, action: PayloadAction<string>) {
      state.hasSubscribed = true;
      state.subscriptionError = action.payload;
    },
    blogPageUpdateRequested(state, action:PayloadAction<BlogPageId>) {
      state.updatingBlogPageIds.push(action.payload);
      state.isModalSubmitting = true;
    },
    blogPageUpdateCompleted(state, action:PayloadAction<BlogPageId>) {
      const itemId = action.payload;
      state.updatingBlogPageIds = state.updatingBlogPageIds.filter(id => id !== itemId);
      state.updatingErrors.delete(itemId);
      state.isModalSubmitting = false;
    },
    blogPageUpdateFailed(state, action:PayloadAction<[BlogPageId,string]>) {
      const [itemId, errorMessage] = action.payload;

      state.updatingBlogPageIds = state.updatingBlogPageIds.filter(id => id !== itemId);
      state.updatingErrors.set(itemId, errorMessage);
      state.isModalSubmitting = false;
    },
  }
});

export default blogPagesSlice;

import React from 'react';
import classNames from 'classnames';
import styles from './event-history.module.scss';
import { compareDesc, format } from 'date-fns';
import CircleIcon from '../Icons/CircleIcon';
import ClientDetails from '../../models/ClientDetails';
import ClientEvent from '../../models/ClientEvent';
import StatRecord, { StatRecordType } from '../../models/StatRecord';
import Exercise, { ExerciseUnit } from '../../models/Exercise';

interface EventHistoryProps {
    clientDetails: ClientDetails[];
    exercises: Exercise[];
}

interface HistoryItem {
    user: string;
    eventId: string;
    recordDate: Date;
    description: string;
}

interface HistoryClientEvent extends ClientEvent {
    user: string;
}
interface HistoryStatRecord extends StatRecord {
    user: string;
}

const EventHistory = ({ clientDetails = [], exercises }: EventHistoryProps) => {
    const history = clientDetails
        .reduce((all, detail) => {
            const updated = [...all];
            updated.push(...detail.stats.map(s => ({ ...s, user: detail.email })));
            updated.push(...detail.events.map(e => ({ ...e, user: detail.email })));
            return updated;
        }, [] as (HistoryClientEvent|HistoryStatRecord)[])
        .map(item => {
            const historyItem = {
                user: item.user,
            } as HistoryItem;

            if (item.hasOwnProperty('eventId')) {
                const eventItem = item as ClientEvent;
                historyItem.eventId = eventItem.eventId;
                historyItem.recordDate = new Date(eventItem.date);
                historyItem.description = eventItem.description;
            } else {
                const statItem = item as StatRecord;
                historyItem.eventId = statItem.statRecordId;
                historyItem.recordDate = new Date(statItem.date);
                
                if (statItem.statRecordType === StatRecordType.SETS) {
                    const exerciseName = exercises.find(exercise => exercise.exerciseId === statItem.exerciseId)?.exerciseName || '';
                    let description = `Completed Set of ${exerciseName} for ${statItem.value}`;

                    if (statItem.unit !== ExerciseUnit.none) {
                        description = `${description} ${statItem.unit}`;
                    }

                    historyItem.description = description;
                } else if (statItem.statRecordType === StatRecordType.REPS) {
                    const exerciseName = exercises.find(exercise => exercise.exerciseId === statItem.exerciseId)?.exerciseName || '';
                    let description = `Completed ${exerciseName} for ${statItem.value} reps`;

                    historyItem.description = description;
                } else {
                    historyItem.description = '';
                }
            }

            return historyItem
        }).sort((a, b) => compareDesc(a.recordDate, b.recordDate));;
    
    if (!history.length) {
        return <p className={styles.noDetails}>No events to display.</p>
    }
    
    return(
    <dl className={classNames(styles.datalist)}>
        {history.map((log) => (<React.Fragment key={log.eventId}>
            <dt>
                <span className={styles.logIcon}>
                    <CircleIcon />
                </span>
                <span className={styles.date}>{format(log.recordDate, 'dd MMM yyyy')} at {format(log.recordDate, 'h:mm a')}</span>
            </dt>
            <dd><strong>{log.user}: </strong></dd>
            <dd>{log.description}</dd>
        </React.Fragment>))}
    </dl>);
};

export default EventHistory;
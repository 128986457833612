import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../root-reducer';

export const ordersStateSelector = (state: RootState) => state.orders;

export const selectedOrderSelector = createSelector(ordersStateSelector, (ordersState) => ordersState.selectedOrder);

export const orderDetailsModalIsVisibleSelector = createSelector(ordersStateSelector,
  (ordersState) => ordersState.orderDetailsModalIsVisible);

export const orderFormSelector = createSelector(ordersStateSelector,
  (ordersState) => ({
    form: ordersState.orderForm,
    formIsValid: ordersState.orderFormIsValid,
    validationErrors: ordersState.validationErrors,
    selectedOrder: ordersState.selectedOrder,
    isProcessing: ordersState.isProcessing,
  }));
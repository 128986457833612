import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/LargeModal/LargeModal';
import styles from './preferences-modal.module.scss';
import preferencesSlice from '../../store/preferences/preferences-slice';
import PreferencesModalBody from './components/PreferencesModalBody';
import { modalFormPreferencesSelector } from '../../store/preferences/preferences-selector';
import { updatePreferences } from '../../store/preferences/preferences-thunks';
import { userIdSelector } from '../../store/auth/auth-selectors';
import useSession from '../../custom_hooks/useSession';

const PreferencesModal = () => {
  const dispatch = useDispatch();
  const { hidePreferencesModal } = preferencesSlice.actions;
  const modalFormPreferences = useSelector(modalFormPreferencesSelector);
  const session = useSession();
  
  const handleClose = () => {
    dispatch(hidePreferencesModal());
  };
  
  const handleSubmit = () => {
    dispatch(updatePreferences(session, modalFormPreferences));
  };

  const renderHeader = () => <h2>Preferences</h2>;

  const renderBody = () => (<PreferencesModalBody modalFormPreferences={modalFormPreferences} onClose={handleClose} />);

  const renderActions = () => (
    <div className={styles.actionsContainer}>
      <button type="button"
        className={classNames("pure-button", styles.cancelButton)} 
        onClick={handleClose}>
        Close
      </button>
      
      <button type="button"
        className={classNames("pure-button", styles.submitButton)} 
        onClick={handleSubmit}>
        Save 
      </button>
    </div>
  );
  
  return (
    <Modal
      onClose={handleClose}
      renderHeader={renderHeader}
      renderBody={renderBody}
      renderActions={renderActions}
    />
  );
};

export default PreferencesModal;

import AddOn from '../../models/AddOn';
import { PreferenceIdKey } from '../../models/Preference';

const InventoryAddon = {
    key: PreferenceIdKey.INVENTORY,
    description: 'Add and Manage Inventory',
    headerText: 'Inventory',
    link: '/inventory',
    summaryCardIds: [PreferenceIdKey.INVENTORY_OUT_OF_STOCK],
} as AddOn;

export default InventoryAddon;
export enum ClientState {
    active = 'active',
    inactive = 'inactive',
}

interface Client {
    clientId: string;
    email: string;
    clientName: string;
    clientState: ClientState;
}

export default Client;
import React from 'react';
import classNames from 'classnames';
import styles from './home-summary-card.module.scss';


export enum CardColor {
    primary,
    secondary,
    accent,
}

interface HomeSummaryCardProps {
    header: string;
    footer: string;
    cardValue: string | number;
    color: CardColor;
    Icon?: React.ReactNode; 
}

const HomeSummaryCard = ({ header, footer, cardValue, color, Icon }: HomeSummaryCardProps) => {
    
    const isText = typeof cardValue === 'string';
    
    return (
    <div className={classNames(styles.container, {
        [styles.primary]: (color === CardColor.primary),
        [styles.secondary]: (color === CardColor.secondary),
        [styles.accent]: (color === CardColor.accent),
    })}>
        <section className={classNames(styles.left)}>
            <header className={styles.header}>
                {header}
            </header>
            <div className={classNames(styles.valueContainer, { [styles.isText]: isText })}>
                {cardValue}
            </div>
            <footer className={styles.footer}>
                {footer}
            </footer>
        </section>
        <section className={styles.right}>
            { Icon ? Icon : "" }
        </section>
    </div>
    );
};

export default HomeSummaryCard;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import NewInventoryFormField from '../../models/NewInventoryFormField.enum';
import HashMap from '../../models/HashMap';
import validateTruthyValue from '../../helpers/validate-truthy-value';
import InventoryItem from '../../models/Item';

type ItemId = string;
  
interface InventoryState {
  newInventoryModalIsVisible: boolean;
  newInventoryForm: HashMap<NewInventoryFormField, string>;
  newInventoryFormIsValid: boolean;
  validationErrors: NewInventoryFormField[];
  isSubmittingNewInventory: boolean;
  newInventoryItemError: string;
  items: InventoryItem[];
  hasSubscribedToInventoryItems: boolean;
  subscriptionId?: string;
  subscriptionError: string;
  updatingItemIds: ItemId[];
  updatingErrors: HashMap<ItemId, string>;
}

const inventorySlice = createSlice({
  name: 'inventory',
  initialState: {
    newInventoryModalIsVisible: false,
    newInventoryForm: new HashMap<NewInventoryFormField, string>(),
    validationErrors: new Array<NewInventoryFormField>(),
    isSubmittingNewInventory: false,
    hasSubscribedToInventoryItems: false,
    items:[] as InventoryItem[],
    updatingItemIds: [] as ItemId[],
    updatingErrors: new HashMap<ItemId, string>(),
  } as InventoryState,
  reducers: {
    showNewInventoryModal(state) {
      state.newInventoryModalIsVisible = true;
    },
    hideNewInventoryModal(state) {
      state.newInventoryModalIsVisible = false;
    },
    updateForm(state, action: PayloadAction<{field: NewInventoryFormField, value: string}>) {
      const { field, value} = action.payload;
      
      state.newInventoryForm.set(field, value);
      
      const validationErrors = validateTruthyValue(state.newInventoryForm.ref());

      state.validationErrors = validationErrors;
      state.newInventoryFormIsValid = !validationErrors.length;
    },
    clearForm(state) {
      state.newInventoryForm.clear();
      state.newInventoryFormIsValid = false;
      state.validationErrors = new Array<NewInventoryFormField>();
    },
    submittingInventoryStarted(state) {
      state.isSubmittingNewInventory = true;
    },
    submittingInventoryCompleted(state) {
      state.isSubmittingNewInventory = false;
    },
    submittingInventoryFailed(state, action: PayloadAction<string>) {
      state.isSubmittingNewInventory = false;
      state.newInventoryItemError = action.payload;
    },
    unsubscribedToInventoryItems(state) {
      state.hasSubscribedToInventoryItems = false;
      delete state.subscriptionId;
    },
    subscribedToInventoryItems(state, action: PayloadAction<string>) {
      state.hasSubscribedToInventoryItems = true;
      state.subscriptionId = action.payload;
    },
    inventoryItemsReceived(state, action: PayloadAction<InventoryItem[]>) {
      state.items = action.payload;
    },
    inventoryItemsSubscriptionFailed(state, action: PayloadAction<string>) {
      state.hasSubscribedToInventoryItems = true;
      state.subscriptionError = action.payload;
    },
    itemUpdateRequested(state, action:PayloadAction<ItemId>) {
      state.updatingItemIds.push(action.payload);
    },
    itemUpdateCompleted(state, action:PayloadAction<ItemId>) {
      const itemId = action.payload;
      state.updatingItemIds = state.updatingItemIds.filter(id => id !== itemId);
      state.updatingErrors.delete(itemId);
    },
    itemUpdateFailed(state, action:PayloadAction<[ItemId,string]>) {
      const [itemId, errorMessage] = action.payload;

      state.updatingItemIds = state.updatingItemIds.filter(id => id !== itemId);
      state.updatingErrors.set(itemId, errorMessage);
    },
  }
});

export default inventorySlice;

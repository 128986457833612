export interface OrderBillingAndShippingDetails {
  billingStreetAddress: string;
  billingCity: string;
  billingState: string;
  billingPostalCode: string;
  shippingStreetAddress: string;
  shippingCity: string;
  shippingState: string;
  shippingPostalCode: string;
}

export enum OrderState {
  OrderReceived = 'OrderReceived',
  OrderProcessing = 'OrderProcessing',
  OrderShipmentSent = 'OrderShipmentSent',
  OrderShipmentComplete = 'OrderShipmentComplete',
}

export const toOrderState = (str: string): OrderState => {
  switch(str) {
    case OrderState.OrderReceived:
      return OrderState.OrderReceived;
    case OrderState.OrderProcessing:
      return OrderState.OrderProcessing;
    case OrderState.OrderShipmentSent:
      return OrderState.OrderShipmentSent;
    case OrderState.OrderShipmentComplete:
      return OrderState.OrderShipmentComplete;
    default:
      return OrderState.OrderReceived;
  }
};

export const toOrderBillingAndShippingDetails = (jsonStr: string): OrderBillingAndShippingDetails => {
  const jsonObj = JSON.parse(jsonStr);
  
  return {
    billingStreetAddress: jsonObj.billingStreetAddress,
    billingCity: jsonObj.billingCity,
    billingState: jsonObj.billingState,
    billingPostalCode: jsonObj.billingPostalCode,
    shippingStreetAddress: jsonObj.shippingStreetAddress,
    shippingCity: jsonObj.shippingCity,
    shippingState: jsonObj.shippingState,
    shippingPostalCode: jsonObj.shippingPostalCode,
  } as OrderBillingAndShippingDetails;
};

export interface Order {
  orderId: string;
  created: number;
  billingAndShipping: OrderBillingAndShippingDetails;
  amount: number;
  email: string;
  name: string;
  orderState: OrderState;
  publicNotes: string;
  internalNotes: string;
  updated: number;
}

export default Order;
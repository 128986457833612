import React from 'react';
import { useSelector } from 'react-redux';

import NewInventoryModal from '../../features/NewInventoryModal/NewInventoryModal';
import { newInventoryModalIsVisibleSelector } from '../../store/inventory/inventory-selector';

import OrderDetailsModal from '../../features/OrderDetailsModal/OrderDetailsModal';
import { orderDetailsModalIsVisibleSelector } from '../../store/orders/orders-selector';

import ContactUsDetailsModal from '../../features/ContactUsDetailsModal/ContactUsDetailsModal';
import { detailsModalIsVisibleSelector as contactUsDetailsModalIsVisibleSelector } from '../../store/contactUsRequests/contact-us-requests-selector';

import PreferencesModal from '../../features/PreferencesModal/PreferencesModal';
import { preferencesModalIsVisibleSelector } from '../../store/preferences/preferences-selector';

import BlogPageModal from '../../features/BlogPageModal/BlogPageModal';
import { blogPageModalIsVisibleSelector } from '../../store/blogPages/blog-pages-selector';

import UserAppModal from '../../features/UserAppModal/UserAppModal';
import { userAppModalIsVisibleSelector } from '../../store/userApps/user-apps-selectors';

import ExerciseProgramModal from '../../features/ExerciseProgramModal/ExerciseProgramModal';
import { exerciseProgramModalIsVisibleSelector } from '../../store/exercisePrograms/exercise-programs-selectors';

import NewExerciseModal from '../../features/NewExerciseModal/NewExerciseModal';
import { newExerciseModalIsVisibleSelector } from '../../store/exercises/exercises-selector';

import EditExerciseModal from '../../features/EditExerciseModal/EditExerciseModal';
import { editExerciseModalIsVisibleSelector } from '../../store/exercises/exercises-selector';

import ClientModal from '../../features/ClientModal/ClientModal';
import { clientModalIsVisibleSelector } from '../../store/clients/clients-selector';

import ManageExerciseModal from '../../features/ManageExerciseModal/ManageExerciseModal';
import { manageExerciseModalIsVisibleSelector } from '../../store/exerciseProgramExercises/exercise-program-exercises-selector';

const ModalCollection = () => {
  const newInventoryModalIsVisible = useSelector(newInventoryModalIsVisibleSelector);
  const orderDetailsModalIsVisible = useSelector(orderDetailsModalIsVisibleSelector);
  const contactUsDetailsModalIsVisible = useSelector(contactUsDetailsModalIsVisibleSelector);
  const preferencesModalIsVisible = useSelector(preferencesModalIsVisibleSelector);
  const blogPageModalIsVisible = useSelector(blogPageModalIsVisibleSelector);
  const userAppModalIsVisible = useSelector(userAppModalIsVisibleSelector);
  const exerciseProgramModalIsVisible = useSelector(exerciseProgramModalIsVisibleSelector);
  const newExerciseModalIsVisible = useSelector(newExerciseModalIsVisibleSelector);
  const editExerciseModalIsVisible = useSelector(editExerciseModalIsVisibleSelector);
  const clientModalIsVisible = useSelector(clientModalIsVisibleSelector);
  const manageExerciseModalIsVisible = useSelector(manageExerciseModalIsVisibleSelector);

  return (<>
    { newInventoryModalIsVisible && <NewInventoryModal /> }
    { orderDetailsModalIsVisible && <OrderDetailsModal /> }
    { contactUsDetailsModalIsVisible && <ContactUsDetailsModal /> }
    { preferencesModalIsVisible && <PreferencesModal /> }
    { blogPageModalIsVisible && <BlogPageModal />}
    { userAppModalIsVisible && <UserAppModal />}
    { exerciseProgramModalIsVisible && <ExerciseProgramModal />}
    { newExerciseModalIsVisible && <NewExerciseModal />}
    { editExerciseModalIsVisible && <EditExerciseModal />}
    { clientModalIsVisible && <ClientModal /> }
    { manageExerciseModalIsVisible && <ManageExerciseModal />}
  </>);
};

export default ModalCollection;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HashMap from '../../models/HashMap';
import validateTruthyValue from '../../helpers/validate-truthy-value';
import Client from '../../models/Client';

type ExerciseProgramClientsId = string;
  
interface ExerciseProgramClientsState {
  exerciseProgramClients: Client[];
  hasSubscribedToExerciseProgramClients: boolean;
  subscriptionId?: string;
  subscriptionError: string;
  updatingExerciseProgramClientsIds: ExerciseProgramClientsId[];
  updatingErrors: HashMap<ExerciseProgramClientsId, string>;
  isProcessing: boolean;
  submissionError: string;
}

const exerciseProgramClientsSlice = createSlice({
  name: 'exerciseProgramClients',
  initialState: {
    exerciseProgramClients: [] as Client[],
    updatingExerciseProgramClientsIds: [] as ExerciseProgramClientsId[],
    updatingErrors: new HashMap<ExerciseProgramClientsId, string>(),
    isProcessing: false,
  } as ExerciseProgramClientsState,
  reducers: {
    submittingExerciseProgramClientsStarted(state) {
      state.isProcessing = true;
    },
    submittingExerciseProgramClientsCompleted(state) {
      state.isProcessing = false;
    },
    submittingExerciseProgramClientsFailed(state, action: PayloadAction<string>) {
      state.isProcessing = false;
      state.submissionError = action.payload;
    },
    unsubscribedToExerciseProgramClients(state) {
      state.hasSubscribedToExerciseProgramClients = false;
      delete state.subscriptionId;
    },
    subscribedToExerciseProgramClients(state, action: PayloadAction<string>) {
      state.hasSubscribedToExerciseProgramClients = true;
      state.subscriptionId = action.payload;
    },
    exerciseProgramClientsReceived(state, action: PayloadAction<Client[]>) {
      state.exerciseProgramClients = action.payload;
    },
    exerciseProgramClientsSubscriptionFailed(state, action: PayloadAction<string>) {
      state.hasSubscribedToExerciseProgramClients = true;
      state.subscriptionError = action.payload;
    },
    exerciseProgramClientUpdateRequested(state, action:PayloadAction<ExerciseProgramClientsId>) {
      state.updatingExerciseProgramClientsIds.push(action.payload);
    },
    exerciseProgramClientUpdateCompleted(state, action:PayloadAction<ExerciseProgramClientsId>) {
      const execiseId = action.payload;
      state.updatingExerciseProgramClientsIds = state.updatingExerciseProgramClientsIds.filter(id => id !== execiseId);
      state.updatingErrors.delete(execiseId);
    },
    exerciseProgramClientUpdateFailed(state, action:PayloadAction<[ExerciseProgramClientsId,string]>) {
      const [exerciseProgramClientsId, errorMessage] = action.payload;

      state.updatingExerciseProgramClientsIds = state.updatingExerciseProgramClientsIds.filter(id => id !== exerciseProgramClientsId);
      state.updatingErrors.set(exerciseProgramClientsId, errorMessage);
    },
  }
});

export default exerciseProgramClientsSlice;

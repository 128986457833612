import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './user-app-modal-body.module.scss';
import UserApp from '../../../models/UserApp';
import LoadingWithOverlay from '../../../components/LoadingWithOverlay/LoadingWithOverlay';
import { default as FormField } from '../../../models/UserAppFormField.enum';
import HashMap from '../../../models/HashMap';
import userAppSlice from '../../../store/userApps/user-apps-slice';
import { userAppFormSelector } from '../../../store/userApps/user-apps-selectors';

interface UserAppModalBodyProps {
  onClose: () => void;
}

const UserAppModalBody = ({ onClose }: UserAppModalBodyProps) => {
  const dispatch = useDispatch();
  const { updateForm, setFormDefaults } = userAppSlice.actions;
  const {
    form,
    validationErrors,
    userAppForModal,
    isModalSubmitting,
  } = useSelector(userAppFormSelector);

  useEffect(() => {
    const formDefaults = new HashMap<FormField, string>();

    if (userAppForModal) {
      formDefaults.set(FormField.userAppName, userAppForModal.userAppName)
      formDefaults.set(FormField.userAppDescription, userAppForModal.userAppDescription)
    }

    dispatch(setFormDefaults(formDefaults));
  }, [])

  
  const handleOnChange =  (field: FormField) => (ev: React.FormEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    ev.preventDefault();
    const value = ev.currentTarget.value;
    
    dispatch(updateForm({ field, value}));
  };
  
  const closeModalForNavigation = () => {
    onClose();
  }
  
  return (
    <div id="userApp-form" className={classNames("pure-g pure-form", styles.formContainer)}>
      { isModalSubmitting && <LoadingWithOverlay contained={false}/>}
        <div className="pure-u-1">
          <span className={styles.label}>Name</span>
          <input
            type="text"
            className={classnames(styles.input, 'pure-input-1')}
            onChange={handleOnChange(FormField.userAppName)}
            value={form.getWithDefault(FormField.userAppName, '')}
          />
        </div>
        <div className="pure-u-1">
          <br />
        </div>
        <div className="pure-u-1">
          <span className={styles.label}>Description</span>
          <textarea 
            className={classnames(styles.textarea, 'pure-input-1')}
            onChange={handleOnChange(FormField.userAppDescription)}
            value={form.getWithDefault(FormField.userAppDescription, '')}
          />
        </div>
    </div>
  );
};

export default UserAppModalBody;
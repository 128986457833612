import { createSlice , PayloadAction } from '@reduxjs/toolkit';
import User from '../../models/User';

interface AuthState {
  isSignedIn: boolean;
  isLoading: boolean;
  watchingRequested: boolean;
  isWatching: boolean;
  userSignInError: string;
  watchError: string;
  user: User;
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isSignedIn: false,
    isLoading: false,
    isWatching: false,
    watchingRequested: false,
  } as AuthState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    signInHasFailed(state, action: PayloadAction<string>) {
      state.userSignInError = action.payload;
      state.isSignedIn = false;
      delete state.user;
    },
    signInSucceeded(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.isSignedIn = true;
    },
    watchingRequested(state) {
      state.watchingRequested = true;
    },
    watchingStarted(state) {
      state.isWatching = true;
    },
    errorWatching(state, action: PayloadAction<string>) {
      state.watchError = action.payload;
    },
    signOutSucceeded(state) {
      state.isSignedIn = false;
      state.isLoading = false;
    },
  }
});

export default authSlice;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import styles from './clients-container.module.scss';
import Loading from '../Loading/Loading';
import ClientsTableRow from './ClientsTableRow';
import Client from '../../models/Client';
import PlusIcon from '../Icons/PlusIcon';
import clientsSlice from '../../store/clients/clients-slice';

interface ClientsContainerProps {
  clients: Client[];
  isLoading: boolean;
}

const ClientsContainer = ({ clients, isLoading }: ClientsContainerProps) => {
  const dispatch = useDispatch();
  const [filterVal, setFilterVal] = useState('');
  const [filteredClients, setFilteredClients] = useState([] as Client[]);
  const {
    showClientModal,
  } = clientsSlice.actions;
  
  useEffect(() => {
    setFilteredClients(clients);
  }, [clients]);

  const handleFilterChange = (ev: React.FormEvent<HTMLInputElement>) => {
    const val = ev.currentTarget.value;
    const valUpperCase = val.toUpperCase();

    if (!val) {
      setFilteredClients(clients);
    } else {
      setFilteredClients(clients.filter(client =>
        client.clientName.toUpperCase().indexOf(valUpperCase) > -1
        || client.email.toLocaleUpperCase().indexOf(valUpperCase) > -1
        || client.clientState.toLocaleUpperCase().indexOf(valUpperCase) > -1
      ));
    }
    
    setFilterVal(val);
  };

  const handleNewClientClick = () => {
    dispatch(showClientModal());
  };

  const noClients = !isLoading && !(filteredClients && filteredClients.length);

  return (<>
    <section className={classNames("pure-u-1", styles.headerSection)}>
      <h1>Clients</h1>
      <button className={classNames("pure-button", styles.newClientButton)}
        onClick={handleNewClientClick}
      >
        <span className={styles.iconContainer}>
          <PlusIcon />
        </span>
        Add Client
      </button>
    </section>
    <section className={classNames("pure-form", styles.clientsFilterContainer)}>
      <input type="text" placeholder="Filter Clients..."
             className={classNames("pure-u-1 pure-u-md-1-2 pure-u-lg-1-4 pure-input-rounded", styles.filterPurchases)}
             value={filterVal}
             onChange={handleFilterChange}
      />
    </section>
    
    <section className={styles.tableContainer}>
      <table className={classNames("pure-table", styles.clientsTable)}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Status</th>
            <th className={styles.center}>Edit</th>
            <th className={styles.center}>Detials</th>
          </tr>
        </thead>
        <tbody>
          { isLoading && (
            <tr className={styles.infoRow}>
              <td colSpan={6}><Loading></Loading></td>
            </tr>
          )}
          {  noClients && (
            <tr className={styles.infoRow}>
                <td colSpan={6}><strong>
                  { filterVal
                    ? "No client matches your filter"
                    : "No clients to display"
                  }
                  
                </strong></td>
            </tr>
          )}

          {!noClients && filteredClients
            .map((client, i) => 
              (<ClientsTableRow client={client} index={i} key={client.clientId} />)
            )
          }
        </tbody>
      </table>
    </section>
  </>);
};

export default ClientsContainer;

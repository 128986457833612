import api from '../../api';
import { AppThunk } from '../index';
import HashMap from '../../models/HashMap';
import UserAppFormField from '../../models/UserAppFormField.enum';
import userAppSlice from './user-apps-slice';


export const postNewUserApp = (
    userId: string,
    form: HashMap<UserAppFormField, string>,
): AppThunk => async(dispatch) => {
  const {
    submittingUserAppStarted,
    submittingUserAppCompleted,
    submittingUserAppFailed,
    hideUserAppModal,
    clearForm,
  } = userAppSlice.actions;

  dispatch(submittingUserAppStarted());
  
  try {

    await api.userApps.postNewUserApp(userId, form);

    dispatch(submittingUserAppCompleted());
    dispatch(clearForm());
    dispatch(hideUserAppModal())
  } catch(e) {
    dispatch(submittingUserAppFailed(e.message));
  }
};


export const subscribeToUserApps = (userId: string): AppThunk => async(dispatch) => {
  const {
    subscribedToUserApps,
    userAppsReceived,
    userAppsSubscriptionFailed,
  } = userAppSlice.actions;

  try {
    const subscriptionId = await api.userApps.subscribeToUserApps(userId, (userApps) => {
      dispatch(userAppsReceived(userApps));
    });
    dispatch(subscribedToUserApps(subscriptionId));
  } catch(e) {
    dispatch(userAppsSubscriptionFailed(e.message));
  }
}
      
export const unsubscribeToUserApps = (subscriptionId: string): AppThunk => async(dispatch) => {
  const {
    unsubscribedFromUserApps,
  } = userAppSlice.actions;

  api.userApps.unsubscribeFromUserApps(subscriptionId);
  
  dispatch(unsubscribedFromUserApps());
}

/**
export const updateBlogPage = (userId: string, form: HashMap<BlogPageFormField, string>): AppThunk => async(dispatch) => {
  const {
    blogPageUpdateRequested,
    blogPageUpdateCompleted,
    blogPageUpdateFailed,
  } = blogPagesSlice.actions;
  
  const orderId = form.getWithDefault(BlogPageFormField.blogPageId, '');

  dispatch(blogPageUpdateRequested(orderId));
  
  try {
    await api.blogPages.updateBlogPage(userId, form);

    dispatch(blogPageUpdateCompleted(orderId));
  } catch(e) {
    dispatch(blogPageUpdateFailed([orderId, e.message]));
  }
};

**/
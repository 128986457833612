import React, { ReactEventHandler } from 'react';
import { useDispatch } from 'react-redux';
import Exercise from '../../models/Exercise';
import exercisesSlice from '../../store/exercises/exercises-slice';
import styles from './exercises-container.module.scss';

interface ExercisesTableRowProps {
  exercise: Exercise;
  index: number;
}

const ExercisesTableRow = ({
  exercise,
  index,
}: ExercisesTableRowProps) => {
  const dispatch = useDispatch();
  const {
    showEditExerciseModal,
  } = exercisesSlice.actions;

  const handleClick = () => {
    dispatch(showEditExerciseModal(exercise.exerciseId));
  };
  
  const handleThumbnailClick = (ev: React.MouseEvent<HTMLAnchorElement | HTMLTableCellElement>) => {
    ev.stopPropagation();
    return false;
  };

  const thumbnail = exercise.mediaSources.find((src) => src.isThumbnail);

  return (
    <tr className={index % 2 === 0 ? '' : 'pure-table-odd'} onClick={handleClick}>
      <td className={styles.tdExerciseName}>{exercise.exerciseName}</td>
      <td className={styles.tdEmail}>{exercise.category}</td>
      <td className={styles.tdContent}>
        {exercise.description}
      </td>
      <td colSpan={2} onClick={handleThumbnailClick}>
        <a href={thumbnail?.mediaSourceUrl} target="_blank" onClick={handleThumbnailClick}>
          {thumbnail?.mediaSourceFileName}
        </a>
      </td>
    </tr>
  );
};

export default ExercisesTableRow;

import { v4 as createUUID } from 'uuid';
import SessionData from '../models/SessionData';
import { getAppDoc, getUserRootDoc } from './Helpers';
import ExerciseProgramExercise from '../models/ExerciseProgramExercise';

export const CLIENTS_PATH = 'exercises';

type SubscriptionId = string;

type exercisesUpdatedHandler = (exercises: ExerciseProgramExercise[]) => void;

const SUBSCRIPTION_CACHE: Map<string, () => void> = new Map();

const exerciseProgramExercisesAPI = {
  subscribeToExercises: async (session: SessionData, exercisesUpdated: exercisesUpdatedHandler) => {
    const appDoc = getAppDoc(session);
    const exerciseCollection = appDoc.collection(CLIENTS_PATH);

    const unsubscribe = exerciseCollection.onSnapshot((snapshot) => {
      let storeExercises: ExerciseProgramExercise[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        const exercise: ExerciseProgramExercise = {
          exerciseId: doc.id as string,
          splitDay: data.splitDay,
          reps: data.reps,
          sets: data.sets,
          order: data.order,
        };
        storeExercises = [...storeExercises, exercise];
      });
      exercisesUpdated(storeExercises);
    });

    const subscriptionUUID = createUUID();

    SUBSCRIPTION_CACHE.set(subscriptionUUID, unsubscribe);

    return subscriptionUUID;
  },

  unsubscribeFromExercise: (subscriptionId: SubscriptionId) => {
    if (SUBSCRIPTION_CACHE.has(subscriptionId)) {
      const unsubscribe = SUBSCRIPTION_CACHE.get(subscriptionId);
      unsubscribe && unsubscribe();
      SUBSCRIPTION_CACHE.delete(subscriptionId);
    }
  },

  addExercise: async (session: SessionData, exercise: ExerciseProgramExercise) => {
    const appDoc = getAppDoc(session);
    const exerciseCollection = appDoc.collection(CLIENTS_PATH);

    return exerciseCollection.doc(exercise.exerciseId).set({
      exerciseId: exercise.exerciseId,
      splitDay: exercise.splitDay,
      order: exercise.order,
      reps: exercise.reps,
      sets: exercise.sets,
    });
  },

  removeExercise: async (session: SessionData, exercise: ExerciseProgramExercise) => {
    const appDoc = getAppDoc(session);
    const exerciseCollection = appDoc.collection(CLIENTS_PATH);
    const exerciseExerciseDoc = exerciseCollection.doc(exercise.exerciseId);

    return exerciseExerciseDoc.delete();
  },

  updateExercise: async (session: SessionData, exercise: ExerciseProgramExercise) => {
    const appDoc = getAppDoc(session);
    const exerciseCollection = appDoc.collection(CLIENTS_PATH);

    return exerciseCollection.doc(exercise.exerciseId).set({
      exerciseId: exercise.exerciseId,
      splitDay: exercise.splitDay,
      order: exercise.order,
      reps: exercise.reps,
      sets: exercise.sets,
    });
  },

};

export default exerciseProgramExercisesAPI;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './clients.module.scss';
import ClientsContainer from '../../components/ClientsContainer/ClientsContainer';
import { subscribeToClients, unsubscribeToClients } from '../../store/clients/clients-thunks';
import { clientsStateSelector } from '../../store/clients/clients-selector';
import useSession from '../../custom_hooks/useSession';


const Clients = () => {
  const dispatch = useDispatch();
  const {
    clients,
    hasSubscribed,
    subscriptionId,
  } = useSelector(clientsStateSelector);
  const session = useSession();
  
  useEffect(() => {
    if (!hasSubscribed) {
      if (session.userId) {
        dispatch(subscribeToClients(session));
      } else {
        return () => {
          if (subscriptionId) {
            dispatch(unsubscribeToClients(subscriptionId));
          }
        }
      }
    }
  }, [session, hasSubscribed]); 
  
  useEffect(() => {
    if (subscriptionId) {
      dispatch(unsubscribeToClients(subscriptionId));
    }
  }, [session.selectedExerciseProgramId]);

  return (
    <div className={classNames("pure-g", styles.purchases)}>
    	<div className={classNames("pure-u-1")}>
        <div className={styles.cardContainer}>
          { <ClientsContainer clients={clients} isLoading={!hasSubscribed} /> }
        </div>
    	</div>
    </div>
  );
};

export default Clients;


import firebase from 'firebase';
import SessionData from '../models/SessionData';
import MediaSource from '../models/MediaSource';

type StorageItemURL = string;

const storageApi = {
  
  createStorageItem: async(session: SessionData, path: string, file: File, mediaSource: MediaSource) => {
    const storageRef = firebase.storage().ref();
    const storagePath = `${session.userId}/${path}/${mediaSource.mediaSourceId}/${mediaSource.mediaSourceFileName}`;
    const newItemRef = storageRef.child(storagePath);

    const url = await newItemRef.put(file).then((snapshot) => snapshot.ref.getDownloadURL());
    
    return {
      ...mediaSource,
      mediaSourceUrl: url as string,
      mediaSourceRemotePath: storagePath,
    } as MediaSource;
  },
  deleteStorageItem: async(path: string) => {
    const storageRef = firebase.storage().ref();
    const desertRef = storageRef.child(path);
    return desertRef.delete();
  }
};

export default storageApi;
// https://www.digitalocean.com/community/tutorials/how-to-use-font-awesome-5-with-react

// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
// import { faListAlt, faFireAlt, faChartLine, faCommentDots, faCaretRight, faArrowLeft, faHome,
//     faInfoCircle, faCircle as faCircleSolid, faFire, faRibbon, faSignOutAlt, faBurn,
//     faRunning,
// } from '@fortawesome/free-solid-svg-icons';

// import { faCircle, faCheckCircle, faCalendarCheck } from '@fortawesome/free-regular-svg-icons';

import { faUsers, faDumbbell, faMountain, faEdit, faBinoculars, faCircle } from '@fortawesome/free-solid-svg-icons';

library.add(
    faUsers,
    faDumbbell,
    faMountain,
    faEdit,
    faBinoculars,
    faCircle,
);
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../root-reducer';

export const blogPagesStateSelector = (state: RootState) => state.blogPages;

export const selectedBlogPageSelector = createSelector(blogPagesStateSelector, (state) => state.selectedBlogPage);

export const blogPageModalIsVisibleSelector = createSelector(blogPagesStateSelector, (state) => state.blogPageModalIsVisible);

export const blogPageFormSelector = createSelector(blogPagesStateSelector,
  (state) => ({
    form: state.blogPageForm,
    formIsValid: state.formIsValid,
    validationErrors: state.validationErrors,
    selectedBlogPage: state.selectedBlogPage,
    isModalSubmitting: state.isModalSubmitting,
  }));
import ClientDetails from "../../models/ClientDetails";
import ClientEvent, { ClientEventType, generateEventCategory } from "../../models/ClientEvent";
import HashMap from '../../models/HashMap';
import StatRecord, { StatRecordType } from "../../models/StatRecord";

export const usersSignedInToday = (details: ClientDetails[]) => {
    const today = new Date();
    const categoryKey = generateEventCategory(today);
    
    return details
        .filter((detail) => detail.events
            .some((event) => event.eventCategory === categoryKey && event.eventType === ClientEventType.SIGN_IN))
        .length;
}; 

export const mostPopularExercise = (details: ClientDetails[]) => {
    const [exerciseId] = details
        .reduce((exerciseIdsCount, detail) => {
            detail.stats
            .filter(stat => stat.statRecordType === StatRecordType.SETS)
            .forEach(stat => {
                const current = exerciseIdsCount.getWithDefault(stat.exerciseId, 0);
                exerciseIdsCount.set(stat.exerciseId, current + 1);
            });
            return exerciseIdsCount;
        }, new HashMap<string, number>())
        .reduce((mostPop, [exerciseId, count]) => {
            const [_, currentMax] = mostPop;
            
            if (count > currentMax) {
                return [exerciseId, count];
            }
            
            return mostPop;
        }, ["", 0] as [string, number]);
    
    return exerciseId;
};

export const totalSets = (details: ClientDetails[]) => {
    return details
        .reduce((total, detail) => {
            detail.stats
            .filter(stat => stat.statRecordType === StatRecordType.SETS)
            .forEach(stat => {
                total += 1;
            });
            return total;
        }, 0)
};
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../root-reducer';

export const authStateSelector = (state: RootState) => state.auth;

export const isSignedInSelector = createSelector(
  authStateSelector,
  (authState) => authState.isSignedIn
);

export const watchingStateSelector = createSelector(
  authStateSelector,
  ({ isWatching, watchingRequested, isSignedIn }) => ({
    isWatching, watchingRequested, isSignedIn,
  }),
);

export const userIdSelector = createSelector(
  authStateSelector,
  ({ user }) => {
    return user && user.uid;
  },
);

export const userSelector = createSelector(
  authStateSelector,
  ({ user }) => {
    return user;
  }
);

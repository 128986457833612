import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import Modal from '../../components/Modal/Modal';
import NewInventoryModalBody from './components/NewInventoryModalBody';
import inventorySlice from '../../store/inventory/inventory-slice';
import { newInventoryFormSelector } from '../../store/inventory/inventory-selector';
import { postNewItem } from '../../store/inventory/inventory-thunks';
import styles from './new-inventory-modal.module.scss';
import useSession from '../../custom_hooks/useSession';

const NewInventoryModal = () => {
  const dispatch = useDispatch();
  const [imageFile, updateImageFile] = useState<File | null>(null);
  const newInventoryFormState = useSelector(newInventoryFormSelector);
  const session = useSession();
  
  
  const { hideNewInventoryModal, clearForm } = inventorySlice.actions;
  
  const renderHeader = () => <h2>New Inventory Item</h2>;
  
  const handleUpdateImageFile = (file: File | null) => {
    updateImageFile(file);
  };
  
  const renderBody = () => <NewInventoryModalBody isLoading={newInventoryFormState.isSubmitting} updateImageFile={handleUpdateImageFile} />;
  
  const handleClose = () => {
    dispatch(hideNewInventoryModal());
    dispatch(clearForm());
  };
  
  const handleSubmit = () => {
    const {
      isValid,
      fields,
    }  = newInventoryFormState;
    
    if (!isValid) return;
    
    if (session && imageFile) {
      dispatch(postNewItem(session, fields, imageFile))
    }
  };

  const renderActions = () => (
    <button type="button"
      className={classNames("pure-button", styles.submitButton)} 
      onClick={handleSubmit}>
      Submit
    </button>
  );
  
  return (
    <Modal
      onClose={handleClose}
      renderHeader={renderHeader}
      renderBody={renderBody}
      renderActions={renderActions}
    />
  );
};

export default NewInventoryModal;

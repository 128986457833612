import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UserSettings from '../../models/UserSettings';
import HashMap from '../../models/HashMap';
import UserSettingsFormField from '../../models/UserSettingsFormField.enum';
import validateTruthyValue from '../../helpers/validate-truthy-value';

export interface SettingsState {
  publishableKey: string;
  secretKey: string;
  isLoading: boolean;
  settingsRequestComplete: boolean;
  settingsRequestError: string;
  settingsPostError: string;
  form: HashMap<UserSettingsFormField, string>;
  validationErrors: string[];
  formIsValid: boolean,
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    publishableKey: '', 
    secretKey: '',
    isLoading: false,
    settingsRequestComplete: false,
    form: new HashMap<UserSettingsFormField, string>(),
    validationErrors: new Array<string>(),
    formIsValid: true,
  } as SettingsState,
  reducers: {
    settingsRequested(state) {
      state.isLoading = true;
      state.settingsRequestComplete = false;
    },
    settingsRequestSucceeded(state, action: PayloadAction<UserSettings>) {
      const settings = action.payload;
      const publishableKey = settings.publishableKey || '';
      const secretKey = settings.secretKey || '';
      const allowedOrigins = (settings.allowedOrigins || []).join('\n');
      state.isLoading = false;
      state.settingsRequestComplete = true;
      state.publishableKey = publishableKey;
      state.secretKey = secretKey;
      state.form.set(UserSettingsFormField.publishableKey, publishableKey);
      state.form.set(UserSettingsFormField.secretKey, secretKey);
      state.form.set(UserSettingsFormField.externalAppKey, settings.externalAppKey);
      state.form.set(UserSettingsFormField.allowedOrigins, allowedOrigins);
    },
    settingsRequestFailed(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.settingsRequestComplete = true;
      state.settingsRequestError = action.payload;
    },
    settingsPosted(state) {
      state.isLoading = true;
    },
    settingsPostSucceeded(state) {
      state.isLoading = false;
    },
    settingsPostFailed(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.settingsPostError = action.payload;
    },
    updateForm(state, action: PayloadAction<{field: UserSettingsFormField, value: string}>) {
      const { field, value} = action.payload;
      
      state.form.set(field, value);
      
      const validationErrors = validateTruthyValue(state.form.ref());

      state.validationErrors = validationErrors;
      state.formIsValid = !validationErrors.length;
    },
    clearForm(state) {
      state.form.clear();
      state.formIsValid = false;
      state.validationErrors = new Array<string>();
      state.settingsRequestComplete = false;
    },
  }
});

export default settingsSlice;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import Modal from '../../components/Modal/Modal';
import exerciseSlice from '../../store/exercises/exercises-slice';
import {  newExerciseFormSelector } from '../../store/exercises/exercises-selector';
import { postNew, updateExercise } from '../../store/exercises/exercises-thunks';
import styles from './new-exercise-modal.module.scss';
import useSession from '../../custom_hooks/useSession';
import ExerciseDetailsModalBody from './components/ExerciseDetailsModalBody';
import AddMediaModalBody from './components/AddMediaModalBody';
import FormField from '../../models/ExerciseField.enum';

enum ModalStep {
  DetailsStep,
  MediaStep
}

const NewExerciseModal = () => {
  const dispatch = useDispatch();
  const [currentStep, setModalStep] = useState<ModalStep>(ModalStep.DetailsStep);
  const newExerciseFormState = useSelector(newExerciseFormSelector);
  const session = useSession();
  
  
  const { hideNewExerciseModal, clearForm } = exerciseSlice.actions;
  
  const renderHeader = () => <h2>New Exercise</h2>;
  
  const renderBody = () => {
    if (currentStep === ModalStep.DetailsStep) {
      return <ExerciseDetailsModalBody isLoading={newExerciseFormState.isSubmitting} />
    } else {
      return <AddMediaModalBody isLoading={newExerciseFormState.isSubmitting} />
    }
  };
  
  const handleClose = () => {
    dispatch(hideNewExerciseModal());
    dispatch(clearForm());
  };
  
  const handleSubmit = () => {
    const {
      isValid,
      fields,
      mediaSources,
    }  = newExerciseFormState;
    
    if (!isValid) return;
    
    if (session) {
      dispatch(postNew(session, fields, mediaSources))
    }
  };
  
  const handleNext = () => {
    setModalStep(ModalStep.MediaStep);
  };
  const handlePrev = () => {
    setModalStep(ModalStep.DetailsStep);
  };

  const renderActions = () => (<div className={styles.actionsContainer}>
    <button type="button"
      className={classNames("pure-button", styles.cancelButton)} 
      onClick={handleClose}>
      Close
    </button>

    { currentStep === ModalStep.DetailsStep &&
      (
        <button type="button"
          className={classNames("pure-button", styles.nextButton)} 
          onClick={handleNext}>
          Add Media &gt;
        </button>
      )
    }

    { currentStep === ModalStep.MediaStep &&
      (
        <button type="button"
          className={classNames("pure-button", styles.previousButton)} 
          onClick={handlePrev}>
          &lt; Edit Details
        </button>
      )
    }
      
    { currentStep === ModalStep.MediaStep &&
      (
        <button type="button"
          className={classNames("pure-button", styles.submitButton)} 
          onClick={handleSubmit}>
          Submit
        </button>
      )
    }
  </div>);
  
  return (
    <Modal
      onClose={handleClose}
      renderHeader={renderHeader}
      renderBody={renderBody}
      renderActions={renderActions}
    />
  );
};

export default NewExerciseModal;

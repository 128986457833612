import { combineReducers } from '@reduxjs/toolkit';
import authSlice from './auth/auth-slice';
import inventorySlice from './inventory/inventory-slice';
import settingsSlice from './settings/settings-slice';
import ordersSlice from './orders/orders-slice';
import contactUsRequestsSlice from './contactUsRequests/contact-us-requests-slice';
import homeSlice from './home/home-slice';
import preferencesSlice from './preferences/preferences-slice';
import navigationSlice from './navigation/navigation-slice';
import blogPagesSlice from './blogPages/blog-pages-slice';
import userAppSlice from './userApps/user-apps-slice';
import exercisesSlice from './exercises/exercises-slice';
import exerciseProgramsSlice from './exercisePrograms/exercise-programs-slice';
import clientsSlice from './clients/clients-slice';
import exerciseProgramClientsSlice from './exercisesProgramClients/exercise-program-clients-slice';
import exerciseProgramExercisesSlice from './exerciseProgramExercises/exercise-program-exercises-slice';
import messagesSlice from './messages/messages-slice';

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  inventory: inventorySlice.reducer,
  settings: settingsSlice.reducer,
  orders: ordersSlice.reducer,
  contactUs: contactUsRequestsSlice.reducer,
  home: homeSlice.reducer,
  preferences: preferencesSlice.reducer,
  navigation: navigationSlice.reducer,
  blogPages: blogPagesSlice.reducer,
  userApps: userAppSlice.reducer,
  exercises: exercisesSlice.reducer,
  exercisePrograms: exerciseProgramsSlice.reducer,
  clients: clientsSlice.reducer,
  exerciseProgramClients: exerciseProgramClientsSlice.reducer,
  exerciseProgramExercises: exerciseProgramExercisesSlice.reducer,
  messages: messagesSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>

const appReducer = (state: RootState | undefined, action: { type: string }) => {
  if (action.type === authSlice.actions.signOutSucceeded().type && state) {
    const nuState = {
      ...state,
    };
    delete nuState.inventory;
    delete nuState.settings;
    delete nuState.orders;
    delete nuState.contactUs;
    delete nuState.home;
    delete nuState.preferences;
    delete nuState.blogPages;
    delete nuState.navigation;
    delete nuState.userApps;
    delete nuState.exercises;
    delete nuState.exercisePrograms;
    delete nuState.clients;
    delete nuState.exerciseProgramClients;
    delete nuState.exerciseProgramExercises;
    delete nuState.messages;

    return rootReducer(nuState, action);
  }

  return rootReducer(state, action);
}

export default appReducer;

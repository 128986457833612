import firebase from 'firebase';

export interface ConfigSettings {
  publishableKey?: string;
  error?: string;
}

export interface ConfigAPI {
  getConfigSettings: () => Promise<ConfigSettings>;
}

const configAPI = {
  getConfigSettings: async () => {
    const response = await firebase.functions().httpsCallable('getPublicSettings')();
    return response.data as any as ConfigSettings;
  },
};

export default configAPI;

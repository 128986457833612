import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HashMap from '../../models/HashMap';
import ExerciseProgramExercise from '../../models/ExerciseProgramExercise';
import ManageExerciseFormField from '../../models/ManageExerciseFormField';

type ExerciseProgramExercisesId = string;
  
interface ExerciseProgramExercisesState {
  exerciseProgramExercises: ExerciseProgramExercise[];
  hasSubscribedToExerciseProgramExercises: boolean;
  subscriptionId?: string;
  subscriptionError: string;
  updatingExerciseProgramExercisesIds: ExerciseProgramExercisesId[];
  updatingErrors: HashMap<ExerciseProgramExercisesId, string>;
  isProcessing: boolean;
  submissionError: string;
  manageExerciseForm: HashMap<ManageExerciseFormField, string>;
  manageModalIsVisible: boolean;
  isProcessingManageModal: boolean;
}

const exerciseProgramExercisesSlice = createSlice({
  name: 'exerciseProgramExercises',
  initialState: {
    exerciseProgramExercises: [] as ExerciseProgramExercise[],
    updatingExerciseProgramExercisesIds: [] as ExerciseProgramExercisesId[],
    updatingErrors: new HashMap<ExerciseProgramExercisesId, string>(),
    isProcessing: false,
    manageExerciseForm: new HashMap<ManageExerciseFormField, string>(),
    manageModalIsVisible: false,
    isProcessingManageModal: false,
  } as ExerciseProgramExercisesState,
  reducers: {
    submittingExerciseProgramExercisesStarted(state) {
      state.isProcessing = true;
    },
    submittingExerciseProgramExercisesCompleted(state) {
      state.isProcessing = false;
    },
    submittingExerciseProgramExercisesFailed(state, action: PayloadAction<string>) {
      state.isProcessing = false;
      state.submissionError = action.payload;
    },
    unsubscribedToExerciseProgramExercises(state) {
      state.hasSubscribedToExerciseProgramExercises = false;
      delete state.subscriptionId;
    },
    subscribedToExerciseProgramExercises(state, action: PayloadAction<string>) {
      state.hasSubscribedToExerciseProgramExercises = true;
      state.subscriptionId = action.payload;
    },
    exerciseProgramExercisesReceived(state, action: PayloadAction<ExerciseProgramExercise[]>) {
      state.exerciseProgramExercises = action.payload;
    },
    exerciseProgramExercisesSubscriptionFailed(state, action: PayloadAction<string>) {
      state.hasSubscribedToExerciseProgramExercises = true;
      state.subscriptionError = action.payload;
    },
    exerciseProgramExerciseUpdateRequested(state, action:PayloadAction<ExerciseProgramExercisesId>) {
      state.updatingExerciseProgramExercisesIds.push(action.payload);
    },
    exerciseProgramExerciseUpdateCompleted(state, action:PayloadAction<ExerciseProgramExercisesId>) {
      const execiseId = action.payload;
      state.updatingExerciseProgramExercisesIds = state.updatingExerciseProgramExercisesIds.filter(id => id !== execiseId);
      state.updatingErrors.delete(execiseId);
      state.manageModalIsVisible = false;
      state.manageExerciseForm.clear();
    },
    exerciseProgramExerciseUpdateFailed(state, action:PayloadAction<[ExerciseProgramExercisesId,string]>) {
      const [exerciseProgramExercisesId, errorMessage] = action.payload;

      state.updatingExerciseProgramExercisesIds = state.updatingExerciseProgramExercisesIds.filter(id => id !== exerciseProgramExercisesId);
      state.updatingErrors.set(exerciseProgramExercisesId, errorMessage);
    },
    openManageExerciseModal(state, action: PayloadAction<ExerciseProgramExercise>) {
      const epExercise = action.payload;
      state.manageModalIsVisible = true;
      state.manageExerciseForm.set(ManageExerciseFormField.exerciseId, epExercise.exerciseId.toString());
      state.manageExerciseForm.set(ManageExerciseFormField.splitDay, epExercise.splitDay?.toString() || '1');
      state.manageExerciseForm.set(ManageExerciseFormField.reps, epExercise.reps?.toString() || '1');
      state.manageExerciseForm.set(ManageExerciseFormField.sets, epExercise.sets?.toString() || '1');
      state.manageExerciseForm.set(ManageExerciseFormField.order, epExercise.order?.toString() || '1');
    },
    closeManageExerciseModal(state) {
      state.manageModalIsVisible = false;
      state.manageExerciseForm.clear();
    },
    updateManageForm(state, action: PayloadAction<{field: ManageExerciseFormField, value: string}>) {
      const { field, value} = action.payload;
      
      state.manageExerciseForm.set(field, value);
    },
    clearEditForm(state) {
      state.manageExerciseForm.clear();
    },
  }
});

export default exerciseProgramExercisesSlice;

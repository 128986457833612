import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import styles from './client-details-container.module.scss';
import Client from '../../models/Client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clientsSlice from '../../store/clients/clients-slice';
import ClientDetails from '../../models/ClientDetails';
import EventHistory from '../EventHistory/EventHistory';
import useExercises from '../../store/exercises/useExercises';
import LoadingWithOverlay from '../LoadingWithOverlay/LoadingWithOverlay';
import HomeSummaryCard, { CardColor } from '../HomeSummaryCard/HomeSummaryCard';
import { getLastExercise, getLastLogin } from './client-details-calculator';
import { format } from 'date-fns';
import Messages from '../Messages/Messages';

interface ClientsContainerProps {
  clientDetails: ClientDetails[];
  client: Client;
  isLoading: boolean;
}

const ClientsContainer = ({ client, clientDetails, isLoading }: ClientsContainerProps) => {
  const dispatch = useDispatch();
  
  const { exercises, hasSubscribedToExercises } = useExercises();

  const {
    showClientModal,
  } = clientsSlice.actions;

  const handleEditClick = () => {
    dispatch(showClientModal(client.clientId));
  }
  
  const lastLogIn = getLastLogin(clientDetails);
  
  const lastExerciseInfo = getLastExercise(clientDetails, exercises);
  const lastExerciseName = lastExerciseInfo?.exercise?.exerciseName;
  const lastExerciseDate = lastExerciseInfo?.event?.date && new Date(lastExerciseInfo?.event?.date);

  return (<>
    {(isLoading || !hasSubscribedToExercises) && <LoadingWithOverlay contained={false}/> }
    <section className={classNames("pure-u-1", styles.headerSection)}>
      <h1><span className={styles.clientName}>{client.clientName}</span> Details</h1>
      <button
        type="button"
        className={classNames('pure-button pure-button-primary', styles.actionButton)}
        onClick={handleEditClick}
      >
        <FontAwesomeIcon icon={['fas', 'edit']} />
      </button>
    </section>
    
    <section className={styles.tableContainer}>
      <div className={"pure-g"}>
        <div className="pure-u-1">
          <hr /> 
        </div>
        <div className={"pure-u-1-3"}>
          <span className={styles.infoLabel}>
            Name:
          </span>
          <span className={styles.infoValue}>
            {client.clientName}
          </span>
        </div>
        <div className={"pure-u-1-3"}>
          <span className={styles.infoLabel}>
            Email:
          </span>
          <span className={styles.infoValue}>
            {client.email}
          </span>
        </div>
        <div className={"pure-u-1-3"}>
          <span className={styles.infoLabel}>
            Status:
          </span>
          <span className={styles.infoValue}>
            {client.clientState}
          </span>
        </div>
        <div className="pure-u-1">
          <hr /> 
        </div>
        <div className="pure-u-1">
          <div className="pure-u-1 pure-u-md-1-2">
            <h3>All Client Events</h3>
          </div>
          <div className="pure-u-1 pure-u-md-1-2">
            <h3>Messages</h3>
          </div>
        </div>
        <div className="pure-u-1 pure-u-md-1-2">
          <div className={classNames(styles.eventHistoryContainer)}>
            <EventHistory clientDetails={clientDetails} exercises={exercises} />
          </div>
        </div>
        <div className="pure-u-1 pure-u-md-1-2">
          <div className={classNames(styles.eventHistoryContainer)}>
            <Messages client={client} />
          </div>
        </div>
        <div className="pure-u-1">
          <div className={classNames("pure-g")}>
            { lastLogIn && (
              <div className="pure-u-1-2">
                <div className={styles.cardContainer}>
                  <HomeSummaryCard
                    cardValue={`${format(lastLogIn, 'dd MMM yyyy')} at ${format(lastLogIn, 'h:mm a')}`}
                    color={CardColor.primary}
                    header="Last Login"
                    footer=""
                    Icon={<FontAwesomeIcon icon={['fas', 'users']} />}
                  />
                </div>
              </div>
            )}
            { lastExerciseName && lastExerciseDate && (
              <div className="pure-u-1-2">
                <div className={styles.cardContainer}>
                  <HomeSummaryCard
                    cardValue={lastExerciseName}
                    color={CardColor.secondary}
                    header="Last Completed Exercise"
                    footer={`Completed on ${format(lastExerciseDate, 'dd MMM yyyy')} at ${format(lastExerciseDate, 'h:mm a')}`}
                    Icon={<FontAwesomeIcon icon={['fas', 'users']} />}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  </>);
};

export default ClientsContainer;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import LoadingWithOverlay from '../../../components/LoadingWithOverlay/LoadingWithOverlay';
import styles from './contact-us-details-modal-body.module.scss';
import { ContactUsRequestFormField as FormField } from '../../../models/ContactUsRequest';
import contactUsSlice from '../../../store/contactUsRequests/contact-us-requests-slice';
import { contactUsFormStateSelector } from '../../../store/contactUsRequests/contact-us-requests-selector';
import { ContactUsRequestState } from '../../../models/ContactUsRequest';
import HashMap from '../../../models/HashMap';

const OrderDetailsModalBody = () => {
  const dispatch = useDispatch();
  const {
    form,
    validationErrors,
    selectedContactUsRequest,
    isProcessing,
  } = useSelector(contactUsFormStateSelector);
  
  const { updateForm, setFormDefaults } = contactUsSlice.actions;
  
  useEffect(() => {
    const formDefaults = new HashMap<FormField, string>();
    formDefaults.set(FormField.internalNotes, selectedContactUsRequest?.internalNotes || '');
    formDefaults.set(FormField.contactUsRequestState, selectedContactUsRequest?.contactUsRequestState || '');
    formDefaults.set(FormField.contactUsRequestId, selectedContactUsRequest?.contactUsRequestId || '');
    dispatch(setFormDefaults(formDefaults));
  }, [])
  
  const handleOnChange =  (field: FormField) => (ev: React.FormEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    ev.preventDefault();
    const value = ev.currentTarget.value;
    
    dispatch(updateForm({ field, value}));
  };
  
  const handleStateChange = (ev: React.FormEvent<HTMLSelectElement>) => {
    ev.preventDefault();
    dispatch(updateForm({ field: FormField.contactUsRequestState, value: ev.currentTarget.value }));
  };
  
  return (
    <div id="order-form" className={classNames("pure-g", styles.formContainer)}>
      { isProcessing && <LoadingWithOverlay contained={false}/>}
        <div className="pure-u-1-2">
          <span className={styles.label}>Full Name</span>
          <span className={styles.value}>{selectedContactUsRequest?.fullName}</span>
        </div>
        <div className="pure-u-1-4">
          <span className={styles.label}>Email</span>
          <span className={styles.value}>{selectedContactUsRequest?.email}</span>
        </div>
        <div className="pure-u-1">
          <div className={styles.spacer} />
        </div>
        <div className="pure-u-2-3">
          <span className={styles.label}>Message</span>
          <span className={styles.value}>{selectedContactUsRequest?.message}</span>
        </div>
        <div className="pure-u-1">
          <hr />
        </div>
        <div className="pure-u-1-2">
          <span className={classNames(styles.label, styles.fullWidth)}>
            Internal Notes
          </span>
          <textarea
            value={form.getWithDefault(FormField.internalNotes, "") }
            className={styles.textArea}
            onChange={handleOnChange(FormField.internalNotes)}
          />
        </div>
        <div className="pure-u-1">
          <div className={styles.spacer} />
        </div>
        <div className="pure-u-1">
          <span className={classNames(styles.label, styles.fullWidth)}>
            Contact Us Request State
          </span>
          <select value={form.getWithDefault(FormField.contactUsRequestState, ContactUsRequestState.Received)}
            onChange={handleStateChange}>
            <option value={ContactUsRequestState.Received}>Received</option>
            <option value={ContactUsRequestState.Reviewed}>Reviewed</option>
            <option value={ContactUsRequestState.Responded}>Responded</option>
          </select>
        </div>
    </div>
  );
};

export default OrderDetailsModalBody;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './exercise-programs-container.module.scss';
import Loading from '../Loading/Loading';
import ExerciseProgramCard from './components/ExerciseProgramCard/ExerciseProgramCard';
import ExerciseProgram from '../../models/ExerciseProgram';
import PlusIcon from '../Icons/PlusIcon';
import exerciseProgramsSlice from '../../store/exercisePrograms/exercise-programs-slice';
import { selectedExerciseProgramSelector } from '../../store/exercisePrograms/exercise-programs-selectors';

interface ExerciseProgramsContainerProps {
    exercisePrograms: ExerciseProgram[];
}

const ExerciseProgramsContainer = ({ exercisePrograms }: ExerciseProgramsContainerProps) => {
    const dispatch = useDispatch();
    const selectedExerciseProgram = useSelector(selectedExerciseProgramSelector);

    const handleNewAppClick = () => {
        dispatch(exerciseProgramsSlice.actions.showExerciseProgramModal());
    };

    const hasExercisePrograms = !!(exercisePrograms && exercisePrograms.length);

    return (<>
        <section className={classNames("pure-u-1", styles.headerSection)}>
        <h1>My Apps</h1>
        <button className={classNames("pure-button", styles.newAppButton)}
            onClick={handleNewAppClick}
        >
            <span className={styles.iconContainer}>
                <PlusIcon />
            </span>
            Create Exercise Program
        </button>
        </section>
        <section className={classNames("pure-g")}>
            { !hasExercisePrograms && (
                <div className="pure-u-1">
                    <p>You have not create an exercise program.</p>
                </div>
            )}
            { hasExercisePrograms && exercisePrograms.map((exerciseProgram, i) => (
                <div className="pure-u-1 pure-u-sm-1-2 pure-u-md-1-3" key={exerciseProgram.exerciseProgramId}>
                    <ExerciseProgramCard exerciseProgram={exerciseProgram} selected={selectedExerciseProgram?.exerciseProgramId === exerciseProgram.exerciseProgramId} />
                </div>
            ))}
        </section>
    </>);
};

export default ExerciseProgramsContainer;

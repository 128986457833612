import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import LoadingWithOverlay from '../../../components/LoadingWithOverlay/LoadingWithOverlay';
import styles from './manage-exercise-modal-body.module.scss';
import HashMap from '../../../models/HashMap';
import { manageExerciseSelector } from '../../../store/exerciseProgramExercises/exercise-program-exercises-selector';
import epeSlice from '../../../store/exerciseProgramExercises/exercise-program-exercises-slice';
import { default as FormField} from '../../../models/ManageExerciseFormField';

const ManageExerciseDetailsModalBody = () => {
  const dispatch = useDispatch();
  const {
    form,
    isProcessing,
  } = useSelector(manageExerciseSelector);
  
  const handleOnChange =  (field: FormField) => (ev: React.FormEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    ev.preventDefault();
    const value = ev.currentTarget.value;
    
    dispatch(epeSlice.actions.updateManageForm({ field, value}));
  };
  
  return (
    <div id="manage-exercise-form" className={classNames("pure-form pure-form-aligned", styles.formContainer)}>
        { isProcessing && <LoadingWithOverlay contained={false}/>}
        <fieldset>
          <div className="pure-control-group">
            <label className={styles.label}>Split Day</label>
            <input
              type="number"
              className={classnames(styles.input)}
              onChange={handleOnChange(FormField.splitDay)}
              value={form.getWithDefault(FormField.splitDay, '')}
            />
          </div>
          <div className="pure-control-group">
            <label className={styles.label}>Sets</label>
            <input
              type="number"
              className={classnames(styles.input)}
              onChange={handleOnChange(FormField.sets)}
              value={form.getWithDefault(FormField.sets, '')}
            />
          </div>
          <div className="pure-control-group">
            <label className={styles.label}>Reps</label>
            <input
              type="number"
              className={classnames(styles.input)}
              onChange={handleOnChange(FormField.reps)}
              value={form.getWithDefault(FormField.reps, '')}
            />
          </div>
          <div className="pure-control-group">
            <label className={styles.label}>Order</label>
            <input
              type="number"
              className={classnames(styles.input)}
              onChange={handleOnChange(FormField.order)}
              value={form.getWithDefault(FormField.order, '')}
            />
          </div>
        </fieldset>
    </div>
  );
};

export default ManageExerciseDetailsModalBody;
import firebase from 'firebase';
import { v4 as createUUID } from 'uuid';
import HashMap from '../models/HashMap';
import ExerciseProgramFormField from '../models/ExerciseProgramFormField.enum';
import ExerciseProgram from '../models/ExerciseProgram';
import SessionData from '../models/SessionData';
import { getUserRootDoc } from './Helpers';

type exerciseProgramsUpdatedHandler = (exercisePrograms: ExerciseProgram[]) => void;

const SUBSCRIPTION_CACHE: Map<string, () => void> = new Map();

export const EXERCISE_PROGRAM_PATH= 'exercisePrograms';

const exerciseProgramsAPI = {
  postNewExerciseProgram: async (userId: string, form: HashMap<ExerciseProgramFormField, string>) => {
    const userCollection = firebase.firestore().collection('users');
    const currentUserDoc = userCollection.doc(userId);
    const exerciseProgramsCollection = currentUserDoc.collection(EXERCISE_PROGRAM_PATH);

    return exerciseProgramsCollection.add({
      [ExerciseProgramFormField.exerciseProgramName]: form.getWithDefault(ExerciseProgramFormField.exerciseProgramName, ''),
      [ExerciseProgramFormField.exerciseProgramDescription]: form.getWithDefault(ExerciseProgramFormField.exerciseProgramDescription, ''),
      created: (new Date()).toString(),
      active: true,
    }).then();
  },

  updateExerciseProgram: async (userId: string, form: HashMap<ExerciseProgramFormField, string>) => {
    const exerciseProgramId = form.get(ExerciseProgramFormField.exerciseProgramId) as string;

    const userCollection = firebase.firestore().collection('users');
    const currentUserDoc = userCollection.doc(userId);
    const exerciseProgramsCollection = currentUserDoc.collection(EXERCISE_PROGRAM_PATH);
    const exerciseProgramDoc = exerciseProgramsCollection.doc(exerciseProgramId);
    
    return exerciseProgramDoc.update({
      [ExerciseProgramFormField.exerciseProgramName]: form.getWithDefault(ExerciseProgramFormField.exerciseProgramName, ''),
      [ExerciseProgramFormField.exerciseProgramDescription]: form.getWithDefault(ExerciseProgramFormField.exerciseProgramDescription, ''),
      updated: (new Date()).toString(),
    }).then();
  },

  subscribeToExercisePrograms: async (userId: string, exerciseProgramUpdated: exerciseProgramsUpdatedHandler) => {
    const userCollection = firebase.firestore().collection('users');
    const currentUserDoc = userCollection.doc(userId);
    const exerciseProgramCollection = currentUserDoc.collection(EXERCISE_PROGRAM_PATH);

    const unsubscribe = exerciseProgramCollection.onSnapshot((snapshot) => {
      let storeExercisePrograms: ExerciseProgram[] = [];

      snapshot.forEach((doc) => {
        const data = doc.data();
        const exerciseProgram: ExerciseProgram = {
          exerciseProgramId: doc.id as string,
          exerciseProgramName: data.exerciseProgramName,
          exerciseProgramDescription: data.exerciseProgramDescription,
        };

        storeExercisePrograms = [...storeExercisePrograms, exerciseProgram];
      });

      exerciseProgramUpdated(storeExercisePrograms);
    });
    
    const subscriptionUUID = createUUID();

    SUBSCRIPTION_CACHE.set(subscriptionUUID, unsubscribe);

    return subscriptionUUID;
  },

  unsubscribeFromExercisePrograms: (subscriptionId: string) => {
    if (SUBSCRIPTION_CACHE.has(subscriptionId)) {
      const unsubscribe = SUBSCRIPTION_CACHE.get(subscriptionId);
      unsubscribe && unsubscribe();
      SUBSCRIPTION_CACHE.delete(subscriptionId);
    }
  },
};

export default exerciseProgramsAPI;

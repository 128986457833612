import firebase from 'firebase';
import { INVENTORY_PATH } from "./Inventory";
import { CONTACT_US_PATH } from './ContactUs';
import { ContactUsRequestState } from '../models/ContactUsRequest';
import { ORDERS_PATH } from './Orders';
import { OrderState } from '../models/Order';
import { PreferenceIdKey } from '../models/Preference';
import { getAppDoc } from './Helpers';
import SessionData from '../models/SessionData';

type SummaryCardQuery = (session: SessionData) => Promise<number>;

const SummaryCardQueryMap = new Map<PreferenceIdKey, SummaryCardQuery>();

// INVENTORY_OUT_OF_STOCK
SummaryCardQueryMap.set(PreferenceIdKey.INVENTORY_OUT_OF_STOCK, async (session) => {
  const appDoc = getAppDoc(session);
  const inventoryCollection = appDoc.collection(INVENTORY_PATH);
  const query = inventoryCollection.where('active', '==', true).where('quantity', '==', '0');

  const snapshot = await query.get();
  
  return snapshot.size;
});

// UNHANDLED_CONTACT_REQUESTS
SummaryCardQueryMap.set(PreferenceIdKey.UNHANDLED_CONTACT_REQUESTS, async (session) => {
  const appDoc = getAppDoc(session);
  const contactUsCollection = appDoc.collection(CONTACT_US_PATH);

  const query = contactUsCollection.where('contactUsRequestState', '==', ContactUsRequestState.Received)

  const snapshot = await query.get();
  
  return snapshot.size;
});

// UNHANDLED_ORDERS
SummaryCardQueryMap.set(PreferenceIdKey.UNHANDLED_ORDERS, async (session) => {
  const appDoc = getAppDoc(session);
  const ordersCollection = appDoc.collection(ORDERS_PATH);
  
  const snapshot = await ordersCollection.get();
  
  let count = 0;
  
  snapshot.forEach((doc) => {
    const data = doc.data();

    if(data.orderState !== OrderState.OrderShipmentComplete) {
      count++;
    }
  });

  return count;
});

export default SummaryCardQueryMap;


import firebase from 'firebase';
import Exercise from '../models/Exercise';
import { v4 as createUUID } from 'uuid';
import FormField from '../models/NewExerciseFormField.enum';
import ExerciseField from '../models/ExerciseField.enum';
import HashMap from '../models/HashMap';
import SessionData from '../models/SessionData';
import { getAppDoc, getUserRootDoc } from './Helpers';
import MediaSource from '../models/MediaSource';

export const EXERCISES_PATH = 'exercises';

type SubscriptionId = string;

type exercisesUpdatedHandler = (exercises: Exercise[]) => void;

const SUBSCRIPTION_CACHE: Map<string, () => void> = new Map();

const exercisesAPI = {
  subscribeToExercise: async (session: SessionData, exercisesUpdated: exercisesUpdatedHandler) => {
    const rootDoc = getUserRootDoc(session);
    const exerciseCollection = rootDoc.collection(EXERCISES_PATH);

    const unsubscribe = exerciseCollection.onSnapshot((snapshot) => {
      let storeExercises: Exercise[] = [];
      snapshot.forEach((doc) => {
        const exercise: Exercise = {
          exerciseId: doc.id as string,
          ...doc.data() as any,
        };
        storeExercises = [...storeExercises, exercise];
      });
      exercisesUpdated(storeExercises);
    });

    const subscriptionUUID = createUUID();

    SUBSCRIPTION_CACHE.set(subscriptionUUID, unsubscribe);

    return subscriptionUUID;
  },

  unsubscribeFromExercise: (subscriptionId: string) => {
    if (SUBSCRIPTION_CACHE.has(subscriptionId)) {
      const unsubscribe = SUBSCRIPTION_CACHE.get(subscriptionId);
      unsubscribe && unsubscribe();
      SUBSCRIPTION_CACHE.delete(subscriptionId);
    }
  },

  postNewExercise: async (session: SessionData, form: HashMap<FormField, string | File>, mediaSources: MediaSource[]) => {
    const exerciseName = form.get(FormField.exerciseName) as string;
    const category = form.get(FormField.category) as string;
    const description = form.get(FormField.description) as string;
    const unit = form.get(FormField.unit) as string;
    const rootDoc = getUserRootDoc(session);
    const exerciseCollection = rootDoc.collection(EXERCISES_PATH);

    return exerciseCollection.add({
      exerciseName,
      description,
      category,
      mediaSources,
      unit,
      created: (new Date()).toString(),
    }).then();
  },

  updateExercise: async (session: SessionData, form: HashMap<ExerciseField, string | File>, mediaSources: MediaSource[]) => {
    const exerciseId = form.get(ExerciseField.exerciseId) as string;

    const rootDoc = getUserRootDoc(session);
    const exerciseCollection = rootDoc.collection(EXERCISES_PATH);
    const exerciseExerciseDoc = exerciseCollection.doc(exerciseId);

    const exerciseName = form.get(FormField.exerciseName) as string;
    const category = form.get(FormField.category) as string;
    const description = form.get(FormField.description) as string;
    const unit = form.get(FormField.unit) as string;

    return exerciseExerciseDoc.update({
      exerciseName,
      description,
      category,
      mediaSources,
      unit,
      updated: (new Date()).toString(),
    }).then();
  },

  deleteExercise: async (session: SessionData, exerciseId: string) => {
    const rootDoc = getUserRootDoc(session);
    const exerciseCollection = rootDoc.collection(EXERCISES_PATH);
    const exerciseExerciseDoc = exerciseCollection.doc(exerciseId);

    return exerciseExerciseDoc.delete();
  },

};

export default exercisesAPI;

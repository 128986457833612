import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/Modal/Modal';
import styles from './exercise-program-modal.module.scss';
import ExerciseProgramModalBody from './components/ExerciseProgramModalBody';
import { userIdSelector } from '../../store/auth/auth-selectors';
import exerciseProgramsSlice from '../../store/exercisePrograms/exercise-programs-slice';
import { selectedExerciseProgramForModal, exerciseProgramFormSelector } from '../../store/exercisePrograms/exercise-programs-selectors';
import ExerciseProgram from '../../models/ExerciseProgram';
import { postNewExerciseProgram } from '../../store/exercisePrograms/exercise-programs-thunks';

const ExerciseProgramModal = () => {
  const dispatch = useDispatch();
  const { hideExerciseProgramModal } = exerciseProgramsSlice.actions;
  const userId = useSelector(userIdSelector);
  const exerciseProgram = useSelector(selectedExerciseProgramForModal);
  const { form } = useSelector(exerciseProgramFormSelector);
  
  const handleClose = () => {
    dispatch(hideExerciseProgramModal());
  };
  
  const handleSubmit = () => {
    dispatch(postNewExerciseProgram(userId, form));
  };

  const renderHeader = () => <h2>{ exerciseProgram?.exerciseProgramId ? "Edit Exercise Program" : "New Exercise Program"}</h2>;

  const renderBody = () => (<ExerciseProgramModalBody />);

  const renderActions = () => (
    <div className={styles.actionsContainer}>
      <button type="button"
        className={classNames("pure-button", styles.cancelButton)} 
        onClick={handleClose}>
        Close
      </button>
      
      <button type="button"
        className={classNames("pure-button", styles.submitButton)} 
        onClick={handleSubmit}>
        Save 
      </button>
    </div>
  );
  
  return (
    <Modal
      onClose={handleClose}
      renderHeader={renderHeader}
      renderBody={renderBody}
      renderActions={renderActions}
    />
  );
};

export default ExerciseProgramModal;

enum NewInventoryFormField {
  itemName= 'itemName',
  category='category',
  quantity='quantity',
  price='price',
  description='description',
  image='image',
  imageFile='imageFile',
  SKU='SKU',
  shippingCost='shippingCost',
  shippingDateModifierDays='shippingDateModifierDays',
}

export default NewInventoryFormField;

import { format } from 'date-fns'


export enum ClientEventType {
    SIGN_IN = 'SIGN_IN',
    WORKOUT_COMPLETE = 'WORKOUT_COMPLETE'
}

interface ClientEvent {
    eventCategory: string; // yyyyMMdd
    eventId: string;
    eventType: ClientEventType;
    date: string;
    description: string;
    clob: string;
}

export const generateEventCategory = (date: Date) => {
    return format(date, 'yyyyMMdd');
};

export default ClientEvent;
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './exercise-programs.module.scss';
import { userIdSelector } from '../../store/auth/auth-selectors';
import ExerciseProgramsContainer from '../../components/ExerciseProgramsContainer/ExerciseProgramsContainer';
import { exerciseProgramsStateSelector } from '../../store/exercisePrograms/exercise-programs-selectors';
import { subscribeToExercisePrograms, unsubscribeToExercisePrograms } from '../../store/exercisePrograms/exercise-programs-thunks';


const ExercisePrograms = () => {
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);

  const {
    exercisePrograms,
    hasSubscribed,
    subscriptionId,
  } = useSelector(exerciseProgramsStateSelector);
  
  useEffect(() => {
    if (!hasSubscribed) {
      if (userId) {
        dispatch(subscribeToExercisePrograms(userId));
      } else {
        return () => {
          if (subscriptionId) {
            dispatch(unsubscribeToExercisePrograms(subscriptionId));
          }
        }
      }
    }
  }, [userId, hasSubscribed]); 
  
  useEffect(() => {
    if (subscriptionId) {
      dispatch(unsubscribeToExercisePrograms(subscriptionId));
    }
  }, [userId]);

  return (
    <div className={classNames("pure-g", styles.exercisePrograms)}>
    	<div className={classNames("pure-u-1")}>
        <div className={styles.cardContainer}>
          <ExerciseProgramsContainer exercisePrograms={exercisePrograms} />
        </div>
    	</div>
    </div>
  );
};

export default ExercisePrograms;


import React from 'react';
import styles from './app-header.module.scss';

const AppHeader = () => (
  <div className={styles.appHeaderContainer}>
    Be Fire Fitness Management System
  </div>
);

export default AppHeader;

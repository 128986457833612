import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSession from '../../custom_hooks/useSession';
import { clientDetailsSelector } from './clients-selector';
import { requestClientDetails, subscribeToClients, unsubscribeToClients } from './clients-thunks';
import useClients from './useClients';

const useClientDetails = function useClientDetails() {
    const dispatch = useDispatch();
    const { clients, hasSubscribed } = useClients();
    const {
      clientDetails,
      hasLoadedClientDetails,
      isLoading: isLoadingClientDetails,
    } = useSelector(clientDetailsSelector);
    const session = useSession();
    
    useEffect(() => {
      if (clients.length  && !(isLoadingClientDetails || hasLoadedClientDetails)) {
        dispatch(requestClientDetails(clients.map((c) => c.email)));
      }
    }, [clients])
    
    return {
        clients,
        clientDetails,
        hasLoaded: hasLoadedClientDetails && hasSubscribed,
    };
}

export default useClientDetails;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HashMap from '../../models/HashMap';
import Client from '../../models/Client';
import validateTruthyValue from '../../helpers/validate-truthy-value';
import ClientFormField from '../../models/ClientFormField.enum';
import ClientDetails from '../../models/ClientDetails';

type ClientId = string;
  
interface ClientsState {
  clientForm: HashMap<ClientFormField, string>;
  formIsValid: boolean;
  validationErrors: string[];
  isProcessing: boolean;
  clients: Client[];
  hasSubscribed: boolean;
  subscriptionId?: string;
  subscriptionError: string;
  updatingClientIds: ClientId[];
  updatingErrors: HashMap<ClientId, string>;
  selectedClient?: Client;
  clientModalIsVisible: boolean;
  isModalSubmitting: boolean;
  newClientError: string;
  clientDetails: ClientDetails[];
  isLoadingClientDetails: boolean;
  clientDetailsRequestError: string;
  hasLoadedClientDetails: boolean;
}

const clientsSlice = createSlice({
  name: 'client',
  initialState: {
    clientForm: new HashMap<ClientFormField, string>(),
    validationErrors: new Array<string>(),
    isProcessing: false,
    hasSubscribed: false,
    clients:[] as Client[],
    updatingClientIds: [] as ClientId[],
    updatingErrors: new HashMap<ClientId, string>(),
    clientModalIsVisible: false,
    isModalSubmitting: false,
    isLoadingClientDetails: false,
    hasLoadedClientDetails: false,
  } as ClientsState,
  reducers: {
    submittingClientStarted(state) {
      state.isModalSubmitting = true;
    },
    submittingClientCompleted(state) {
      state.isModalSubmitting = false;
      state.clientModalIsVisible = false;
      state.newClientError = '';
    },
    submittingClientFailed(state, action: PayloadAction<string>) {
      state.isModalSubmitting = false;
      state.newClientError = action.payload;
    },
    showClientModal(state, action: PayloadAction<ClientId|undefined>) {
      const clientId = action.payload;
      const selectedClient = clientId && state.clients.find(client => client.clientId === clientId);

      if (selectedClient) {
        state.selectedClient = selectedClient;
      } else {
        state.selectedClient = {} as Client;
      }

      state.clientModalIsVisible = true;
    },
    hideClientModal(state) {
      state.clientModalIsVisible = false;
      delete state.selectedClient;
    },
    setFormDefaults(state, action: PayloadAction<HashMap<ClientFormField, string>>) {
      const fields = action.payload;
      
      fields.forEach(([field, value]) => {
        state.clientForm.set(field, value);
      });
    },
    updateForm(state, action: PayloadAction<{field: ClientFormField, value: string}>) {
      const { field, value} = action.payload;
      
      state.clientForm.set(field, value);
      
      const validationErrors = validateTruthyValue(state.clientForm.ref());

      state.validationErrors = validationErrors;
      state.formIsValid = !validationErrors.length;
    },
    clearForm(state) {
      state.clientForm.clear();
      state.formIsValid = false;
      state.validationErrors = new Array<ClientFormField>();
    },
    unsubscribedFromClients(state) {
      state.hasSubscribed = false;
      delete state.subscriptionId;
    },
    subscribedToClients(state, action: PayloadAction<string>) {
      state.hasSubscribed = true;
      state.subscriptionId = action.payload;
    },
    clientsReceived(state, action: PayloadAction<Client[]>) {
      state.clients= action.payload;
    },
    clientsSubscriptionFailed(state, action: PayloadAction<string>) {
      state.hasSubscribed = true;
      state.subscriptionError = action.payload;
    },
    clientUpdateRequested(state, action:PayloadAction<ClientId>) {
      state.updatingClientIds.push(action.payload);
      state.isModalSubmitting = true;
    },
    clientUpdateCompleted(state, action:PayloadAction<ClientId>) {
      const itemId = action.payload;
      state.updatingClientIds = state.updatingClientIds.filter(id => id !== itemId);
      state.updatingErrors.delete(itemId);
      state.clientModalIsVisible = false;
      state.isModalSubmitting = false;
    },
    clientUpdateFailed(state, action:PayloadAction<[ClientId,string]>) {
      const [itemId, errorMessage] = action.payload;

      state.updatingClientIds = state.updatingClientIds.filter(id => id !== itemId);
      state.updatingErrors.set(itemId, errorMessage);
      state.isModalSubmitting = false;
    },
    
    clientDetailsRequested(state) {
      state.isLoadingClientDetails = true;
    },
    clientDetailsRequestSucceeded(state, action: PayloadAction<ClientDetails[]>) {
      state.isLoadingClientDetails = false;
      state.clientDetailsRequestError = '';
      state.clientDetails = action.payload;
      state.hasLoadedClientDetails = true;
    },
    clientDetailsRequestFailed(state, action: PayloadAction<string>) {
      state.isLoadingClientDetails = false;
      state.clientDetailsRequestError = action.payload;
      state.hasLoadedClientDetails = false;
    },
  }
});

export default clientsSlice;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HashMap from '../../models/HashMap';

export enum NavItem {
  ADD_ON = 'ADD_ON',
  USER_APPS = 'USER_APPS',
  EXERCISE_PROGRAMS = 'EXERCISE_PROGRAMS',
}

interface NavigationState {
  expandedNavs: HashMap<NavItem, boolean>;
}

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    expandedNavs: new HashMap<NavItem, boolean>(),
  } as NavigationState,
  reducers: {
    toggleExpandedNav(state, action: PayloadAction<NavItem>) {
      const navItem = action.payload;
      const isExpanded = state.expandedNavs.getWithDefault(navItem, false);
      state.expandedNavs.set(navItem, !isExpanded);
    }
  }
});

export default navigationSlice;

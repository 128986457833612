import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import LoadingWithOverlay from '../../../components/LoadingWithOverlay/LoadingWithOverlay';
import styles from './client-modal-body.module.scss';
import { default as FormField } from '../../../models/ClientFormField.enum';
import clientsSlice from '../../../store/clients/clients-slice';
import { clientFormSelector } from '../../../store/clients/clients-selector';
import { ClientState } from '../../../models/Client';
import HashMap from '../../../models/HashMap';

const ClientDetailsModalBody = () => {
  const dispatch = useDispatch();
  const {
    form,
    validationErrors,
    selectedClient,
    isModalSubmitting,
  } = useSelector(clientFormSelector);
  
  const { updateForm, setFormDefaults } = clientsSlice.actions;

  useEffect(() => {
    const formDefaults = new HashMap<FormField, string>();
    formDefaults.set(FormField.clientName, selectedClient?.clientName || '');
    formDefaults.set(FormField.email, selectedClient?.email || '');
    formDefaults.set(FormField.clientState, selectedClient?.clientState || '');
    formDefaults.set(FormField.clientId, selectedClient?.clientId || '');
    dispatch(setFormDefaults(formDefaults));
  }, [])

  const handleOnChange =  (field: FormField) => (ev: React.FormEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    ev.preventDefault();
    const value = ev.currentTarget.value;
    
    dispatch(updateForm({ field, value}));
  };

  const handleClientStateChange = (ev: React.FormEvent<HTMLSelectElement>) => {
    ev.preventDefault();
    dispatch(updateForm({ field: FormField.clientState, value: ev.currentTarget.value }));
  };
  
  return (
    <div id="client-form" className={classNames("pure-g pure-form", styles.formContainer)}>
      { isModalSubmitting && <LoadingWithOverlay contained={false}/>}
        <div className="pure-u-1">
          <span className={styles.label}>Name </span>
          <input
            type="text"
            className={classnames(styles.input, 'pure-input-1')}
            onChange={handleOnChange(FormField.clientName)}
            value={form.getWithDefault(FormField.clientName, '')}
          />
        </div>
        <div className="pure-u-1">
          <br />
        </div>
        <div className="pure-u-1">
          <span className={styles.label}>Email</span>
          <input
            type="text"
            className={classnames(styles.input, 'pure-input-1')}
            onChange={handleOnChange(FormField.email)}
            value={form.getWithDefault(FormField.email, '')}
          />
        </div>
        <div className="pure-u-1">
          <div className={styles.spacer} />
        </div>
        <div className="pure-u-1">
          <span className={classNames(styles.label, styles.fullWidth)}>
            Client Status
          </span>
          <select id="client-state"
            value={form.getWithDefault(FormField.clientState, ClientState.inactive)}
            onChange={handleClientStateChange}
            className={"pure-u-1"}
          >
            <option value={ClientState.active}>Active</option>
            <option value={ClientState.inactive}>Inactive</option>
          </select>
        </div>
    </div>
  );
};

export default ClientDetailsModalBody;
import AddOn from '../../models/AddOn';
import { PreferenceIdKey } from '../../models/Preference';

const OrderTrackingAddon = {
    key: PreferenceIdKey.ORDER_TRACKING,
    description: 'View and Handler your Orders',
    headerText: 'Orders',
    link: '/orders',
    summaryCardIds: [PreferenceIdKey.UNHANDLED_ORDERS],
} as AddOn;

export default OrderTrackingAddon;
import firebase from 'firebase';
import { v4 as createUUID } from 'uuid';
import ContactUsRequest, { toContactUsRequestState, ContactUsRequestFormField as FormField, ContactUsRequestState } from '../models/ContactUsRequest';
import HashMap from '../models/HashMap';
import SessionData from '../models/SessionData';
import { getAppDoc } from './Helpers';

type ContactUsRequestsUpdatedHandler = (contactUsRequests: ContactUsRequest[]) => void;

const SUBSCRIPTION_CACHE: Map<string, () => void> = new Map();

export const CONTACT_US_PATH = 'contactUs';

const contactUsAPI = {
  subscribeToContactUsRequests: async (session: SessionData, contactUsRequestsUpdated: ContactUsRequestsUpdatedHandler) => {
    const appDoc = getAppDoc(session);
    const contactUsCollection = appDoc.collection(CONTACT_US_PATH);

    const unsubscribe = contactUsCollection.onSnapshot((snapshot) => {
      let storeContactUsRequests : ContactUsRequest[] = [];

      snapshot.forEach((doc) => {
        const data = doc.data();
        const contactUsRequest: ContactUsRequest = {
          contactUsRequestId: doc.id as string,
          email: data.email,
          fullName: data.fullName,
          internalNotes: data.internalNotes,
          updated: data.updated,
          contactUsRequestState: toContactUsRequestState(data.contactUsRequestState || ''),
          message: data.message,
        };

        storeContactUsRequests = [...storeContactUsRequests, contactUsRequest];
      });

      contactUsRequestsUpdated(storeContactUsRequests);
    });
    
    const subscriptionUUID = createUUID();

    SUBSCRIPTION_CACHE.set(subscriptionUUID, unsubscribe);

    return subscriptionUUID;
  },

  unsubscribeFromContactUsReqeusts: (subscriptionId: string) => {
    if (SUBSCRIPTION_CACHE.has(subscriptionId)) {
      const unsubscribe = SUBSCRIPTION_CACHE.get(subscriptionId);
      unsubscribe && unsubscribe();
      SUBSCRIPTION_CACHE.delete(subscriptionId);
    }
  },

  updateContactUsRequest: async (session: SessionData, form: HashMap<FormField, string>) => {
    const contactUsRequestId = form.get(FormField.contactUsRequestId) as string;

    const appDoc = getAppDoc(session);
    const contactUsCollection = appDoc.collection(CONTACT_US_PATH);
    const contactUsDoc = contactUsCollection.doc(contactUsRequestId);
    
    return contactUsDoc.update({
      [FormField.internalNotes]: form.getWithDefault(FormField.internalNotes, ''),
      [FormField.contactUsRequestState]: form.getWithDefault(FormField.contactUsRequestState, ''),
      updated: (new Date()),
    }).then();
  },

  receivedContactUsRequestsCount: async (session: SessionData) => {
    const appDoc = getAppDoc(session);
    const contactUsCollection = appDoc.collection(CONTACT_US_PATH);

    const query = contactUsCollection.where('contactUsRequestState', '==', ContactUsRequestState.Received)

    const snapshot = await query.get();
    
    return snapshot.size;
  },
};

export default contactUsAPI;

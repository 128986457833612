import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import useSession from '../../custom_hooks/useSession';

const EXEMPT_ROUTES = [
    'exercise-programs',
    'sign-in',
    'sign-up',
    'summary',
    'clients',
    'client-details',
    'exercises',
    'messages',
];

const SelectedExerciseProgramWatcher = () => {
    const session = useSession();
    const [_, pathname]  = window.location.pathname.split('/')
    const isOnExemptRoute = EXEMPT_ROUTES.some(r => pathname.endsWith(r));

    if (session.selectedExerciseProgramId || isOnExemptRoute) return null;

    return <Redirect to="/exercise-programs" />;
};

export default SelectedExerciseProgramWatcher;

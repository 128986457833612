import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './sign-up.module.scss';
import { Link, Redirect } from 'react-router-dom';
import useSession from '../../custom_hooks/useSession';
import SignUpField from '../../models/SignUpField.enum';
import HashMap from '../../models/HashMap';
import FormError from '../../components/FormError/FormError';
import LoadingWithOverlay from '../../components/LoadingWithOverlay/LoadingWithOverlay';
import api from '../../api';


const SignUp = () => {
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [showErrors, setShowErrors] = useState(false);
    const [form, setForm] = useState(new HashMap<SignUpField, string>());
    const [isLoading, setIsLoading] = useState(false);

    const { userId } = useSession();

    const requiredFields = [SignUpField.displayName, SignUpField.email, SignUpField.password];


    const handleFieldChange = (field: SignUpField) => (ev: React.FormEvent<HTMLInputElement>) => {
        form.set(field, ev.currentTarget.value);
        setForm(form.clone());
    };

    const getError = (field: SignUpField) => {
        if (showErrors && !form.getWithDefault(field, '')) {
            return 'required';
        }
    };

    const getPwConfirmError = () => {
        if (showErrors && !passwordConfirm) {
            return 'required';
        }

        if (passwordConfirm
            && form.getWithDefault(SignUpField.password, '')
            && passwordConfirm !== form.getWithDefault(SignUpField.password, '')
        ) {
            return 'password and confirmation must match';
        }
    };
    
    const handleSignUpClick = (ev: React.FormEvent<HTMLButtonElement>) => {
        const isValid = requiredFields.reduce((valid, field) => {
            return valid && !!form.get(field);
        }, true);
        
        if (isValid) {
            setIsLoading(true);
            api.auth.signUp(form);
            setShowErrors(false);
        } else {
            setShowErrors(true);
        }
        
        ev.preventDefault();
    };

    if (userId) return <Redirect to="/" />;
    
    return (
    <div className={classNames("pure-g", styles.signUp)}>
    	<div className={classNames("pure-u-1", styles.cardContainer)}>
            { isLoading && (<LoadingWithOverlay contained />)}
            <h2 className={styles.welcomeMessage}>Sign Up</h2>
            <form className={classNames("pure-form pure-form-aligned", styles.signUpForm)}>
                <fieldset>
                    <div className="pure-control-group">
                        <label htmlFor="email">Email</label>
                        <input id="email" type="email" className="" placeholder="Email"
                            onChange={handleFieldChange(SignUpField.email)}
                            value={form.getWithDefault(SignUpField.email, '')}
                            required
                        />
                        <div className={styles.errorContainer}>
                            <FormError errorMessage={getError(SignUpField.email)} />
                        </div>
                    </div>
                    <div className="pure-control-group">
                        <label htmlFor="password">Password</label>
                        <input id="password" type="password" className="" placeholder="Password"
                            onChange={handleFieldChange(SignUpField.password)}
                            value={form.getWithDefault(SignUpField.password, '')}
                            required
                        />
                        <div className={styles.errorContainer}>
                            <FormError errorMessage={getError(SignUpField.password)} />
                        </div>
                    </div>
                    <div className="pure-control-group">
                        <label htmlFor="password">Password Confirm</label>
                        <input id="password" type="password" className="" placeholder="Password"
                            onChange={(ev: React.FormEvent<HTMLInputElement>) => { setPasswordConfirm(ev.currentTarget.value); }}
                            value={passwordConfirm}
                            required
                        />
                        <div className={styles.errorContainer}>
                            <FormError errorMessage={getPwConfirmError()} />
                        </div>
                    </div>
                    <div className="pure-control-group">
                        <label htmlFor="firstName">Display Name</label>
                        <input id="displayName" type="text" className="" placeholder="Display Name"
                            onChange={handleFieldChange(SignUpField.displayName)}
                            value={form.getWithDefault(SignUpField.displayName, '')}
                            required
                        />
                        <div className={styles.errorContainer}>
                            <FormError errorMessage={getError(SignUpField.displayName)} />
                        </div>
                    </div>
                    <div className={classNames("pure-control-group")}>
                        <div className={styles.buttonContainer}>
                            <button type="submit" className={
                                classNames("pure-button pure-button-primary", styles.signUpButton)}
                                onClick={handleSignUpClick}
                            >
                                Sign Up
                            </button>
                        </div>
                        <p className={styles.signInContainer}>
                            Already have an account?
                            <Link to="/sign-in" className={styles.signInLink}>
                                Sign In
                            </Link>
                        </p>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>); 
};

export default SignUp;
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './home.module.scss';
import LoadingWithOverlay from '../../components/LoadingWithOverlay/LoadingWithOverlay';
import HomeSummaryCard, { CardColor } from '../../components/HomeSummaryCard/HomeSummaryCard';
import EventHistory from '../../components/EventHistory/EventHistory';
import useSession from '../../custom_hooks/useSession';
import { userSelector } from '../../store/auth/auth-selectors';
import { clientDetailsSelector, clientsStateSelector } from '../../store/clients/clients-selector';
import { requestClientDetails, subscribeToClients, unsubscribeToClients } from '../../store/clients/clients-thunks';
import { mostPopularExercise, totalSets, usersSignedInToday } from './HomeCalculator';
import { format } from 'date-fns';
import { exercisesSelector } from '../../store/exercises/exercises-selector';
import { subscribeToExercises, unsubscribeToExercises } from '../../store/exercises/exercises-thunks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Home = () => {
  const dispatch = useDispatch();
  const session = useSession();
  const userInfo = useSelector(userSelector);
  const {
    clientDetails,
    hasLoadedClientDetails,
    isLoading: isLoadingClientDetails,
  } = useSelector(clientDetailsSelector);
  
  const {
    clients,
    hasSubscribed: hasSubscribedToClients,
    subscriptionId,
  } = useSelector(clientsStateSelector);

  const {
    exercises,
    hasSubscribed: hasSubscribedToExercises,
    subscriptionId: exercisesSubscriptionId,
  } = useSelector(exercisesSelector);
  
  useEffect(() => {
    if (!hasSubscribedToExercises) {
      if (session.userId) {
        dispatch(subscribeToExercises(session));
      } else {
        return () => {
          if (exercisesSubscriptionId) {
            dispatch(unsubscribeToExercises(exercisesSubscriptionId));
          }
        }
      }
    }
  }, [session, hasSubscribedToExercises]); 
  
  useEffect(() => {
    if (!hasSubscribedToClients) {
      if (session.userId) {
        dispatch(subscribeToClients(session));
      } else {
        return () => {
          if (subscriptionId) {
            dispatch(unsubscribeToClients(subscriptionId));
          }
        }
      }
    }
  }, [session, hasSubscribedToClients]); 
  
  useEffect(() => {
    if (clients.length  && !(isLoadingClientDetails || hasLoadedClientDetails)) {
      dispatch(requestClientDetails(clients.map((c) => c.email)));
    }
  }, [clients])
  
  const signedInUsers = hasLoadedClientDetails
    ? usersSignedInToday(clientDetails)
    : 0;

  const mostPopularExerciseId = hasLoadedClientDetails && exercises.length
    ?mostPopularExercise(clientDetails)
    : "";
  
  const mostPopularExerciseName = mostPopularExerciseId
    ? exercises.find(exercise => exercise.exerciseId === mostPopularExerciseId)?.exerciseName || ""
    : "";

  const numOfSets = hasLoadedClientDetails
    ? totalSets(clientDetails)
    : 0;
  
  const isProcessing = isLoadingClientDetails || !hasSubscribedToExercises || !hasSubscribedToClients;
  
  const now = format(new Date(), 'M/d/yyyy h:m a');

  return (
    <div className={classNames("pure-g", styles.home)}>
    	<div className={classNames("pure-u-1 pure-u-lg-5-6", styles.cardContainer)}>
        <h2 className={styles.welcomeMessage}>Welcome!</h2>
        <p className={styles.summaryMessage}>
          You are signed in as <strong>{userInfo.email}</strong>. 
        </p>
        <p className={styles.summaryMessage}>
          Check out some of the things your clients have been up to
        </p>
        <div className={classNames("pure-g", styles.summaryContainer)}>
          { isProcessing && <LoadingWithOverlay contained /> }
          <div className='pure-u-1'>
            <div className="pure-g">
              <div className="pure-u-1 pure-u-md-1-3">
                <div className={styles.cardWrapper}>
                  <HomeSummaryCard cardValue={signedInUsers} header="Number of Today's Users" color={CardColor.primary} footer={`As of ${now}`}
                    Icon={<FontAwesomeIcon icon={['fas', 'users']} />}
                  />
                </div>
              </div>
              <div className="pure-u-1 pure-u-md-1-3">
                <div className={styles.cardWrapper}>
                  <HomeSummaryCard cardValue={mostPopularExerciseName} header="Most Popular Exercise" color={CardColor.secondary} footer={`As of ${now}`}
                    Icon={<FontAwesomeIcon icon={['fas', 'dumbbell']} />}
                  />
                </div>
              </div>
              <div className="pure-u-1 pure-u-md-1-3">
                <div className={styles.cardWrapper}>
                  <HomeSummaryCard cardValue={numOfSets} header="Total Sets Completed" color={CardColor.accent} footer={`As of ${now}`}
                    Icon={<FontAwesomeIcon icon={['fas', 'mountain']} />}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pure-u-1">
            <hr /> 
          </div>
          <div className="pure-u-1">
            <div className="pure-u-1 pure-u-md-1-2">
              <h3>All Client Events</h3>
            </div>
          </div>
          <div className="pure-u-1 pure-u-md-1-2">
            <div className={classNames(styles.eventHistoryContainer)}>
              <EventHistory clientDetails={clientDetails} exercises={exercises} />
            </div>
          </div>
        </div>
    	</div>
    </div>
  );
};

export default Home;


import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingWithOverlay from '../../../components/LoadingWithOverlay/LoadingWithOverlay';
import styles from '../new-inventory-modal.module.scss';
import { default as FormField } from '../../../models/NewInventoryFormField.enum';
import inventorySlice from '../../../store/inventory/inventory-slice';
import { newInventoryFormSelector } from '../../../store/inventory/inventory-selector';

interface NewInventoryModalBodyProps {
  isLoading: boolean,
  updateImageFile: (file: File | null) => void;
}

const NewInventoryModalBody = ({ isLoading, updateImageFile }: NewInventoryModalBodyProps) => {
  const dispatch = useDispatch();
  const {
    fields,
    validationErrors,
  } = useSelector(newInventoryFormSelector);
  const isSaving = false;
  const { updateForm } = inventorySlice.actions;
  
  const handleOnChange =  (field: FormField) => (ev: React.FormEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    ev.preventDefault();
    const value = ev.currentTarget.value;
    
    dispatch(updateForm({ field, value}));
  };
  
  const handleImageFileChange = (ev: React.FormEvent<HTMLInputElement>) => {
    ev.preventDefault();
    const value = ev.currentTarget.value;
    const field = FormField.image;
    const files = ev.currentTarget.files;
    
    if(files && files.length > 0) {
      const file = files[0];
      updateImageFile(file);
    } else {
      updateImageFile(null);
    }
    
    dispatch(updateForm({ field , value}));
  };
  
  
  return (
    <form id="new-item-form" className="pure-form pure-form-aligned">
      { isLoading && <LoadingWithOverlay contained={false}/>}
      <fieldset>
        <div className="pure-control-group">
          <label htmlFor="item-name">Item Name</label>
          <input id="item-name" type="text" name="itemName" placeholder="Item Name"
                 required value={fields.getWithDefault(FormField.itemName, "")}
                 onChange={handleOnChange(FormField.itemName)}
          />
           { validationErrors.indexOf(FormField.itemName) > -1 &&
              (<span className="pure-form-message validation-message">
                Item name is required.
              </span>)
           }
        </div>
        <div className="pure-control-group">
          <label htmlFor="category">Category</label>
          <input id="category" type="text" name="category" placeholder="Category"
                 required value={fields.getWithDefault(FormField.category, "")} 
                 onChange={handleOnChange(FormField.category)}
           />
          { validationErrors.indexOf(FormField.category) > -1 &&
            <span className="pure-form-message validation-message">
              Category name is required.
            </span>
          }
        </div>
        <div className="pure-control-group">
          <label htmlFor="category">SKU</label>
          <input id="category" type="text" name="category" placeholder="Category"
                 required value={fields.getWithDefault(FormField.SKU, "")} 
                 onChange={handleOnChange(FormField.SKU)}
           />
          { validationErrors.indexOf(FormField.SKU) > -1 &&
            <span className="pure-form-message validation-message">
              SKU is required.
            </span>
          }
        </div>
        <div className="pure-control-group">
          <label htmlFor="quantity">Quantity</label>
          <input id="quantity" type="number" name="quantity" min="0" placeholder="Quantity"
                 required value={fields.getWithDefault(FormField.quantity, "")}
                 onChange={handleOnChange(FormField.quantity)}
           />
          { validationErrors.indexOf(FormField.quantity) > -1 &&
            (<span className="pure-form-message validation-message">
              Quantity is required.
            </span>)
          }
        </div>
        <div className="pure-control-group">
          <label htmlFor="shippingCost">Shipping Cost</label>
          <input id="shippingCost" type="number" name="shippingCost" min="0" placeholder="Shipping Cost"
                 required value={fields.getWithDefault(FormField.shippingCost, "")}
                 onChange={handleOnChange(FormField.shippingCost)}
           />
          { validationErrors.indexOf(FormField.shippingCost) > -1 &&
            (<span className="pure-form-message validation-message">
              Shipping Cost is required.
            </span>)
          }
        </div>
        <div className="pure-control-group">
          <label htmlFor="shippingDateModifierDays">Days Until Shipment</label>
          <input id="shippingDateModifierDays" type="number" name="shippingDateModifierDays" min="0" placeholder="Days Until Shipment"
                 required value={fields.getWithDefault(FormField.shippingDateModifierDays, "")}
                 onChange={handleOnChange(FormField.shippingDateModifierDays)}
           />
          { validationErrors.indexOf(FormField.shippingDateModifierDays) > -1 &&
            (<span className="pure-form-message validation-message">
              Days Until Shipment is required.
            </span>)
          }
        </div>
        <div className="pure-control-group">
          <label htmlFor="price">Price</label>
          <input id="price" type="number" name="price" placeholder="Price"
                 required value={fields.getWithDefault(FormField.price, "")} min=".50"
                 onChange={handleOnChange(FormField.price)}
           />
          { validationErrors.indexOf(FormField.price) > -1 &&
            (<span className="pure-form-message validation-message">
              Price is required.
            </span>)
          }
        </div>
        <div className="pure-control-group">
          <label htmlFor="Image">Image</label>
          <input id="image" type="file" name="image" placeholder="Upload an image"
                 required value={fields.getWithDefault(FormField.image, "")}
                 onChange={handleImageFileChange}
           />
          { validationErrors.indexOf(FormField.image) > -1 &&
            (<span className="pure-form-message validation-message">
              Image is required.
            </span>)
          }
        </div>
        <div className="pure-control-group">
          <label className={styles.descriptionLabel} htmlFor="description">Description</label>
          <textarea id="description" value={fields.getWithDefault(FormField.description, "")}
            name="description" placeholder="Description"
            className={styles.description}
            onChange={handleOnChange(FormField.description)}
          ></textarea>
          { validationErrors.indexOf(FormField.description) > -1 &&
            (<span className="pure-form-message validation-message">
              Description is required.
            </span>)
          }
        </div>
      </fieldset>
      { isSaving && (<LoadingWithOverlay contained={false} />) }
    </form>
  );
};

export default NewInventoryModalBody;
import { compareDesc } from "date-fns";
import ClientDetails from "../../models/ClientDetails";
import ClientEvent, { ClientEventType } from "../../models/ClientEvent";
import Exercise from "../../models/Exercise";
import StatRecord, { StatRecordType } from "../../models/StatRecord";

export const getLastLogin = (clientDetails: ClientDetails[]) => {
    const sortedEvents = clientDetails
        .reduce((allEvents, clientDetails) => {
            return [...allEvents, ...clientDetails.events];
        }, [] as ClientEvent[])
        .sort((a, b) => compareDesc(new Date(a.date), new Date(b.date)));
    
    return sortedEvents.length
    ? new Date(sortedEvents[0].date)
    : null;
};

export const getLastExercise = (clientDetails: ClientDetails[], exercises: Exercise[]) => {
    const sortedEvents = clientDetails
        .reduce((allEvents, clientDetails) => {
            return [...allEvents, ...clientDetails.events];
        }, [] as ClientEvent[])
        .filter((event) => event.eventType === ClientEventType.WORKOUT_COMPLETE)
        .sort((a, b) => compareDesc(new Date(a.date), new Date(b.date)));
    
    if (!sortedEvents.length) return null;
    
    const event = sortedEvents[0];
    const { exerciseId } = JSON.parse(event.clob) as { exerciseId: string };
    
    const exercise = exercises.find(exercise => exercise.exerciseId === exerciseId);

    return { event, exercise };
};
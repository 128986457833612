import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Link, Redirect } from 'react-router-dom';
import styles from './sign-in.module.scss';
import { signIn } from '../../store/auth/user-actions';
import { authStateSelector } from '../../store/auth/auth-selectors';
import LoadingWithOverlay from '../../components/LoadingWithOverlay/LoadingWithOverlay';

enum FormField {
  email = 'email',
  password = 'password',
};

interface ComponentState {
  email: string;
  password: string;
  validationErrors: FormField[];
};

const SignIn = () => {
  const dispatch = useDispatch();
  const [state, updateState] = useState({
    email: '',
    password: '',
    validationErrors: [],
  } as ComponentState);
  const {
    isLoading,
    isSignedIn,
  } = useSelector(authStateSelector);

  const validate = (): boolean => {
    const validationErrors = [] as FormField[];

    Object.keys(FormField).forEach(fieldName => {
      if(!(state as any)[fieldName].trim()) {
        validationErrors.push((FormField as any)[fieldName]);
      }
    });

    updateState({
      ...state,
      validationErrors,
    });

    return !validationErrors.length;
  };

  const handleSignIn = (ev: React.FormEvent) => {
    const isValid = validate();
    ev.preventDefault();

    if (isValid && !isLoading) {
      dispatch(signIn(state.email, state.password));
    }
  };

  const handleInputChange = (prop: FormField) => (ev: React.FormEvent<HTMLInputElement>) => {
    const value = ev.currentTarget.value;

    updateState({
      ...state,
      [prop]: value,
    });
  };

  if (isSignedIn) return (<Redirect to="/" />);

  return (
    <div className={classNames("pure-g", styles.signIn)}>
      { isLoading && <LoadingWithOverlay contained />}
    	<div className={classNames("pure-u-1 pure-u-lg-1-2", styles.cardContainer)}>
        <section
          className={classNames("pure-u-1", styles.signInFormContainer)}
        >
          <form className="pure-form pure-form-aligned">
            <legend>
              <h1>Sign In</h1>
            </legend>
            <fieldset>
              <div className="pure-control-group">
                <label htmlFor="email" className="">Email</label>
                <input id="email" type="email" name="email" placeholder="Email"
                       required value={state.email} className=""
                       onChange={handleInputChange(FormField.email)}
                />
                  {state.validationErrors.indexOf(FormField.email) > -1 && (
                    <span className="pure-form-message validation-message">
                      Email is required.
                    </span>
                  )}
              </div>
              <div className="pure-control-group">
                <label htmlFor="password">Password</label>
                <input id="password" type="password" name="password" placeholder="Password"
                       required value={state.password}
                       onChange={handleInputChange(FormField.password)}
                />
                  {state.validationErrors.indexOf(FormField.password) > -1 && (
                    <span className="pure-form-message validation-message">
                      Password is required.
                    </span>
                  )}
              </div>
      
              <div id="sign-in-button-container" className="pure-control-group">
                <button id="sign-in-button" type="submit"
                  className="pure-button pure-button-primary" onClick={handleSignIn}>Sign In</button>
              </div>
            </fieldset>
          </form>
        </section>
        <div className="pure-u-1">
          Don't have an acount? <Link to="/sign-up">Sign Up</Link>
        </div>
    	</div>
    </div>
  );
};

export default SignIn;


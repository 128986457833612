import React from 'react';
import classNames from 'classnames';
import styles from './client-details.module.scss';
import ClientDetailsContainer from '../../components/ClientDetailsContainer/ClientDetailsContainer';
import { useParams } from 'react-router-dom';
import Client from '../../models/Client';
import useClientDetails from '../../store/clients/useClientDetails';

interface ClientDetailsRouteParams {
  clientId: string;
}

const ClientDetails = () => {
  const  { clientId } = useParams() as ClientDetailsRouteParams;
  const {
    clients,
    clientDetails,
    hasLoaded,
  } = useClientDetails();
  
  const client = hasLoaded
    ? clients.find(client => client.clientId === clientId) as Client
    : null;
  
  const selectedClientDetails = hasLoaded
  ? clientDetails.filter(detail => detail.email === client?.email)
  : null;

  return (
    <div className={classNames("pure-g", styles.purchases)}>
    	<div className={classNames("pure-u-1")}>
        <div className={styles.cardContainer}>
          { client && selectedClientDetails &&
            <ClientDetailsContainer clientDetails={selectedClientDetails} client={client} isLoading={!hasLoaded} />
          }
        </div>
    	</div>
    </div>
  );
};

export default ClientDetails;


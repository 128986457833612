import api from '../../api';
import { AppThunk } from '../index';
import epcSlice from './exercise-program-clients-slice';
import SessionData from '../../models/SessionData';
import Client from '../../models/Client';

export const subscribeToExerciseProgramClients = (session: SessionData): AppThunk => async(dispatch) => {
  const {
    subscribedToExerciseProgramClients,
    exerciseProgramClientsReceived,
    exerciseProgramClientsSubscriptionFailed,
  } = epcSlice.actions;

  try {
    const subscriptionId = await api.exerciseProgramClients.subscribeToClients(session, (items) => {
      dispatch(exerciseProgramClientsReceived(items));
    });
    dispatch(subscribedToExerciseProgramClients(subscriptionId));
  } catch(e) {
    dispatch(exerciseProgramClientsSubscriptionFailed(e.message));
  }
}
      
export const unsubscribeToExerciseProgramClients = (subscriptionId: string): AppThunk => async(dispatch) => {
  const {
    unsubscribedToExerciseProgramClients,
  } = epcSlice.actions;

  await api.exercises.unsubscribeFromExercise(subscriptionId);
  
  dispatch(unsubscribedToExerciseProgramClients());
}

export const addExerciseProgramClient = (session: SessionData, client: Client): AppThunk => async(dispatch) => {
  const {
    exerciseProgramClientUpdateRequested,
    exerciseProgramClientUpdateCompleted,
    exerciseProgramClientUpdateFailed
  } = epcSlice.actions;
  
  dispatch(exerciseProgramClientUpdateRequested(client.clientId));
  
  try {
    await api.exerciseProgramClients.addClient(session, client);
    await api.publicClient.addExerciseProgram(session, client.email);

    dispatch(exerciseProgramClientUpdateCompleted(client.clientId));
  } catch(e) {
    dispatch(exerciseProgramClientUpdateFailed([client.clientId, e.message]));
  }
};

export const removeExerciseProgramClient = (session: SessionData, client: Client): AppThunk => async(dispatch) => {
  const {
    exerciseProgramClientUpdateRequested,
    exerciseProgramClientUpdateCompleted,
    exerciseProgramClientUpdateFailed,
  } = epcSlice.actions;
  
  dispatch(exerciseProgramClientUpdateRequested(client.clientId));
  
  try {
    await api.exerciseProgramClients.removeClient(session, client);
    await api.publicClient.removeExerciseProgram(session, client.email);

    dispatch(exerciseProgramClientUpdateCompleted(client.clientId));
  } catch(e) {
    dispatch(exerciseProgramClientUpdateFailed([client.clientId, e.message]));
  }
};
import api from '../../api';
import { AppThunk } from '../index';
import ordersSlice from './orders-slice';
import OrderField from '../../models/OrderField.enum';
import HashMap from '../../models/HashMap';
import SessionData from '../../models/SessionData';

export const subscribeToOrders = (session: SessionData): AppThunk => async(dispatch) => {
  const {
    subscribedToOrders,
    ordersReceived,
    ordersSubscriptionFailed,
  } = ordersSlice.actions;

  try {
    const subscriptionId = await api.orders.subscribeToOrders(session, (orders) => {
      dispatch(ordersReceived(orders));
    });
    dispatch(subscribedToOrders(subscriptionId));
  } catch(e) {
    dispatch(ordersSubscriptionFailed(e.message));
  }
}
      
export const unsubscribeToOrders = (subscriptionId: string): AppThunk => async(dispatch) => {
  const {
    unsubscribedToOrders,
  } = ordersSlice.actions;

  api.orders.unsubscribeFromOrders(subscriptionId);
  
  dispatch(unsubscribedToOrders());
}

export const updateOrder = (session: SessionData, form: HashMap<OrderField, string>): AppThunk => async(dispatch) => {
  const {
    orderUpdateRequested,
    orderUpdateCompleted,
    orderUpdateFailed,
  } = ordersSlice.actions;
  
  const orderId = form.getWithDefault(OrderField.orderId, '');

  dispatch(orderUpdateRequested(orderId));
  
  try {
    await api.orders.updateOrder(session, form);

    dispatch(orderUpdateCompleted(orderId));
  } catch(e) {
    dispatch(orderUpdateFailed([orderId, e.message]));
  }
};
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './exercise-program-exercises.module.scss';
import { exercisesSelector } from '../../store/exercises/exercises-selector';
import { subscribeToExercises, unsubscribeToExercises } from '../../store/exercises/exercises-thunks';
import useSession from '../../custom_hooks/useSession';
import ExercisesContainer from '../../components/ExerciseProgramExercisesContainer/ExercisesContainer';

import { subscribeToExerciseProgramExercises, unsubscribeToExerciseProgramExercises } from '../../store/exerciseProgramExercises/exercise-program-exercises-thunks';
import { exercisesSelector as epeExercisesSelector } from '../../store/exerciseProgramExercises/exercise-program-exercises-selector';

const Exercises = () => {
  const dispatch = useDispatch();
  const {
    exercises,
    hasSubscribed,
    subscriptionId,
  } = useSelector(exercisesSelector);

  const {
    exerciseProgramExercises,
    hasSubscribed: hasSubscribedToEPE,
    subscriptionId: epcSubscriptionId
  } = useSelector(epeExercisesSelector);

  const session = useSession();
  
  useEffect(() => {
    if (!hasSubscribed) {
      if (session.userId) {
        dispatch(subscribeToExercises(session));
      } else {
        return () => {
          if (subscriptionId) {
            dispatch(unsubscribeToExercises(subscriptionId));
          }
        }
      }
    }
  }, [session, hasSubscribed]); 

  useEffect(() => {
    if (!hasSubscribedToEPE) {
      if (session.userId) {
        dispatch(subscribeToExerciseProgramExercises(session));
      } else {
        return () => {
          if (epcSubscriptionId) {
            dispatch(unsubscribeToExerciseProgramExercises(epcSubscriptionId));
          }
        }
      }
    }
  }, [session, hasSubscribedToEPE]); 
  
  useEffect(() => {
    if (subscriptionId) {
      dispatch(unsubscribeToExercises(subscriptionId));
    }
    if (epcSubscriptionId) {
      dispatch(unsubscribeToExerciseProgramExercises(epcSubscriptionId));
    }
  }, [session.selectedExerciseProgramId]);
  
  return (
    <div className={classNames("pure-g", styles.inventory)}>
    	<div className={classNames("pure-u-1", styles.cardContainer)}>
          <ExercisesContainer exercises={exercises} isLoading={!hasSubscribed} selectedExercises={exerciseProgramExercises} />
    	</div>
    </div>
  );
};

export default Exercises;

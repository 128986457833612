import firebase from 'firebase';
import SessionData from '../models/SessionData';

const USERS_PATH = 'users';
const EXERCISE_PROGRAMS_PATH = 'exercisePrograms';

export const getUserRootDoc = (sessionData: SessionData) => {
    const userCollection = firebase.firestore().collection(USERS_PATH);
    return userCollection.doc(sessionData.userId);
};

export const getAppDoc = (sessionData: SessionData) => {
    const currentUserDoc = getUserRootDoc(sessionData);
    const exerciseProgramsCollection = currentUserDoc.collection(EXERCISE_PROGRAMS_PATH);
    return exerciseProgramsCollection.doc(sessionData.selectedExerciseProgramId);
};

export const getExerciseProgramPath = (sessionData: SessionData) => {
    return `${USERS_PATH}/${sessionData.userId}/${EXERCISE_PROGRAMS_PATH}/${sessionData.selectedExerciseProgramId}`;
};

export default {
    getAppDoc,
}
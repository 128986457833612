import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import styles from './exercise-program-card.module.scss';
import CircleIcon from '../../../Icons/CircleIcon';
import CheckCircleIcon from '../../../Icons/CheckCircleIcon';
import { userIdSelector } from '../../../../store/auth/auth-selectors';
import ExerciseProgram from '../../../../models/ExerciseProgram';
import exerciseProgramsSlice from '../../../../store/exercisePrograms/exercise-programs-slice';

interface AddOnCardProps {
    exerciseProgram: ExerciseProgram;
    selected: boolean;
}

const AddOnCard = ({ exerciseProgram, selected }: AddOnCardProps) => {
    const dispatch = useDispatch();
    const userId = useSelector(userIdSelector);
    const {
        selectExerciseProgram
    } = exerciseProgramsSlice.actions;

    const toggleActivate = () => {
        dispatch(selectExerciseProgram(exerciseProgram));
    };

    return (
        <div className={classnames(styles.cardContainer, { [styles.selected]: selected })} onClick={toggleActivate}>
            <header>
                {exerciseProgram.exerciseProgramName}
                <span className={styles.iconContainer}>
                    { selected
                        ? (<CheckCircleIcon />)
                        : (<CircleIcon />)
                    }
                </span>
            </header>
            <p>
                {exerciseProgram.exerciseProgramDescription}
            </p>
        </div>
    );
};

export default AddOnCard;
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSession from '../../custom_hooks/useSession';
import { exercisesSelector } from './exercises-selector';
import { subscribeToExercises, unsubscribeToExercises } from './exercises-thunks';

const useExercises = function useExercises() {
    const dispatch = useDispatch();
    const session = useSession();
    const {
        exercises,
        hasSubscribed: hasSubscribedToExercises,
        subscriptionId: exercisesSubscriptionId,
    } = useSelector(exercisesSelector);
    useEffect(() => {
        if (!hasSubscribedToExercises) {
            if (session.userId) {
                dispatch(subscribeToExercises(session));
            } else {
                return () => {
                    if (exercisesSubscriptionId) {
                        dispatch(unsubscribeToExercises(exercisesSubscriptionId));
                    }
                }
            }
        }
    }, [session, hasSubscribedToExercises]); 
    
    return {
        exercises,
        hasSubscribedToExercises,
    }
}

export default useExercises;
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './exercise-program-clients.module.scss';
import ClientsContainer from '../../components/ExerciseProgramClientsContainer/ExerciseProgramClientsContainer';
import { subscribeToClients, unsubscribeToClients } from '../../store/clients/clients-thunks';
import { clientsStateSelector } from '../../store/clients/clients-selector';
import useSession from '../../custom_hooks/useSession';

import { subscribeToExerciseProgramClients, unsubscribeToExerciseProgramClients } from '../../store/exercisesProgramClients/exercise-program-clients-thunks';
import { clientsSelector as epcClientsSelector } from '../../store/exercisesProgramClients/exercise-program-clients-selector';


const Clients = () => {
  const dispatch = useDispatch();
  const {
    clients,
    hasSubscribed,
    subscriptionId,
  } = useSelector(clientsStateSelector);
  
  const {
    exerciseProgramClients,
    hasSubscribed: hasSubscribedToEPC,
    subscriptionId: epcSubscriptionId
  } = useSelector(epcClientsSelector);

  const session = useSession();
  
  useEffect(() => {
    if (!hasSubscribed) {
      if (session.userId) {
        dispatch(subscribeToClients(session));
      } else {
        return () => {
          if (subscriptionId) {
            dispatch(unsubscribeToClients(subscriptionId));
          }
        }
      }
    }
  }, [session, hasSubscribed]); 

  useEffect(() => {
    if (!hasSubscribedToEPC) {
      if (session.userId) {
        dispatch(subscribeToExerciseProgramClients(session));
      } else {
        return () => {
          if (epcSubscriptionId) {
            dispatch(unsubscribeToExerciseProgramClients(epcSubscriptionId));
          }
        }
      }
    }
  }, [session, hasSubscribedToEPC]); 
  
  useEffect(() => {
    if (subscriptionId) {
      dispatch(unsubscribeToClients(subscriptionId));
    }
    if (epcSubscriptionId) {
      dispatch(unsubscribeToExerciseProgramClients(epcSubscriptionId));
    }
  }, [session.selectedExerciseProgramId]);

  return (
    <div className={classNames("pure-g", styles.purchases)}>
    	<div className={classNames("pure-u-1")}>
        <div className={styles.cardContainer}>
          { <ClientsContainer clients={clients} epcClients={exerciseProgramClients} isLoading={!hasSubscribed} /> }
        </div>
    	</div>
    </div>
  );
};

export default Clients;


import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SessionData from '../models/SessionData';
import { userIdSelector } from '../store/auth/auth-selectors';
import { selectedExerciseProgramSelector } from '../store/exercisePrograms/exercise-programs-selectors';
import history from '../routes/history';

const useSession = function useSession() {
  const userId = useSelector(userIdSelector) || '';
  const selectedExerciseProgram = useSelector(selectedExerciseProgramSelector);
  
  return {
      userId,
      selectedExerciseProgramId: selectedExerciseProgram?.exerciseProgramId || '',
      isReady: !!(userId && selectedExerciseProgram?.exerciseProgramId),
  } as SessionData;
}

export default useSession;
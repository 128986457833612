import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HashMap from '../../models/HashMap';
import UserApp from '../../models/UserApp';
import UserAppFormField from '../../models/UserAppFormField.enum';
import validateTruthyValue from '../../helpers/validate-truthy-value';

type UserAppId = string;

interface UserAppState {
  userApps: UserApp[];
  selectedUserApp: UserApp;
  userAppModalIsVisible: boolean;
  userAppForModal?: UserApp; 
  userAppForm: HashMap<UserAppFormField, string>;
  formIsValid: boolean;
  validationErrors: string[];
  isProcessing: boolean;
  isModalSubmitting: boolean;
  newUserAppError: string;
  hasSubscribed: boolean;
  subscriptionId?: string;
  subscriptionError?: string;
}

const userAppSlice = createSlice({
  name: 'userApp',
  initialState: {
    userApps: new Array<UserApp>(),
    userAppForm: new HashMap<UserAppFormField, string>(),
  } as UserAppState,
  reducers: {
    submittingUserAppStarted(state) {
      state.isModalSubmitting = true;
    },
    submittingUserAppCompleted(state) {
      state.isModalSubmitting = false;
      state.newUserAppError = '';
    },
    submittingUserAppFailed(state, action: PayloadAction<string>) {
      state.isModalSubmitting = false;
      state.newUserAppError = action.payload;
    },
    selectUserApp(state, action: PayloadAction<UserApp>) {
      state.selectedUserApp = action.payload;
    },
    showUserAppModal(state, action: PayloadAction<UserAppId|undefined>) {
      const userAppId = action.payload;
      const userApp = userAppId && state.userApps.find(userApp => userApp.userAppId === userAppId);

      if (userApp) {
        state.userAppForModal = userApp;
      } else {
        state.userAppForModal = {} as UserApp;
      }

      state.userAppModalIsVisible = true;
    },
    hideUserAppModal(state) {
      state.userAppModalIsVisible = false;
      delete state.userAppForModal;
    },
    setFormDefaults(state, action: PayloadAction<HashMap<UserAppFormField, string>>) {
      const fields = action.payload;
      
      fields.forEach(([field, value]) => {
        state.userAppForm.set(field, value);
      });
    },
    updateForm(state, action: PayloadAction<{field: UserAppFormField, value: string}>) {
      const { field, value} = action.payload;
      
      state.userAppForm.set(field, value);
      
      const validationErrors = validateTruthyValue(state.userAppForm.ref());

      state.validationErrors = validationErrors;
      state.formIsValid = !validationErrors.length;
    },
    clearForm(state) {
      state.userAppForm.clear();
      state.formIsValid = false;
      state.validationErrors = new Array<UserAppFormField>();
    },
    unsubscribedFromUserApps(state) {
      state.hasSubscribed = false;
      delete state.subscriptionId;
    },
    subscribedToUserApps(state, action: PayloadAction<string>) {
      state.hasSubscribed = true;
      state.subscriptionId = action.payload;
    },
    userAppsReceived(state, action: PayloadAction<UserApp[]>) {
      state.userApps= action.payload;
    },
    userAppsSubscriptionFailed(state, action: PayloadAction<string>) {
      state.hasSubscribed = true;
      state.subscriptionError = action.payload;
    },
  }
});

export default userAppSlice;

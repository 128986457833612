import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HashMap from '../../models/HashMap';
import Preference, { PreferenceIdKey } from '../../models/Preference';

interface PreferencesState {
  preferences: Preference[];
  preferencesModalIsVisible: boolean;
  isProcessing: boolean;
  subscriptionId: string;
  subscriptionError: string;
  hasSubscribedToPreferences: boolean;
  updatingPreferenceIds: PreferenceIdKey[];
  updatingErrors: HashMap<PreferenceIdKey, string>;
  modalFormPreferences: Preference[];
  summaryCardValues: HashMap<PreferenceIdKey, number>;
  summaryCardValueRequestError: string;
  hasRequestedSummaryCardValues: boolean;
}

const preferencesSlice = createSlice({
  name: 'preferences',
  initialState: {
    preferences: new Array<Preference>(),
    modalFormPreferences: new Array<Preference>(),
    updatingPreferenceIds: new Array<PreferenceIdKey>(),
    updatingErrors: new HashMap<PreferenceIdKey, string>(),
    summaryCardValues: new HashMap<PreferenceIdKey, number>(),
  } as PreferencesState,
  reducers: {
    showPreferencesModal(state) {
      state.preferencesModalIsVisible = true;
    },
    hidePreferencesModal(state) {
      state.preferencesModalIsVisible = false;
      state.modalFormPreferences = [...state.preferences];
    },
    unsubscribedToPreferences(state) {
      state.hasSubscribedToPreferences = false;
      delete state.subscriptionId;
    },
    subscribedToPreferences(state, action: PayloadAction<string>) {
      state.hasSubscribedToPreferences = true;
      state.subscriptionId = action.payload;
    },
    preferencesReceived(state, action: PayloadAction<Preference[]>) {
      state.preferences= [...action.payload];
      state.modalFormPreferences = [...action.payload];
    },
    preferencesSubscriptionFailed(state, action: PayloadAction<string>) {
      state.hasSubscribedToPreferences = true;
      state.subscriptionError = action.payload;
    },
    preferenceUpdateRequested(state, action:PayloadAction<PreferenceIdKey>) {
      state.updatingPreferenceIds.push(action.payload);
      state.isProcessing = true;
    },
    preferenceUpdateCompleted(state, action:PayloadAction<PreferenceIdKey>) {
      const itemId = action.payload;
      state.updatingPreferenceIds = state.updatingPreferenceIds.filter(id => id !== itemId);
      state.updatingErrors.delete(itemId);
      state.isProcessing = false;
    },
    preferenceUpdateFailed(state, action:PayloadAction<[PreferenceIdKey,string]>) {
      const [preferenceIdKey, errorMessage] = action.payload;

      state.updatingPreferenceIds = state.updatingPreferenceIds.filter(id => id !== preferenceIdKey);
      state.updatingErrors.set(preferenceIdKey, errorMessage);
      state.isProcessing = false;
    },
    updateModalForm(state, action: PayloadAction<Preference[]>) {
      state.modalFormPreferences = action.payload;
    },
    summaryCardValuesRequested(state) {
      state.isProcessing = true; 
      state.hasRequestedSummaryCardValues = true;
    },
    summaryCardValuesRequestSucceeded(state, action: PayloadAction<HashMap<PreferenceIdKey, number>>) {
      state.isProcessing = false; 
      state.summaryCardValues = action.payload;
      delete state.summaryCardValueRequestError;
    },
    summaryCardValuesRequestFailed(state, action: PayloadAction<string>) {
      state.isProcessing = false; 
      state.summaryCardValueRequestError = action.payload;
    },
  }
});

export default preferencesSlice;

import HashMap from './HashMap';
import { default as FormField } from './ManageExerciseFormField';

interface ExerciseProgramExercise {
    exerciseId: string;
    splitDay: number;
    sets: number;
    reps: number;
    order: number;
}


export const toExerciseProgramExercise = (hm: HashMap<FormField, string>) => ({
    exerciseId: hm.getWithDefault(FormField.exerciseId, ''),
    splitDay: parseInt(hm.getWithDefault(FormField.splitDay, '1')),
    sets: parseInt(hm.getWithDefault(FormField.sets, '1')),
    reps: parseInt(hm.getWithDefault(FormField.reps, '1')),
    order: parseInt(hm.getWithDefault(FormField.order, '1')),
} as ExerciseProgramExercise);



export default ExerciseProgramExercise;
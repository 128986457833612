import firebase from 'firebase';
import { v4 as createUUID } from 'uuid';
import HashMap from '../models/HashMap';
import BlogPageFormField from '../models/BlogPageFormField.enum';
import BlogPage from '../models/BlogPage';
import SessionData from '../models/SessionData';
import { getAppDoc } from './Helpers';

type blogPagesUpdatedHandler = (blogPages: BlogPage[]) => void;

const SUBSCRIPTION_CACHE: Map<string, () => void> = new Map();

export const BLOG_PAGES_PATH= 'blogPages';

const blogPagesAPI = {
  postNewBlogPage: async (session: SessionData, form: HashMap<BlogPageFormField, string>) => {
    const appDoc = getAppDoc(session);
    const blogPagesCollection = appDoc.collection(BLOG_PAGES_PATH);

    return blogPagesCollection.add({
      header: form.getWithDefault(BlogPageFormField.header, ''),
      subheader: form.getWithDefault(BlogPageFormField.subheader, ''),
      content: form.getWithDefault(BlogPageFormField.content, ''),
      blogPageState: form.getWithDefault(BlogPageFormField.blogPageState, ''),
      created: (new Date()).toString(),
      active: true,
    }).then();
  },

  subscribeToBlogPages: async (session: SessionData, blogPagesUpdated: blogPagesUpdatedHandler) => {
    const appDoc = getAppDoc(session);
    const blogPagesCollection = appDoc.collection(BLOG_PAGES_PATH);

    const unsubscribe = blogPagesCollection.onSnapshot((snapshot) => {
      let storeBlogPages: BlogPage[] = [];

      snapshot.forEach((doc) => {
        const data = doc.data();
        const blogPage: BlogPage = {
          blogPageId: doc.id as string,
          header: data.header,
          subheader: data.subheader,
          blogPageState: data.blogPageState,
          content: data.content,
          created: data.created,
          updated: data.updated,
        };

        storeBlogPages = [...storeBlogPages, blogPage];
      });

      blogPagesUpdated(storeBlogPages);
    });
    
    const subscriptionUUID = createUUID();

    SUBSCRIPTION_CACHE.set(subscriptionUUID, unsubscribe);

    return subscriptionUUID;
  },

  unsubscribeFromBlogPages: (subscriptionId: string) => {
    if (SUBSCRIPTION_CACHE.has(subscriptionId)) {
      const unsubscribe = SUBSCRIPTION_CACHE.get(subscriptionId);
      unsubscribe && unsubscribe();
      SUBSCRIPTION_CACHE.delete(subscriptionId);
    }
  },

  updateBlogPage: async (session: SessionData, form: HashMap<BlogPageFormField, string>) => {
    const blogPageId = form.get(BlogPageFormField.blogPageId) as string;

    const appDoc = getAppDoc(session);
    const blogPagesCollection = appDoc.collection(BLOG_PAGES_PATH);
    const blogPageDoc = blogPagesCollection.doc(blogPageId);
    
    return blogPageDoc.update({
      [BlogPageFormField.header]: form.getWithDefault(BlogPageFormField.header, ''),
      [BlogPageFormField.subheader]: form.getWithDefault(BlogPageFormField.subheader, ''),
      [BlogPageFormField.content]: form.getWithDefault(BlogPageFormField.content, ''),
      [BlogPageFormField.blogPageState]: form.getWithDefault(BlogPageFormField.blogPageState, ''),
      updated: (new Date()).toString(),
    }).then();
  },
};

export default blogPagesAPI;

import firebase from 'firebase';
import Item from '../models/Item';
import { v4 as createUUID } from 'uuid';
import FormField from '../models/NewInventoryFormField.enum';
import InventoryItemField from '../models/InventoryItemField.enum';
import HashMap from '../models/HashMap';
import SessionData from '../models/SessionData';
import { getAppDoc } from './Helpers';

export const INVENTORY_PATH = 'inventory';

type SubscriptionId = string;

type itemsUpdatedHandler = (items: Item[]) => void;

const SUBSCRIPTION_CACHE: Map<string, () => void> = new Map();

const itemsAPI = {
  subscribeToInventory: async (session: SessionData, itemsUpdated: itemsUpdatedHandler) => {
    const appDoc = getAppDoc(session);
    const inventoryCollection = appDoc.collection(INVENTORY_PATH);
    const query = inventoryCollection.where('active', '==', true);

    const unsubscribe = query.onSnapshot((snapshot) => {
      let storeItems: Item[] = [];
      snapshot.forEach((doc) => {
        const item: Item = {
          itemId: doc.id as string,
          ...doc.data() as any,
        };
        storeItems = [...storeItems, item];
      });
      itemsUpdated(storeItems);
    });

    const subscriptionUUID = createUUID();

    SUBSCRIPTION_CACHE.set(subscriptionUUID, unsubscribe);

    return subscriptionUUID;
  },

  unsubscribeFromInventory: (subscriptionId: string) => {
    if (SUBSCRIPTION_CACHE.has(subscriptionId)) {
      const unsubscribe = SUBSCRIPTION_CACHE.get(subscriptionId);
      unsubscribe && unsubscribe();
      SUBSCRIPTION_CACHE.delete(subscriptionId);
    }
  },

  postNewInventory: async (session: SessionData, form: HashMap<FormField, string | File>, imageUrl: string) => {
    const itemName = form.get(FormField.itemName) as string;
    const SKU = form.get(FormField.SKU) as string;
    const category = form.get(FormField.category) as string;
    const quantity = form.get(FormField.quantity) as string;
    const price = form.get(FormField.price) as string;
    const description = form.get(FormField.description) as string;
    const shippingCost = form.get(FormField.shippingCost) as string;
    const shippingDateModifierDays = form.get(FormField.shippingDateModifierDays) as string;
    const appDoc = getAppDoc(session);
    const inventoryCollection = appDoc.collection(INVENTORY_PATH);

    return inventoryCollection.add({
      itemName,
      quantity,
      price,
      description,
      category,
      SKU,
      imageSrc: imageUrl,
      shippingCost,
      shippingDateModifierDays,
      created: (new Date()).toString(),
      active: true,
      deleted: false,
    }).then();
  },

  updateInventoryItem: async (session: SessionData, form: HashMap<InventoryItemField, string | File>) => {
    const itemId = form.get(InventoryItemField.itemId) as string;
    const quantity = form.get(InventoryItemField.quantity) as string;
    const price = form.get(InventoryItemField.price) as string;
    const description = form.get(InventoryItemField.description) as string;
    const shippingCost = form.get(InventoryItemField.shippingCost) as string;
    const shippingDateModifierDays = form.get(InventoryItemField.shippingDateModifierDays) as string;

    const appDoc = getAppDoc(session);
    const inventoryCollection = appDoc.collection(INVENTORY_PATH);
    const inventoryItemDoc = inventoryCollection.doc(itemId);

    return inventoryItemDoc.update({
      quantity,
      price,
      description,
      shippingCost,
      shippingDateModifierDays,
      updated: (new Date()).toString(),
    }).then();
  },

  deleteInventoryItem: async (session: SessionData, itemId: string) => {
    const appDoc = getAppDoc(session);
    const inventoryCollection = appDoc.collection(INVENTORY_PATH);
    const inventoryItemDoc = inventoryCollection.doc(itemId);

    return inventoryItemDoc.update({
      updated: (new Date()).toString(),
      active: false,
    }).then();
  },

  getOutOfStockCount: async (session: SessionData) => {
    const appDoc = getAppDoc(session);
    const inventoryCollection = appDoc.collection(INVENTORY_PATH);
    const query = inventoryCollection.where('active', '==', true).where('quantity', '==', '0');

    const snapshot = await query.get();

    return snapshot.size;
  },
};

export default itemsAPI;

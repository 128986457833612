import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSession from '../../custom_hooks/useSession';
import { clientsStateSelector } from './clients-selector';
import { subscribeToClients, unsubscribeToClients } from './clients-thunks';

const useClients = function useClients() {
    const dispatch = useDispatch();
    const {
        clients,
        hasSubscribed,
        subscriptionId,
    } = useSelector(clientsStateSelector);
    const session = useSession();
    
    useEffect(() => {
      if (!hasSubscribed) {
        if (session.userId) {
          dispatch(subscribeToClients(session));
        } else {
          return () => {
            if (subscriptionId) {
              dispatch(unsubscribeToClients(subscriptionId));
            }
          }
        }
      }
    }, [session, hasSubscribed]); 
    
    useEffect(() => {
      if (subscriptionId) {
        dispatch(unsubscribeToClients(subscriptionId));
      }
    }, [session.selectedExerciseProgramId]);
    
    return {
        clients,
        hasSubscribed,
    };
}

export default useClients;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HashMap from '../../models/HashMap';
import ContactUsRequest, { ContactUsRequestFormField as FormField } from '../../models/ContactUsRequest';
import validateTruthyValue from '../../helpers/validate-truthy-value';

type ContactUsRequestId = string;
  
interface ContactUsRequestsState {
  selectContactRequestForm: HashMap<FormField, string>;
  formIsValid: boolean;
  validationErrors: string[];
  isProcessing: boolean;
  contactUsRequests: ContactUsRequest[];
  hasSubscribed: boolean;
  subscriptionId?: string;
  subscriptionError: string;
  updatingContactUsRequestIds: ContactUsRequestId[];
  updatingErrors: HashMap<ContactUsRequestId, string>;
  selectedContactUsRequest?: ContactUsRequest;
  detailsModalIsVisible: boolean;
}

const contactUsRequestsSlice = createSlice({
  name: 'contactUsRequests',
  initialState: {
    selectContactRequestForm: new HashMap<FormField, string>(),
    validationErrors: new Array<string>(),
    isProcessing: false,
    hasSubscribed: false,
    contactUsRequests:[] as ContactUsRequest[],
    updatingContactUsRequestIds: [] as ContactUsRequestId[],
    updatingErrors: new HashMap<ContactUsRequestId, string>(),
    detailsModalIsVisible: false,
  } as ContactUsRequestsState,
  reducers: {
    showDetailsModal(state, action: PayloadAction<ContactUsRequestId>) {
      const selectedOrder = state.contactUsRequests.find(contactUsRequest => contactUsRequest.contactUsRequestId === action.payload);
      if (selectedOrder) {
        state.detailsModalIsVisible = true;
        state.selectedContactUsRequest = selectedOrder;
      }
    },
    hideDetailsModal(state) {
      state.detailsModalIsVisible = false;
      delete state.selectedContactUsRequest;
    },
    setFormDefaults(state, action: PayloadAction<HashMap<FormField, string>>) {
      const fields = action.payload;
      
      fields.forEach(([field, value]) => {
        state.selectContactRequestForm.set(field, value);
      });
    },
    updateForm(state, action: PayloadAction<{field: FormField, value: string}>) {
      const { field, value} = action.payload;
      
      state.selectContactRequestForm.set(field, value);
      
      const validationErrors = validateTruthyValue(state.selectContactRequestForm.ref());

      state.validationErrors = validationErrors;
      state.formIsValid = !validationErrors.length;
    },
    clearForm(state) {
      state.selectContactRequestForm.clear();
      state.formIsValid = false;
      state.validationErrors = new Array<FormField>();
    },
    unsubscribedToContactUsRequests(state) {
      state.hasSubscribed = false;
      delete state.subscriptionId;
    },
    subscribedToContactUsRequests(state, action: PayloadAction<string>) {
      state.hasSubscribed = true;
      state.subscriptionId = action.payload;
    },
    contactUsRequestsReceived(state, action: PayloadAction<ContactUsRequest[]>) {
      state.contactUsRequests = action.payload;
    },
    subscriptionFailed(state, action: PayloadAction<string>) {
      state.hasSubscribed= true;
      state.subscriptionError = action.payload;
    },
    contactUsRequestUpdateRequested(state, action:PayloadAction<ContactUsRequestId>) {
      state.updatingContactUsRequestIds.push(action.payload);
      state.isProcessing = true;
    },
    contactUsRequestUpdateCompleted(state, action:PayloadAction<ContactUsRequestId>) {
      const itemId = action.payload;
      state.updatingContactUsRequestIds = state.updatingContactUsRequestIds.filter(id => id !== itemId);
      state.updatingErrors.delete(itemId);
      state.isProcessing = false;
    },
    contactUsRequestUpdateFailed(state, action:PayloadAction<[ContactUsRequestId, string]>) {
      const [itemId, errorMessage] = action.payload;

      state.updatingContactUsRequestIds = state.updatingContactUsRequestIds.filter(id => id !== itemId);
      state.updatingErrors.set(itemId, errorMessage);
      state.isProcessing = false;
    },
  }
});

export default contactUsRequestsSlice;

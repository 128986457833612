import SummaryCard from '../../models/SummaryCard';
import { PreferenceIdKey } from '../../models/Preference';

const UnhandledContactRequests = {
  description: 'Number of unhandled contact requests',
  headerText: 'Contact Requests',
  key: PreferenceIdKey.UNHANDLED_CONTACT_REQUESTS,
  link: '/contact-us-requests'
} as SummaryCard;

export default UnhandledContactRequests;
import api from '../../api';
import { AppThunk } from '../index';
import authSlice from './auth-slice';
import history from '../../routes/history';

export const signIn = (email: string, password: string): AppThunk => async(dispatch) => {
  const {
    startLoading,
    stopLoading,
    signInHasFailed,
  } = authSlice.actions;

  dispatch(startLoading());
  
  try {
    await api.auth.signIn(email, password);
  } catch(e) {
    dispatch(signInHasFailed(e.message));
  }
  dispatch(stopLoading());
};

export const subscribeToSignIn = (): AppThunk => async(dispatch) => {
  const {
    errorWatching,
    signInSucceeded,
    watchingStarted,
    watchingRequested,
  } = authSlice.actions;

  dispatch(watchingRequested());

  try {
    api.auth.subscribeToAuthStateChange((user) => {
      dispatch(watchingStarted());
      if (user) {
        dispatch(signInSucceeded(user));
      }
    });
  } catch(e) {
    dispatch(errorWatching(e.message));
  }

};

export const signOut = (): AppThunk => async(dispatch) => {
  const {
    signOutSucceeded,
  } = authSlice.actions;

  await api.auth.signOut();

  dispatch(signOutSucceeded());
};

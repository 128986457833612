import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './exercises.module.scss';
import { exercisesSelector } from '../../store/exercises/exercises-selector';
import { subscribeToExercises, unsubscribeToExercises } from '../../store/exercises/exercises-thunks';
import useSession from '../../custom_hooks/useSession';
import ExercisesContainer from '../../components/ExercisesContainer/ExercisesContainer';

const Exercises = () => {
  const dispatch = useDispatch();
  const {
    exercises,
    hasSubscribed,
    subscriptionId,
  } = useSelector(exercisesSelector);
  const session = useSession();
  
  useEffect(() => {
    if (!hasSubscribed) {
      if (session.userId) {
        dispatch(subscribeToExercises(session));
      } else {
        return () => {
          if (subscriptionId) {
            dispatch(unsubscribeToExercises(subscriptionId));
          }
        }
      }
    }
  }, [session, hasSubscribed]); 
  
  useEffect(() => {
    if (subscriptionId) {
      dispatch(unsubscribeToExercises(subscriptionId));
    }
  }, [session.selectedExerciseProgramId]);
  
  return (
    <div className={classNames("pure-g", styles.inventory)}>
    	<div className={classNames("pure-u-1", styles.cardContainer)}>
          <ExercisesContainer exercises={exercises} isLoading={!hasSubscribed} />
    	</div>
    </div>
  );
};

export default Exercises;

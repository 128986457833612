import firebase from 'firebase';
import SessionData from '../models/SessionData';
import UserSettings from '../models/UserSettings';

const settingsAPI = {
  getSettings: async (session: SessionData) => {
    // const getSettings = firebase.functions().httpsCallable('getSettings');
    
    // const response = await getSettings({
    //   userAppId: session.selectedUs
    // });
    // const userSettings = response.data as any as UserSettings;
    
    // return userSettings;
    return {} as UserSettings;
  },
  postSettings: async (userSettings: UserSettings) => {
    const saveSettings = firebase.functions().httpsCallable('saveSettings');
    await saveSettings(userSettings);
  },
}

export default settingsAPI;
import SummaryCard from '../../models/SummaryCard';
import InventoryOutOfStock from './InventoryOutOfStock';
import UnhandledContactRequests from './UnhandledContactRequests';
import UnhandledOrders from './UnhandledOrders';
import { PreferenceIdKey } from '../../models/Preference';

const summaryCardMap = new Map<PreferenceIdKey, SummaryCard>();
summaryCardMap.set(PreferenceIdKey.INVENTORY_OUT_OF_STOCK, InventoryOutOfStock);
summaryCardMap.set(PreferenceIdKey.UNHANDLED_CONTACT_REQUESTS, UnhandledContactRequests);
summaryCardMap.set(PreferenceIdKey.UNHANDLED_ORDERS, UnhandledOrders);

export default summaryCardMap;
import api from '../../api';
import { AppThunk } from '../index';
import epcSlice from './exercise-program-exercises-slice';
import SessionData from '../../models/SessionData';
import ExerciseProgramExercise from '../../models/ExerciseProgramExercise';
import { showError, showSuccess } from '../../alert/alert';


export const subscribeToExerciseProgramExercises = (session: SessionData): AppThunk => async(dispatch) => {
  const {
    subscribedToExerciseProgramExercises,
    exerciseProgramExercisesReceived,
    exerciseProgramExercisesSubscriptionFailed,
  } = epcSlice.actions;

  try {
    const subscriptionId = await api.exerciseProgramExercises.subscribeToExercises(session, (items) => {
      dispatch(exerciseProgramExercisesReceived(items));
    });
    dispatch(subscribedToExerciseProgramExercises(subscriptionId));
  } catch(e) {
    dispatch(exerciseProgramExercisesSubscriptionFailed(e.message));
  }
}
      
export const unsubscribeToExerciseProgramExercises = (subscriptionId: string): AppThunk => async(dispatch) => {
  const {
    unsubscribedToExerciseProgramExercises,
  } = epcSlice.actions;

  await api.exercises.unsubscribeFromExercise(subscriptionId);
  
  dispatch(unsubscribedToExerciseProgramExercises());
}

export const addExerciseProgramExercise = (session: SessionData, exercise: ExerciseProgramExercise): AppThunk => async(dispatch) => {
  const {
    exerciseProgramExerciseUpdateRequested,
    exerciseProgramExerciseUpdateCompleted,
    exerciseProgramExerciseUpdateFailed
  } = epcSlice.actions;
  
  dispatch(exerciseProgramExerciseUpdateRequested(exercise.exerciseId));
  
  try {
    await api.exerciseProgramExercises.addExercise(session, exercise);

    dispatch(exerciseProgramExerciseUpdateCompleted(exercise.exerciseId));
  } catch(e) {
    dispatch(exerciseProgramExerciseUpdateFailed([exercise.exerciseId, e.message]));
  }
};

export const removeExerciseProgramExercise = (session: SessionData, exercise: ExerciseProgramExercise): AppThunk => async(dispatch) => {
  const {
    exerciseProgramExerciseUpdateRequested,
    exerciseProgramExerciseUpdateCompleted,
    exerciseProgramExerciseUpdateFailed,
  } = epcSlice.actions;
  
  dispatch(exerciseProgramExerciseUpdateRequested(exercise.exerciseId));
  
  try {
    await api.exerciseProgramExercises.removeExercise(session, exercise);

    dispatch(exerciseProgramExerciseUpdateCompleted(exercise.exerciseId));
  } catch(e) {
    dispatch(exerciseProgramExerciseUpdateFailed([exercise.exerciseId, e.message]));
  }
};

export const updateExerciseProgramExercise = (session: SessionData, exercise: ExerciseProgramExercise): AppThunk => async(dispatch) => {
  const {
    exerciseProgramExerciseUpdateRequested,
    exerciseProgramExerciseUpdateCompleted,
    exerciseProgramExerciseUpdateFailed,
  } = epcSlice.actions;
  
  dispatch(exerciseProgramExerciseUpdateRequested(exercise.exerciseId));
  
  try {
    await api.exerciseProgramExercises.updateExercise(session, exercise);
    
    showSuccess('Exercise Updated');

    dispatch(exerciseProgramExerciseUpdateCompleted(exercise.exerciseId));
  } catch(e) {
    showError('Error saving exercise');
    dispatch(exerciseProgramExerciseUpdateFailed([exercise.exerciseId, e.message]));
  }
};
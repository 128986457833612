import React, { ReactEventHandler } from 'react';
import { useDispatch } from 'react-redux';
import Client from '../../models/Client';
import styles from './exercise-program-clients-container.module.scss';
import { addExerciseProgramClient, removeExerciseProgramClient } from '../../store/exercisesProgramClients/exercise-program-clients-thunks';
import useSession from '../../custom_hooks/useSession';

interface ClientsTableRowProps {
  client: Client;
  index: number;
  isEPC: boolean;
  isProcessing: boolean;
}

const ClientsTableRow = ({
  client,
  index,
  isEPC,
  isProcessing
}: ClientsTableRowProps) => {
  const dispatch = useDispatch();
  const session = useSession();
  
  const handleSelect = (ev: React.MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    
    if (isEPC) {
      dispatch(removeExerciseProgramClient(session, client));
    } else {
      dispatch(addExerciseProgramClient(session, client));
    }
  };

  return (
    <tr className={index % 2 === 0 ? '' : 'pure-table-odd'} onClick={handleSelect}>
      <td className={styles.tdClientName}>{client.clientName}</td>
      <td className={styles.tdEmail}>{client.email}</td>
      <td className={styles.tdContent}>
        {client.clientState}
      </td>
      <td onClick={handleSelect} className={styles.tdCheckbox}>
        <input type="checkbox" checked={isEPC} readOnly />
      </td>
    </tr>
  );
};

export default ClientsTableRow;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HashMap from '../../models/HashMap';
import Order from '../../models/Order';
import OrderField from '../../models/OrderField.enum';
import validateTruthyValue from '../../helpers/validate-truthy-value';

type OrderId = string;
  
interface OrdersState {
  orderForm: HashMap<OrderField, string>;
  orderFormIsValid: boolean;
  validationErrors: string[];
  isProcessing: boolean;
  orders: Order[];
  hasSubscribedToOrders: boolean;
  subscriptionId?: string;
  subscriptionError: string;
  updatingOrderIds: OrderId[];
  updatingErrors: HashMap<OrderId, string>;
  selectedOrder?: Order;
  orderDetailsModalIsVisible: boolean;
}

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    orderForm: new HashMap<OrderField, string>(),
    validationErrors: new Array<string>(),
    isProcessing: false,
    hasSubscribedToOrders: false,
    orders:[] as Order[],
    updatingOrderIds: [] as OrderId[],
    updatingErrors: new HashMap<OrderId, string>(),
    orderDetailsModalIsVisible: false,
  } as OrdersState,
  reducers: {
    showOrderDetailsModal(state, action: PayloadAction<OrderId>) {
      const selectedOrder = state.orders.find(order => order.orderId === action.payload);
      if (selectedOrder) {
        state.orderDetailsModalIsVisible = true;
        state.selectedOrder = selectedOrder;
      }
    },
    hideOrderDetailsModal(state) {
      state.orderDetailsModalIsVisible = false;
      delete state.selectedOrder;
    },
    setFormDefaults(state, action: PayloadAction<HashMap<OrderField, string>>) {
      const fields = action.payload;
      
      fields.forEach(([field, value]) => {
        state.orderForm.set(field, value);
      });
    },
    updateForm(state, action: PayloadAction<{field: OrderField, value: string}>) {
      const { field, value} = action.payload;
      
      state.orderForm.set(field, value);
      
      const validationErrors = validateTruthyValue(state.orderForm.ref());

      state.validationErrors = validationErrors;
      state.orderFormIsValid = !validationErrors.length;
    },
    clearForm(state) {
      state.orderForm.clear();
      state.orderFormIsValid = false;
      state.validationErrors = new Array<OrderField>();
    },
    unsubscribedToOrders(state) {
      state.hasSubscribedToOrders = false;
      delete state.subscriptionId;
    },
    subscribedToOrders(state, action: PayloadAction<string>) {
      state.hasSubscribedToOrders = true;
      state.subscriptionId = action.payload;
    },
    ordersReceived(state, action: PayloadAction<Order[]>) {
      state.orders= action.payload;
    },
    ordersSubscriptionFailed(state, action: PayloadAction<string>) {
      state.hasSubscribedToOrders = true;
      state.subscriptionError = action.payload;
    },
    orderUpdateRequested(state, action:PayloadAction<OrderId>) {
      state.updatingOrderIds.push(action.payload);
      state.isProcessing = true;
    },
    orderUpdateCompleted(state, action:PayloadAction<OrderId>) {
      const itemId = action.payload;
      state.updatingOrderIds = state.updatingOrderIds.filter(id => id !== itemId);
      state.updatingErrors.delete(itemId);
      state.isProcessing = false;
    },
    orderUpdateFailed(state, action:PayloadAction<[OrderId,string]>) {
      const [itemId, errorMessage] = action.payload;

      state.updatingOrderIds = state.updatingOrderIds.filter(id => id !== itemId);
      state.updatingErrors.set(itemId, errorMessage);
      state.isProcessing = false;
    },
  }
});

export default orderSlice;

import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import LoadingWithOverlay from '../LoadingWithOverlay/LoadingWithOverlay';
import styles from './messages.module.scss';
import Message from '../../models/Message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compareDesc, format } from 'date-fns';
import useSession from '../../custom_hooks/useSession';
import { useDispatch } from 'react-redux';
import useMessages from '../../store/messages/hooks/useMessages';
import Client from '../../models/Client';
import { addMessage } from '../../store/messages/messages-actions';

interface MessagesProps {
    client: Client;
}

const Messages = ({ client }: MessagesProps) => {
    const dispatch = useDispatch();
    const [userMessage, setUserMessage] = useState('');
    const { userId } = useSession();
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const session = useSession();
    
    const {
        messages = [],
        isLoading,
    } = useMessages(client.email);
    
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
      scrollToBottom()
    }, [messages]);

    const displayMessages = messages
    .filter((message) => {
        if (userId) {
            return message.trainerId === userId;
        }
        return true;
    })
    .map(event => ({
        ...event,
        messageDate: new Date(event.timestamp),
    })).sort((a, b) => compareDesc(b.messageDate, a.messageDate));
    
    const handleMessageChange = (ev: React.FormEvent<HTMLTextAreaElement>) => {
        ev.preventDefault();
        setUserMessage(ev.currentTarget.value);
    }
    
    const handleSendMessage = () => {
        dispatch(addMessage(session, client.email, userMessage));
        setUserMessage('');
    };

    return (<>
    { isLoading && <LoadingWithOverlay contained={false} />}
    <div className={styles.container}>
        <section className={classNames('pure-g', styles.bottomSection)}>
            <dl className={classNames('pure-u-1', styles.datalist)}>
                {displayMessages.map((message) => (<React.Fragment key={message.messageId}>
                    <dt  className={classNames({ [styles.userMessage]: (message.isUserMessage)})}>
                        { !message.isUserMessage && (
                            <span className={styles.logIcon}>
                                <FontAwesomeIcon icon={['fas', 'circle']} size="sm" />
                            </span>
                        )}
                        {message.message}
                        { message.isUserMessage && (
                            <span className={styles.userLogIcon}>
                                <FontAwesomeIcon icon={['fas', 'circle']} size="sm" />
                            </span>
                        )}
                    </dt>
                    <dd className={classNames({ [styles.userMessage]: (message.isUserMessage)})}>
                        <span className={styles.date}>{format(message.messageDate, 'dd MMM yyyy')} at {format(message.messageDate, 'h:m a')}</span>
                    </dd>
                </React.Fragment>))}
                <div ref={messagesEndRef} />
            </dl>
            <div className={classNames(styles.messageBoxContainer, 'pure-form')}>
                <textarea placeholder="Type your message here"
                    value={userMessage}
                    onChange={handleMessageChange}
                >
                </textarea>
                <div className={classNames(styles.messageButtonContainer)}>
                    <button className="pure-button pure-button-primary" onClick={handleSendMessage}>Send</button>
                </div>
            </div>
        </section>
    </div>
    </>);
};

export default Messages;
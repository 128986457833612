import api from '../../api';
import { AppThunk } from '../index';
import contactUsRequestsSlice from './contact-us-requests-slice';
import { ContactUsRequestFormField as FormField } from '../../models/ContactUsRequest';
import HashMap from '../../models/HashMap';
import SessionData from '../../models/SessionData';

export const subscribeToContactUsRequests = (session: SessionData): AppThunk => async(dispatch) => {
  const {
    subscribedToContactUsRequests,
    contactUsRequestsReceived,
    subscriptionFailed
  } = contactUsRequestsSlice.actions;

  try {
    const subscriptionId = await api.contactUs.subscribeToContactUsRequests(session, (contactUsRequests) => {
      dispatch(contactUsRequestsReceived(contactUsRequests));
    });
    dispatch(subscribedToContactUsRequests(subscriptionId));
  } catch(e) {
    dispatch(subscriptionFailed(e.message));
  }
}
      
export const unsubscribeToContactUsRequests = (subscriptionId: string): AppThunk => async(dispatch) => {
  const {
    unsubscribedToContactUsRequests,
  } = contactUsRequestsSlice.actions;

  api.inventory.unsubscribeFromInventory(subscriptionId);
  
  dispatch(unsubscribedToContactUsRequests());
}

export const updateContactUsRequest = (session: SessionData, form: HashMap<FormField, string>): AppThunk => async(dispatch) => {
  const {
    contactUsRequestUpdateRequested,
    contactUsRequestUpdateCompleted,
    contactUsRequestUpdateFailed,
  } = contactUsRequestsSlice.actions;
  
  const contactUsRequestId = form.getWithDefault(FormField.contactUsRequestId, '');

  dispatch(contactUsRequestUpdateRequested(contactUsRequestId));
  
  try {
    await api.contactUs.updateContactUsRequest(session, form);

    dispatch(contactUsRequestUpdateCompleted(contactUsRequestId));
  } catch(e) {
    dispatch(contactUsRequestUpdateFailed([contactUsRequestId, e.message]));
  }
};
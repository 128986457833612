import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import Exercise from '../../models/Exercise';
import styles from './exercises-container.module.scss';
import { addExerciseProgramExercise, removeExerciseProgramExercise, updateExerciseProgramExercise } from '../../store/exerciseProgramExercises/exercise-program-exercises-thunks';
import useSession from '../../custom_hooks/useSession';
import debounce from '../../helpers/debounce';
import ExerciseProgramExercise from '../../models/ExerciseProgramExercise';
import epeSlice from '../../store/exerciseProgramExercises/exercise-program-exercises-slice';

interface ExercisesTableRowProps {
  exercise: Exercise;
  index: number;
  isSelectedExercise: boolean;
  exerciseProgramExercise?: ExerciseProgramExercise;
}

const defaultEpExercise = {
  splitDay: 1,
  sets: 1,
  reps: 1,
  order: 1,
} as ExerciseProgramExercise;

const ExercisesTableRow = ({
  exercise,
  index,
  isSelectedExercise,
  exerciseProgramExercise: epExercise,
}: ExercisesTableRowProps) => {
  const dispatch = useDispatch();
  const session = useSession();
  
  const handleSelect = (ev: React.MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    
    if (isSelectedExercise) {
      dispatch(removeExerciseProgramExercise(session, {
        ...exercise,
        ...(epExercise || defaultEpExercise),
      }));
    } else {
      dispatch(addExerciseProgramExercise(session, {
        ...exercise,
        ...(epExercise || defaultEpExercise),
      }));
    }
  };

  const handleThumbnailClick = (ev: React.MouseEvent<HTMLAnchorElement | HTMLTableCellElement>) => {
    ev.stopPropagation();
    return false;
  };
  
  const thumbnail = exercise.mediaSources.find((src) => src.isThumbnail);
  

  const handleManageClick = () => {
    if (epExercise) {
      dispatch(epeSlice.actions.openManageExerciseModal(epExercise));
    }
  };

  return (
    <tr className={index % 2 === 0 ? '' : 'pure-table-odd'} >
      <td className={styles.tdExerciseName}>{exercise.exerciseName}</td>
      <td className={styles.tdEmail}>{exercise.category}</td>
      <td className={styles.tdContent}>
        {exercise.description}
      </td>
      <td colSpan={2} onClick={handleThumbnailClick}>
        <a href={thumbnail?.mediaSourceUrl} target="_blank" onClick={handleThumbnailClick}>
          {thumbnail?.mediaSourceFileName}
        </a>
      </td>
      <td className={styles.tdRange}>
        {epExercise?.splitDay}
      </td>
      <td className={styles.tdRange}>
        {epExercise?.order}
      </td>
      <td className={styles.tdRange}>
        {epExercise?.sets}
      </td>
      <td className={styles.tdRange}>
        {epExercise?.reps}
      </td>
      <td onClick={handleSelect} className={styles.tdCheckbox}>
        <input type="checkbox" checked={isSelectedExercise} readOnly />
      </td>
      <td>
        {isSelectedExercise && (
          <button className={classNames('pure-button pure-button-primary')}
            onClick={handleManageClick}
          >
            Manage
          </button>
        )}
      </td>
    </tr>
  );
};

export default ExercisesTableRow;

import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import Modal from '../../components/Modal/Modal';
import { selectedClientSelector, clientFormSelector } from '../../store/clients/clients-selector';
import { updateClient, postNewClient } from '../../store/clients/clients-thunks';
import styles from './client-modal.module.scss';
import ClientModalBody from './components/ClientModalBody';
import clientSlice from '../../store/clients/clients-slice';
import useSession from '../../custom_hooks/useSession';

const ClientModal = () => {
  const dispatch = useDispatch();
  const client = useSelector(selectedClientSelector);
  const { form }= useSelector(clientFormSelector);
  const session = useSession();
  
  const { hideClientModal } = clientSlice.actions;
  
  const renderHeader = () => <h2>Client</h2>;
  
  const renderBody = () => <ClientModalBody />;
  
  const handleClose = () => {
    dispatch(hideClientModal());
  };
  
  const handleSubmit = () => {
    if (session) {
      if (client?.clientId) {
        dispatch(updateClient(session, form));
      } else {
        dispatch(postNewClient(session, form));
      }
    }
  };

  const renderActions = () => (
    <div className={styles.actionsContainer}>
      <button type="button"
        className={classNames("pure-button", styles.cancelButton)} 
        onClick={handleClose}>
        Close
      </button>
      
      <button type="button"
        className={classNames("pure-button", styles.submitButton)} 
        onClick={handleSubmit}>
        Save 
      </button>
    </div>
  );
  
  return (
    <Modal
      onClose={handleClose}
      renderHeader={renderHeader}
      renderBody={renderBody}
      renderActions={renderActions}
    />
  );
};

export default ClientModal;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingWithOverlay from '../../../components/LoadingWithOverlay/LoadingWithOverlay';
import styles from '../edit-exercise-modal.module.scss';
import { default as FormField } from '../../../models/NewExerciseFormField.enum';
import exercisesSlice from '../../../store/exercises/exercises-slice';
import { editExerciseFormSelector } from '../../../store/exercises/exercises-selector';
import HashMap from '../../../models/HashMap';
import useSession from '../../../custom_hooks/useSession';
import { ExerciseUnit } from '../../../models/Exercise';
import classNames from 'classnames';

interface NewExerciseModalBodyProps {
  isLoading: boolean,
}

const NewExerciseModalBody = ({ isLoading }: NewExerciseModalBodyProps) => {
  const dispatch = useDispatch();
  const {
    fields,
    validationErrors,
    selectedExercise,
  } = useSelector(editExerciseFormSelector);
  const session = useSession();

  const { updateEditForm, setEditFormDefaults } = exercisesSlice.actions;

  useEffect(() => {
    const formDefaults = new HashMap<FormField, string>();
    formDefaults.set(FormField.exerciseId, fields.get(FormField.exerciseId) || selectedExercise?.exerciseId || '');
    formDefaults.set(FormField.category, fields.get(FormField.category) || selectedExercise?.category || '');
    formDefaults.set(FormField.description, fields.get(FormField.description) || selectedExercise?.description || '');
    formDefaults.set(FormField.exerciseName, fields.get(FormField.exerciseName) || selectedExercise?.exerciseName || '');
    formDefaults.set(FormField.unit, fields.get(FormField.unit) || selectedExercise?.unit || '');
    dispatch(setEditFormDefaults(formDefaults));
  }, [])
  
  const isSaving = false;
  
  const handleOnChange =  (field: FormField) => (ev: React.FormEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>) => {
    ev.preventDefault();
    const value = ev.currentTarget.value;
    
    dispatch(updateEditForm({ field, value}));
  };
  
  return (
    <form id="new-exercise-form" className="pure-form pure-form-aligned">
      { isLoading && <LoadingWithOverlay contained={false}/>}
      <fieldset>
        <div className="pure-control-group">
          <label htmlFor="item-name">Item Name</label>
          <input id="exercise-name" type="text" name="exerciseName" placeholder="Exercise Name"
                 required value={fields.getWithDefault(FormField.exerciseName, "")}
                 onChange={handleOnChange(FormField.exerciseName)}
          />
           { validationErrors.indexOf(FormField.exerciseName) > -1 &&
              (<span className="pure-form-message validation-message">
                Exercise name is required.
              </span>)
           }
        </div>
        <div className="pure-control-group">
          <label htmlFor="unit">Unit</label>
          <select id="unit" name="unit"
            value={fields.getWithDefault(FormField.unit, ExerciseUnit.lbs)}
            onChange={handleOnChange(FormField.unit)}
            required
          >
            <option value={ExerciseUnit.none}>none</option>
            <option value={ExerciseUnit.lbs}>lbs</option>
            <option value={ExerciseUnit.minutes}>minutes</option>
            <option value={ExerciseUnit.seconds}>seconds</option>
          </select>
          { validationErrors.indexOf(FormField.unit) > -1 &&
            <span className="pure-form-message validation-message">
              Unit name is required.
            </span>
          }
        </div>
        <div className="pure-control-group">
          <label htmlFor="category">Category</label>
          <input id="category" type="text" name="category" placeholder="Category"
                 required value={fields.getWithDefault(FormField.category, "")} 
                 onChange={handleOnChange(FormField.category)}
           />
          { validationErrors.indexOf(FormField.category) > -1 &&
            <span className="pure-form-message validation-message">
              Category name is required.
            </span>
          }
        </div>
        <div className={classNames("pure-control-group", styles.descriptionContainer)}>
          <label className={styles.descriptionLabel} htmlFor="description">Description</label>
          <textarea id="description" value={fields.getWithDefault(FormField.description, "")}
            name="description" placeholder="Description"
            className={styles.description}
            onChange={handleOnChange(FormField.description)}
          ></textarea>
          { validationErrors.indexOf(FormField.description) > -1 &&
            (<span className="pure-form-message validation-message">
              Description is required.
            </span>)
          }
        </div>
      </fieldset>
      { isSaving && (<LoadingWithOverlay contained={false} />) }
    </form>
  );
};

export default NewExerciseModalBody;
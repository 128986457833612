import AddOn from '../../models/AddOn';
import { PreferenceIdKey } from '../../models/Preference';

const BlogPageAddOn = {
    key: PreferenceIdKey.BLOG_PAGE,
    description: 'Manage the Content for Blog Pages',
    headerText: 'Blog Pages',
    link: '/blog-pages',
    summaryCardIds: [],
} as AddOn;

export default BlogPageAddOn;
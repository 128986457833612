import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import Modal from '../../components/LargeModal/LargeModal';
import { selectedBlogPageSelector, blogPageFormSelector } from '../../store/blogPages/blog-pages-selector';
import { updateBlogPage, postNewBlogItem } from '../../store/blogPages/blog-pages-thunks';
import styles from './blog-page-modal.module.scss';
import BlogPageModalBody from './components/BlogPageModalBody';
import blogPageSlice from '../../store/blogPages/blog-pages-slice';
import useSession from '../../custom_hooks/useSession';

const BlogPageModal = () => {
  const dispatch = useDispatch();
  const blogPage = useSelector(selectedBlogPageSelector);
  const { form }= useSelector(blogPageFormSelector);
  const session = useSession();
  
  const { hideBlogPageModal } = blogPageSlice.actions;
  
  const renderHeader = () => <h2>Blog Page</h2>;
  
  const renderBody = () => <BlogPageModalBody />;
  
  const handleClose = () => {
    dispatch(hideBlogPageModal());
  };
  
  const handleSubmit = () => {
    if (session) {
      if (blogPage?.blogPageId) {
        dispatch(updateBlogPage(session, form));
      } else {
        dispatch(postNewBlogItem(session, form));
      }
    }
  };

  const renderActions = () => (
    <div className={styles.actionsContainer}>
      <button type="button"
        className={classNames("pure-button", styles.cancelButton)} 
        onClick={handleClose}>
        Close
      </button>
      
      <button type="button"
        className={classNames("pure-button", styles.submitButton)} 
        onClick={handleSubmit}>
        Save 
      </button>
    </div>
  );
  
  return (
    <Modal
      onClose={handleClose}
      renderHeader={renderHeader}
      renderBody={renderBody}
      renderActions={renderActions}
    />
  );
};

export default BlogPageModal;

import React, { useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import LoadingWithOverlay from '../../../components/LoadingWithOverlay/LoadingWithOverlay';
import styles from '../new-exercise-modal.module.scss';
import exercisesSlice, { addToTempFileStore, removeFromTempFileStore } from '../../../store/exercises/exercises-slice';
import { newExerciseFormSelector } from '../../../store/exercises/exercises-selector';
import MediaSource, { MediaSourceType } from '../../../models/MediaSource';
import { v4 as createUUID } from 'uuid';

interface NewExerciseModalBodyProps {
  isLoading: boolean,
}

interface MediaSourceForm {
    mediaSourceName?: string;
    file?: File;
    src?: string;
    mediaSourceType: MediaSourceType;
}

const NewExerciseModalBody = ({ isLoading }: NewExerciseModalBodyProps) => {
  const [form, setForm] = useState({
    mediaSourceName: '',
    mediaSourceType: MediaSourceType.File,
    src: '',
  } as MediaSourceForm);
  const dispatch = useDispatch();
  const { mediaSources } = useSelector(newExerciseFormSelector);
  
  const { addMediaSource, removeMediaSource, updateSelectedThumbnail } = exercisesSlice.actions;

  const isSaving = false;
  
  const handleImageFileChange = (ev: React.FormEvent<HTMLInputElement>) => {
    ev.preventDefault();
    const value = ev.currentTarget.value;
    const files = ev.currentTarget.files;
    
    if(files && files.length > 0) {
      const file = files[0];
      setForm({
        ...form,
        file,
        src: value,
      });
    } else {
      setForm({
        ...form,
        file: undefined,
        src: '',
      });
    }
  };
  
  const setSelectedMediaSourceType = (t: MediaSourceType) => () => {
    setForm({
      ...form,
      mediaSourceType: t,
    });
  };
  
  const updateForm = (field: string) => (ev: React.FormEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [field]: ev.currentTarget.value,
    });
  }
  
  const clearForm = () => {
    setForm({
      mediaSourceName: '',
      mediaSourceType: MediaSourceType.File,
      file: undefined,
      src: '',
    });  
  };
  
  const handleAddMedia = () => {
    const localPathSegments = form?.src?.split('\\') || [''];
    const filename = localPathSegments[localPathSegments.length - 1];
    const mediaSource = {} as MediaSource;
    mediaSource.mediaSourceId = createUUID();
    mediaSource.mediaSourceName = form.mediaSourceName || '';
    mediaSource.mediaSourceFileName = filename;
    mediaSource.isThumbnail = false;
    mediaSource.mediaSourceType = form.mediaSourceType; 
    
    if (form.file) {
      addToTempFileStore(mediaSource.mediaSourceId, form.file);
    }

    dispatch(addMediaSource(mediaSource));
    clearForm();
  };
  
  const handleRemoveMedia = (mediaSource: MediaSource) => () => {
    removeFromTempFileStore(mediaSource);
    dispatch(removeMediaSource(mediaSource));
  };
  
  const selectThumbnail = (mediaSource: MediaSource) => () => {
    dispatch(updateSelectedThumbnail(mediaSource));
  };
  
  return (
    <form id="new-exercise-form" className="pure-form pure-form-aligned">
      { isLoading && <LoadingWithOverlay contained={false}/>}
      { isSaving && (<LoadingWithOverlay contained={false} />) }
      <fieldset className={styles.mstFieldset}>
        <div className={classnames('pure-control-group')}>
          <label className={styles.mstBtnGroupLabel}>Select a media type:</label>
          <div className={classnames("pure-button-group", styles.mstButtonContainer)} role="group" aria-label="media source type">
              <button
                className={classnames("pure-button", { [styles.selectedMst]: (form.mediaSourceType === MediaSourceType.File) })}
                onClick={setSelectedMediaSourceType(MediaSourceType.File)}
                type="button"
              >
                File
              </button>
              <button
                className={classnames("pure-button", { [styles.selectedMst]: (form.mediaSourceType === MediaSourceType.Youtube) })}
                onClick={setSelectedMediaSourceType(MediaSourceType.Youtube)}
                type="button"
              >
                Youtube
              </button>
          </div>
        </div>
        <div className="pure-control-group">
          <label htmlFor="mediaSourceName">Name:</label>
          <input id="mediaSourceName" type="text" name="mediaSourceName" placeholder="Source Name"
                 onChange={updateForm('mediaSourceName')} value={form.mediaSourceName}
           />
        </div>
        { form.mediaSourceType === MediaSourceType.File && (
          <div className="pure-control-group">
            <label htmlFor="mediaFile">File:</label>
            <input id="mediaFile" type="file" name="media file" placeholder="Upload a file"
                   value={form.src} onChange={handleImageFileChange}
             />
          </div>
        )}
        { form.mediaSourceType === MediaSourceType.Youtube && (
          <div className="pure-control-group">
            <label htmlFor="mediaFile">Youtube Url:</label>
            <input id="youtubeUrl" type="text" name="youtubeUrl" placeholder="Paste the Youtube URL"
                   value={form.src} onChange={updateForm('src')}
             />
          </div>
        )}
        <div className={classnames("pure-control-group", styles.addButtonContainer)}>
          <button className={classnames("pure-button", styles.addMediaSourceButton)} type="button" onClick={handleAddMedia}>Add +</button>
        </div>
        <hr />
        <div className={classnames("pure-control-group", styles.sourceListContainer)}>
          <table className={classnames("pure-table pure-table-horizontal", styles.addedMediaTable)}>
            <caption>Added Media</caption>
            { mediaSources.length > 0 && (
              <thead>
                  <tr>
                      <th>Name</th>
                      <th colSpan={2}>File</th>
                      <th>Thumbnail</th>
                      <th></th>
                  </tr>
              </thead>
            )}
            <tbody>
              {mediaSources.map((src, i) => (
                <tr key={src.mediaSourceId}>
                  <td>{src.mediaSourceName}</td>
                  <td colSpan={2}>{src.mediaSourceFileName}</td>
                  <td className={styles.tdIsThumbnail}>
                    {src.mediaSourceType === MediaSourceType.File && (
                      <input type="checkbox" onChange={selectThumbnail(src)} checked={src.isThumbnail} />
                    )}
                  </td>
                  <td><button type="button" onClick={handleRemoveMedia(src)} className={styles.removeMediaButton}>remove</button></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </fieldset>
    </form>
  );
};

export default NewExerciseModalBody;
import SummaryCard from '../../models/SummaryCard';
import { PreferenceIdKey } from '../../models/Preference';

const UnhandledOrders = {
  description: 'The number of incomplete orders',
  headerText: 'Incomplete Orders',
  key: PreferenceIdKey.UNHANDLED_ORDERS,
  link: '/orders'
} as SummaryCard;

export default UnhandledOrders;